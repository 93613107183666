import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from '../dashboard/Sidebar';
import UserSidebar from '../user/UserSidebar';
import Header from '../dashboard/Header';


function UserDashboardLayout({children}) {
  // Mock authentication state

  return (
       <div className="flex h-screen bg-gray-100">
          <UserSidebar />
          <div className="flex-1 flex flex-col overflow-hidden">
            <Header />
            <main className="flex-1 overflow-y-auto">
               {children}
            </main>
          </div>
        </div>
  );
}

export default UserDashboardLayout;