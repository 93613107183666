import React from "react";

function CloudDataSolutions() {
  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <div className="bg-gradient-to-r mt-20 from-blue-500 to-blue-800 text-white py-16 px-6 text-center">
      <div className="container mx-auto px-6 md:px-12 text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            Cloud Data Solutions
          </h1>
          <p className="text-lg md:text-xl mb-6">
            Empower your business with scalable, secure, and cost-effective
            cloud solutions for data management and analytics.
          </p>
          <button className="bg-white text-blue-500 px-6 py-3 rounded-full shadow-md hover:bg-gray-100 transition duration-300">
            Learn More
          </button>
        </div>
      </div>

      {/* Overview Image Section */}
      <div className="flex justify-center items-center py-10 bg-gray-50">
        <div className="bg-white rounded-lg shadow-md p-6 max-w-full md:max-w-lg">
          <img
            src="/Cloud/5.svg"
            alt="Cloud Overview"
            className="w-full h-auto object-contain rounded-lg"
          />
        </div>
      </div>
{/* Section 1: Cloud Platforms */}
<div className="py-12">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-6">
    <div className="order-2 md:order-1 bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/Cloud/1.svg"
        alt="Cloud Platforms"
        className="w-auto h-64 object-contain rounded-lg"
      />
    </div>
    <div className="order-1 md:order-2">
      <h2 className="text-2xl font-bold mb-4">Cloud Platforms</h2>
      <p className="text-gray-700 mb-4">
        Leading platforms like AWS, Google Cloud, and Microsoft Azure provide
        the foundation for modern cloud solutions, offering robust
        infrastructure, scalability, and global reach.
      </p>
    </div>
  </div>
</div>

{/* Section 2: Cloud Data Lakes */}
<div className="py-12 bg-gray-100">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-6">
    <div className="order-1 md:order-1">
      <h2 className="text-2xl font-bold mb-4">Cloud Data Lakes</h2>
      <p className="text-gray-700 mb-4">
        Solutions like AWS Lake Formation, Azure Data Lake Storage, and Google
        Cloud Storage enable efficient storage and management of unstructured
        and structured data at scale.
      </p>
    </div>
    <div className="order-2 md:order-2 bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/Cloud/2.svg"
        alt="Cloud Data Lakes"
        className="w-auto h-64 object-contain rounded-lg"
      />
    </div>
  </div>
</div>

{/* Section 3: Cloud Data Warehouses */}
<div className="py-12">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-6">
    <div className="order-2 md:order-1 bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/Cloud/3.svg"
        alt="Cloud Data Warehouses"
        className="w-auto h-64 object-contain rounded-lg"
      />
    </div>
    <div className="order-1 md:order-2">
      <h2 className="text-2xl font-bold mb-4">Cloud Data Warehouses</h2>
      <p className="text-gray-700 mb-4">
        Tools like Snowflake, AWS Redshift, and Google BigQuery provide
        enterprise-grade cloud data warehousing for analytics and reporting.
      </p>
    </div>
  </div>
</div>

{/* Section 4: Cloud Analytics Services */}
<div className="py-12 bg-gray-100">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-6">
    <div className="order-1 md:order-1">
      <h2 className="text-2xl font-bold mb-4">Cloud Analytics Services</h2>
      <p className="text-gray-700 mb-4">
        Services like AWS Athena, Google BigQuery, and Azure Synapse Analytics
        enable businesses to perform high-performance analytics on cloud-stored
        data.
      </p>
    </div>
    <div className="order-2 md:order-2 bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/Cloud/4.svg"
        alt="Cloud Analytics Services"
        className="w-auto h-64 object-contain rounded-lg"
      />
    </div>
  </div>
</div>

      {/* Call-to-Action Section */}
      <div className="py-12 bg-blue-500 text-white text-center">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-4">Unlock the Power of the Cloud</h2>
          <p className="mb-6">
            Explore the possibilities with cutting-edge cloud data solutions tailored for your business needs.
          </p>
          <button className="bg-white text-blue-500 px-6 py-3 rounded-full shadow-md hover:bg-gray-100 transition duration-300">
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
}

export default CloudDataSolutions;
