import api from "./api";

export const login = async (email, password) => {
  try {
    const response = await api.post("/auth/login", { email, password });
    const { token } = response.data;
    console.log(response);

    // Store token in session storage
    sessionStorage.setItem("token", token);

    return response.data;
  } catch (error) {
    console.log(error);

    console.error("Login failed:", error.response?.data || error.message);
    throw error;
  }
};

export const logout = () => {
  sessionStorage.removeItem("token");
};
