import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FaBuilding,
  FaInfoCircle,
  FaUsers,
  FaNewspaper,
  FaBriefcase,
  FaShieldAlt,
  FaPhoneAlt,
  FaQuestionCircle,
  FaDollarSign,
  FaUserFriends,
  FaRegSmile,
} from "react-icons/fa";
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleMouseEnter = () => {
    setIsMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsMenuOpen(false);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);
  const menuItems = [
    {
      title: "Company",
      items: [
        { name: "About", icon: <FaInfoCircle />, link: "/about" },
        { name: "Enterprise", icon: <FaBuilding />, link: "/enterprise" },
        { name: "Partners", icon: <FaUsers />, link: "/partners" },
        { name: "News Feed", icon: <FaNewspaper />, link: "/news-feed" },
        { name: "Jobs", icon: <FaBriefcase />, link: "/jobs" },
        { name: "Privacy", icon: <FaShieldAlt />, link: "/privacy" },
      ],
    },
    {
      title: "Resources",
      items: [
        {
          name: "Financial Support",
          icon: <FaDollarSign />,
          link: "/financial-support",
        },
        { name: "Contact Us", icon: <FaPhoneAlt />, link: "/contact" },
        {
          name: "Success Stories",
          icon: <FaRegSmile />,
          link: "/success-stories",
        },
        { name: "FAQs", icon: <FaQuestionCircle />, link: "/faqs" },
        { name: "Mentors", icon: <FaUserFriends />, link: "/mentors" },
      ],
    },
  ];
  return (
    <header className="bg-blue-600 mb-10 text-white py-4 px-6 fixed top-0 w-full z-50 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo Section */}
        <div className="flex items-center">
          <Link to="/">
            <img src="/D1 (2).png" alt="Blueblockss" className="h-12 w-auto" />
          </Link>
        </div>
        {/* Hamburger Menu for Mobile */}
        <div className="lg:hidden">
          <button
            className="text-white focus:outline-none"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            )}
          </button>
        </div>
        {/* Desktop Navigation */}
        <nav className="hidden lg:flex space-x-6 items-center">
          <Link to="/" className=" hover:text-blue-300 transition duration-200">
            Home
          </Link>
          <Link
            to="/temp"
            className=" hover:text-blue-300 transition duration-200"
          >
            My Learning Journey
          </Link>

          <Link
            to="/enterprise"
            className=" hover:text-blue-300 transition duration-200"
          >
            Enterprise
          </Link>
          <Link
            to="/events"
            className=" hover:text-blue-300 transition duration-200"
          >
            Events
          </Link>
          <Link
            to="/skill-development"
            className=" hover:text-blue-300 transition duration-200"
          >
            Bootcamps
          </Link>

          {/* More Dropdown */}
          {/* <div
            className="relative"
            ref={dropdownRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <button className=" hover:text-blue-300 transition duration-200">
              More
            </button>
            {isMenuOpen && (
                  <div
                  className="fixed inset-0 bg-white text-blue-500 z-40 mt-20 max-h-[550px] flex flex-col py-12"
                >
                  <div className="container mx-auto px-6">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
                      {menuItems.map((section, index) => (
                        <div key={index}>
                          <h4 className="font-semibold text-gray-700 text-lg mb-4">
                            {section.title}
                          </h4>
                          <ul className="space-y-2">
                            {section.items.map((item, idx) => (
                              <li key={idx} className="flex bg-blue-100 py-2 items-center px-2 space-x-3">
                                <span className="text-blue-700">{item.icon}</span>
                                <Link
                                  to={item.link}
                                  className="text-lg hover:text-blue-900 transition"
                                >
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                    <div className="mt-10 flex justify-center">
                      <button className="bg-blue-700 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition duration-200">
                        Book a Free Call
                      </button>
                    </div>
                  </div>
                </div>
            )}
          </div> */}

          <Link
            to="/sign-in"
            className=" hover:text-blue-300 transition duration-200"
          >
            Sign In
          </Link>
          <button className="bg-white text-blue-500 font-semibold  py-2 px-4 rounded-lg hover:bg-blue-100 transition duration-200">
            Book a Free Call
          </button>

          {/* <div className="relative">
      <button
        id="dropdownAvatarNameButton"
        onClick={toggleDropdown}
        className="flex items-center text-sm pe-1 font-medium text-gray-900 rounded-full hover:text-blue-100 dark:hover:text-blue-500 md:me-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white"
        type="button"
      >
        <span className="sr-only">Open user menu</span>
        <img
          className="w-8 h-8 me-2 rounded-full"
          src="https://flowbite.com/docs/images/people/profile-picture-3.jpg"
          alt="user photo"
        />
        Bonnie Green
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isDropdownOpen && (
        <div
          id="dropdownAvatarName"
          className="absolute right-0 z-10 bg-blue-600 divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
        >
          <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
            <div className="font-medium">Pro User</div>
            <div className="truncate">name@flowbite.com</div>
          </div>
          <ul
            className="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownAvatarNameButton"
          >
            <li>
              <Link
                to="dashboard"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Settings
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Earnings
              </Link>
            </li>
          </ul>
          <div className="py-2">
            <Link
              to="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
            >
              Sign out
            </Link>
          </div>
        </div>
      )}
    </div> */}
        </nav>
      </div>
      {/* Mobile Navigation */}
      {isMenuOpen && (
        <nav className="lg:hidden bg-blue-500 shadow-lg">
          <ul className="space-y-4 px-6 py-4">
            <li>
              <Link
                to="/"
                className="block hover:underline hover:text-blue-300 transition duration-200"
                onClick={() => setIsMenuOpen(false)}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="enterprise"
                className="block hover:underline hover:text-blue-300 transition duration-200"
                onClick={() => setIsMenuOpen(false)}
              >
                Enterprise
              </Link>
            </li>
            <li>
              <Link
                to="events"
                className="block hover:underline hover:text-blue-300 transition duration-200"
                onClick={() => setIsMenuOpen(false)}
              >
                Events
              </Link>
            </li>

            <li>
              <Link
                to="course"
                className="block hover:underline hover:text-blue-300 transition duration-200"
                onClick={() => setIsMenuOpen(false)}
              >
                Bootcamps
              </Link>
            </li>
            <li>
              <Link
                to="sign-in"
                className="block hover:underline hover:text-blue-300 transition duration-200"
                onClick={() => setIsMenuOpen(false)}
              >
                sign-in
              </Link>
            </li>
            <li>
              <Link
                to="Book-a-Free-Call"
                className="block hover:underline hover:text-blue-300 transition duration-200"
                onClick={() => setIsMenuOpen(false)}
              >
                Book a Free Call
              </Link>
            </li>
          </ul>

          <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
            <span class="font-medium text-gray-600 dark:text-gray-300">JL</span>
          </div>
        </nav>
      )}
    </header>
  );
};

export default Header;
