import React, { useState } from 'react';
import { Button } from '../../ui/button';
import { ImageUp as ImageUpload } from 'lucide-react';

interface BasicInfoProps {
  formData: {
    title: string;
    duration: string;
    timing: string;
    image?: File;
  };
  onChange: (field: string, value: string | File) => void;
  onNext: () => void;
  imagePreview?: string;
}

export function BasicInfo({ formData, onChange, onNext, imagePreview }: BasicInfoProps) {
  const [preview, setPreview] = useState<string>(imagePreview || '');

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      onChange('image', file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-blue-900">Basic Information</h2>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Title
          </label>
          <input
            type="text"
            value={formData.title}
            onChange={(e) => onChange('title', e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
            placeholder="Enter bootcamp title"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Duration
          </label>
          <input
            type="text"
            value={formData.duration}
            onChange={(e) => onChange('duration', e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
            placeholder="e.g., 12 weeks"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Timing
          </label>
          <input
            type="text"
            value={formData.timing}
            onChange={(e) => onChange('timing', e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
            placeholder="e.g., Mon-Fri 9AM-5PM"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Bootcamp Image
          </label>
          <div className="mt-1 flex items-center space-x-4">
            <label className="flex items-center justify-center w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-blue-500">
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleImageChange}
              />
              {preview ? (
                <img
                  src={preview}
                  alt="Preview"
                  className="w-full h-full object-cover rounded-lg"
                />
              ) : (
                <div className="text-center">
                  <ImageUpload className="mx-auto h-8 w-8 text-gray-400" />
                  <span className="mt-2 block text-sm text-gray-400">
                    Upload Image
                  </span>
                </div>
              )}
            </label>
            {preview && (
              <Button
                variant="outline"
                onClick={() => {
                  setPreview('');
                  onChange('image', '');
                }}
              >
                Remove Image
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button onClick={onNext}>Next</Button>
      </div>
    </div>
  );
}