import React from 'react';
import { Button } from '../../ui/button';

interface DescriptionProps {
  formData: {
    description: {
      beginners: string;
      graduates: string;
      professionals: string;
    };
  };
  onChange: (field: string, value: string) => void;
  onNext: () => void;
  onBack: () => void;
}

export function Description({ formData, onChange, onNext, onBack }: DescriptionProps) {
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-blue-900">Description</h2>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            For Beginners
          </label>
          <textarea
            value={formData.description.beginners}
            onChange={(e) => onChange('description.beginners', e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
            rows={4}
            placeholder="Description for beginners"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            For Graduates
          </label>
          <textarea
            value={formData.description.graduates}
            onChange={(e) => onChange('description.graduates', e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
            rows={4}
            placeholder="Description for graduates"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            For Professionals
          </label>
          <textarea
            value={formData.description.professionals}
            onChange={(e) => onChange('description.professionals', e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
            rows={4}
            placeholder="Description for professionals"
          />
        </div>
      </div>
      <div className="flex justify-between">
        <Button variant="outline" onClick={onBack}>Back</Button>
        <Button onClick={onNext}>Next</Button>
      </div>
    </div>
  );
}