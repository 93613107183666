import React, { useState, useEffect } from "react";
import { Table } from "../../components/bootcamp/Table";
import { Form } from "../../components/bootcamp/Form";
import { BootcampDetails } from "../../components/bootcamp/BootcampDetails";
import * as api from "../../lib/api";
import { Bootcamp } from "../../types/index";
import { PlusCircle, XCircle } from "lucide-react";

function BootcampFormTemp() {
  const [showForm, setShowForm] = useState(false);
  const [bootcamps, setBootcamps] = useState<Bootcamp[]>([]);
  const [editingBootcamp, setEditingBootcamp] = useState<Bootcamp | null>(null);
  const [selectedBootcamp, setSelectedBootcamp] = useState<Bootcamp | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchBootcamps();
  }, []);

  const fetchBootcamps = async () => {
    setIsLoading(true);
    try {
      const data = await api.fetchAllBootcamps();
      setBootcamps(data);
    } catch (error) {
      console.error("Error fetching bootcamps:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const bootcampFields = [
    { name: "title", label: "Title", type: "text" },
    { name: "duration", label: "Duration", type: "text" },
    { name: "class_time", label: "Class Time", type: "text" },
    { name: "beginners", label: "For Beginners", type: "textarea" },
    { name: "students", label: "For Students", type: "textarea" },
    { name: "professionals", label: "For Professionals", type: "textarea" },
    {
      name: "criteria_for_completion",
      label: "Completion Criteria",
      type: "textarea",
    },
    {
      name: "challenge_description",
      label: "Challenge Description",
      type: "textarea",
    },
    { name: "image", label: "Image", type: "file" },
    {
      name: "skills",
      label: "Skills",
      type: "text",
      placeholder: "Comma separated skills",
    },
  ];

  const handleSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const description = {
        beginners: data.beginners,
        students: data.students,
        professionals: data.professionals,
      };
  
      const skillsArray =
        typeof data.skills === "string"
          ? data.skills.split(",").map((skill: string) => skill.trim()) // Convert skills string to array
          : Array.isArray(data.skills)
          ? data.skills // If already an array, use as-is
          : []; // Default to an empty array if undefined or invalid
  
      if (editingBootcamp) {
        await api.updateBootcamp(editingBootcamp.bootcamp_id, {
          ...data,
          description: JSON.stringify(description), // Add description as a JSON string
          skills: skillsArray, // Ensure skills is an array
        });
      } else {
        const bootcampData = {
          ...data,
          description: JSON.stringify(description), // Add description as a JSON string
          skills: skillsArray, // Ensure skills is an array
        };
        await api.createBootcamp(bootcampData, data.image); // Handle image upload
      }
  
      setShowForm(false);
      setEditingBootcamp(null);
      fetchBootcamps();
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  
  const handleDelete = async (bootcamp: Bootcamp) => {
    if (!window.confirm("Are you sure you want to delete this bootcamp?"))
      return;

    setIsLoading(true);
    try {
      await api.deleteBootcamp(bootcamp.bootcamp_id);
      fetchBootcamps();
    } catch (error) {
      console.error("Error deleting bootcamp:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBootcampClick = (bootcamp: Bootcamp) => {
    setSelectedBootcamp(bootcamp);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="bg-blue-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <h1 className="text-2xl font-bold">Bootcamp Management</h1>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow">
          <div className="p-6">
            <div className="mb-6 flex justify-end">
              <button
                onClick={() => {
                  setShowForm((prev) => !prev); // Toggle the form visibility
                  if (showForm) {
                    setEditingBootcamp(null); // Clear editing state when canceling
                  }
                }}
                className={`flex items-center px-4 py-2 rounded-md text-white ${
                  showForm
                    ? "bg-red-600 hover:bg-red-700"
                    : "bg-blue-600 hover:bg-blue-700"
                }`}
              >
                {showForm ? (
                  <>
                    <XCircle className="w-5 h-5 mr-2" />
                    Cancel
                  </>
                ) : (
                  <>
                    <PlusCircle className="w-5 h-5 mr-2" />
                    Add New Bootcamp
                  </>
                )}
              </button>
            </div>
            {showForm && (
              <div className="mb-8 p-6 bg-gray-50 rounded-lg">
                <Form
                  fields={bootcampFields}
                  initialData={editingBootcamp}
                  onSubmit={handleSubmit}
                  submitLabel={editingBootcamp ? "Update" : "Save"}
                />
              </div>
            )}

            {isLoading ? (
              <div className="flex justify-center items-center py-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
              </div>
            ) : (
              <Table
                columns={[
                  { header: "Title", accessor: "title" },
                  { header: "Duration", accessor: "duration" },
                  { header: "Class Time", accessor: "class_time" },
                  {
                    header: "Skills",
                    accessor: "skills",
                    render: (skills: string[]) =>
                      Array.isArray(skills) ? skills.join(", ") : "",
                  },
                ]}
                data={bootcamps}
                onEdit={(bootcamp) => {
                  setEditingBootcamp(bootcamp);
                  setShowForm(true);
                }}
                onDelete={handleDelete}
                onRowClick={handleBootcampClick}
              />
            )}
          </div>
        </div>
      </div>

      {selectedBootcamp && (
        <BootcampDetails
          bootcamp={selectedBootcamp}
          onClose={() => setSelectedBootcamp(null)}
        />
      )}
    </div>
  );
}

export default BootcampFormTemp;
