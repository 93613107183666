import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  CodeBracketIcon, 
  TrophyIcon, 
  LinkIcon
} from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

// Interfaces
interface LeetCodeProfile {
  username: string;
  name: string;
  avatar: string;
  ranking: number;
  reputation: number;
  website?: string[];
}

interface LeetCodeStats {
  solvedProblem: number;
  easySolved: number;
  mediumSolved: number;
  hardSolved: number;
  totalSubmissionNum: DifficultySubmission[];
  acSubmissionNum: DifficultySubmission[];
}

interface DifficultySubmission {
  difficulty: string;
  count: number;
  submissions: number;
}

// Define a type for difficulty
type Difficulty = 'Easy' | 'Medium' | 'Hard';

// Define color map with type safety
const DIFFICULTY_COLORS: Record<Difficulty, string> = {
  'Easy': 'bg-green-500',
  'Medium': 'bg-yellow-500',
  'Hard': 'bg-red-500'
};

// Difficulty Progress Bar Component
const DifficultyProgressBar: React.FC<{
  difficulty: Difficulty;
  solved: number;
  total: number;
}> = ({ difficulty, solved, total }) => {
  const percentage = total > 0 ? (solved / total) * 100 : 0;

  return (
    <div className="mb-2">
      <div className="flex justify-between text-sm text-gray-600 mb-1">
        <span>{difficulty}</span>
        <span>{`${solved} / ${total}`}</span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div 
          className={`${DIFFICULTY_COLORS[difficulty]} h-2.5 rounded-full`} 
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  );
};

const LeetCodeComponent: React.FC = () => {
  const [profile, setProfile] = useState<LeetCodeProfile | null>(null);
  const [stats, setStats] = useState<LeetCodeStats | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLeetCodeData = async () => {
      try {
        setIsLoading(true);
        const [profileResponse, statsResponse] = await Promise.all([
          axios.get('https://alfa-leetcode-api.onrender.com/fjzzq2002'),
          axios.get('https://alfa-leetcode-api.onrender.com/fjzzq2002/solved')
        ]);

        setProfile(profileResponse.data);
        setStats(statsResponse.data);
      } catch (err) {
        setError('Failed to fetch LeetCode data');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLeetCodeData();
  }, []);

  // Loading State
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-blue-500"></div>
      </div>
    );
  }

  // Error State
  if (error || !profile || !stats) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
        {error || 'No data available'}
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white rounded-xl mt-5 shadow-lg hover:shadow-blue-600/50 p-6 max-w-4xl mx-auto"
    >
         <h2 className="text-2xl font-bold mb-5 text-gray-800">
            Leetcode Contributions
          </h2>
      <div className="grid md:grid-cols-3 gap-8">
        {/* Profile Section */}
        <div className="md:col-span-1 text-center">
          <div className="flex flex-col items-center">
            <img 
              src={profile.avatar} 
              alt={profile.name} 
              className="w-32 h-32 rounded-full border-4 border-blue-500 mb-4"
            />
            <h2 className="text-2xl font-bold text-gray-800">{profile.name}</h2>
            <p className="text-gray-600 mb-4"><Link target='_blank' to={`https://leetcode.com/u/${profile.username}`} >@{profile.username}</Link></p>
            
          
          </div>
        </div>

        {/* Stats Section */}
        <div className="md:col-span-2">
          <div className="grid md:grid-cols-2 gap-6">
            {/* Overall Stats */}
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center mb-4">
                <CodeBracketIcon className="h-8 w-8 text-blue-500 mr-3" />
                <h3 className="text-xl font-semibold text-gray-800">
                  Problem Solving
                </h3>
              </div>
              <div className="space-y-3">
                <div className="flex justify-between">
                  <span className="text-gray-600">Total Solved</span>
                  <span className="font-bold text-blue-600">
                    {stats.solvedProblem}
                  </span>
                </div>
                <DifficultyProgressBar 
                  difficulty="Easy" 
                  solved={stats.easySolved} 
                  total={70} 
                />
                <DifficultyProgressBar 
                  difficulty="Medium" 
                  solved={stats.mediumSolved} 
                  total={75} 
                />
                <DifficultyProgressBar 
                  difficulty="Hard" 
                  solved={stats.hardSolved} 
                  total={45} 
                />
              </div>
            </div>

            {/* Submission Stats */}
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center mb-4">
                <TrophyIcon className="h-8 w-8 text-green-500 mr-3" />
                <h3 className="text-xl font-semibold text-gray-800">
                  Submission Insights
                </h3>
              </div>
              <div className="space-y-3">
                {stats.acSubmissionNum.map((submission, index) => (
                  index > 0 && (
                    <div 
                      key={submission.difficulty} 
                      className="flex justify-between items-center"
                    >
                      <span className="text-gray-600">
                        {submission.difficulty} Acceptance
                      </span>
                      <span className="font-bold text-green-600">
                        {((submission.count / submission.submissions) * 100).toFixed(1)}%
                      </span>
                    </div>
                  )
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default LeetCodeComponent;