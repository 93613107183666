import React from 'react';
import { EnrolledBootcamp } from '../../types/user';
import { BookOpen, CheckCircle, AlertCircle } from 'lucide-react';

interface Props {
  bootcamps: EnrolledBootcamp[];
}

export default function ProgressOverview({ bootcamps }: Props) {
  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold text-gray-900">My Bootcamps Progress</h2>
      <div className="grid gap-6 md:grid-cols-2">
        {bootcamps.map((bootcamp) => (
          <div key={bootcamp.id} className="bg-white rounded-lg shadow p-6">
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-lg font-medium text-gray-900">{bootcamp.title}</h3>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <BookOpen className="w-4 h-4 mr-1" />
                  {bootcamp.completedModules} of {bootcamp.totalModules} modules completed
                </div>
              </div>
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                {bootcamp.progress}% Complete
              </span>
            </div>

            <div className="mt-4">
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className="bg-blue-600 h-2 rounded-full"
                  style={{ width: `${bootcamp.progress}%` }}
                />
              </div>
            </div>

            <div className="mt-4 flex items-center justify-between text-sm">
              <div className="flex items-center text-green-600">
                <CheckCircle className="w-4 h-4 mr-1" />
                {bootcamp.completedModules} Completed
              </div>
              <div className="flex items-center text-yellow-600">
                <AlertCircle className="w-4 h-4 mr-1" />
                {bootcamp.assignments} Pending Assignments
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}