import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import PersonalInfo from './registration/PersonalInfo';
import CareerGoals from './registration/CareerGoals';
import EventSelection from './registration/EventSelection';
import AttendanceConsent from './registration/AttendanceConsent';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type FormData = {
  personalInfo: {
    fullName: string;
    email: string;
    phone: string;
    residence: string;
    timezone: string;
    role: string;
    password: string;
  };
  careerGoals: string[];
  events: {
    bigDataAI: string[];
    coding: string[];
    itManagement: string[];
  };
  attendance: {
    mode: string;
    contactConsent: boolean;
    dataConsent: boolean;
  };
};

const initialFormData: FormData = {
  personalInfo: {
    fullName: '',
    email: '',
    phone: '',
    residence: '',
    timezone: '',
    role: '',
    password: '',
  },
  careerGoals: [],
  events: {
    bigDataAI: [],
    coding: [],
    itManagement: [],
  },
  attendance: {
    mode: '',
    contactConsent: false,
    dataConsent: false,
  },
};

export default function RegistrationForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState<FormData>(initialFormData);

  const totalSteps = 4;

  const handleNext = () => {
    // Add validation before moving to the next step
    if (!isCurrentStepValid()) {
      toast.error('Please fill in all required fields before proceeding.', {
        position: 'top-right',
        autoClose: 3000,
      });
      return;
    }
    setCurrentStep((prev) => Math.min(prev + 1, totalSteps));
  };

  const handlePrev = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const updateFormData = (section: keyof FormData, data: any) => {
    setFormData((prev) => ({
      ...prev,
      [section]: data,
    }));
  };

  const isCurrentStepValid = (): boolean => {
    switch (currentStep) {
      case 1:
        return (
          formData.personalInfo.fullName.trim() !== '' &&
          formData.personalInfo.email.trim() !== '' &&
          formData.personalInfo.phone.trim() !== '' &&
          formData.personalInfo.residence.trim() !== '' &&
          formData.personalInfo.timezone.trim() !== '' &&
          formData.personalInfo.role.trim() !== ''
        );
      case 2:
        return formData.careerGoals.length > 0;
      case 3:
        return (
          formData.events.bigDataAI.length > 0 ||
          formData.events.coding.length > 0 ||
          formData.events.itManagement.length > 0
        );
      case 4:
        return (
          formData.attendance.mode.trim() !== '' && // Ensure mode is not an empty string
          formData.attendance.contactConsent === true &&
          formData.attendance.dataConsent === true
        );
    
      default:
        return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Final validation before submission
    if (!isCurrentStepValid()) {
      toast.error('Please fill in all required fields before submitting.', {
        position: 'top-right',
        autoClose: 3000,
      });
      return;
    }

    const payload = {
      full_name: formData.personalInfo.fullName,
      email: formData.personalInfo.email,
      phone_number: formData.personalInfo.phone,
      place_of_residence: formData.personalInfo.residence,
      time_zone: formData.personalInfo.timezone,
      role: formData.personalInfo.role,
      career_goals: JSON.stringify(formData.careerGoals),
      preferred_mode: formData.attendance.mode,
      contact_permission: formData.attendance.contactConsent,
      data_usage_consent: formData.attendance.dataConsent,
      password: formData.personalInfo.password,
    };

    try {
      const response = await axios.post('https://api.blueblockss.ai:5002/api/auth/register', payload);
      toast.success('Registration successful!', {
        position: 'top-right',
        autoClose: 3000,
      });
      setFormData(initialFormData);
      setCurrentStep(1);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        toast.error(
          error.response?.data?.message || 'Registration failed. Please try again.',
          {
            position: 'top-right',
            autoClose: 3000,
          }
        );
      } else {
        toast.error('An unexpected error occurred. Please try again later.', {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <div className="w-full h-full bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="min-w-full h-full mx-auto">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="bg-blue-600 px-6 py-8 text-white">
            <h1 className="text-3xl font-bold">Registration Form</h1>
            <p className="mt-2 text-blue-100">Prepare for Today's IT Needs: Harness Big Data, AI, and ML</p>
          </div>

          <div className="px-6 pt-4">
            <div className="flex items-center justify-between mb-2">
              {Array.from({ length: totalSteps }).map((_, index) => (
                <React.Fragment key={index}>
                  <div
                    className={`w-8 h-8 rounded-full flex items-center justify-center ${
                      index + 1 <= currentStep ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'
                    }`}
                  >
                    {index + 1}
                  </div>
                  {index < totalSteps - 1 && (
                    <div
                      className={`flex-1 h-1 mx-2 ${
                        index + 1 < currentStep ? 'bg-blue-600' : 'bg-gray-200'
                      }`}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>

          <form className="p-6">
            <div className="space-y-6">
              {currentStep === 1 && (
                <PersonalInfo
                  data={formData.personalInfo}
                  onChange={(data) => updateFormData('personalInfo', data)}
                />
              )}
              {currentStep === 2 && (
                <CareerGoals
                  data={formData.careerGoals}
                  onChange={(data) => updateFormData('careerGoals', data)}
                />
              )}
              {currentStep === 3 && (
                <EventSelection
                  data={formData.events}
                  onChange={(data) => updateFormData('events', data)}
                />
              )}
              {currentStep === 4 && (
                <AttendanceConsent
                  data={formData.attendance}
                  onChange={(data) => updateFormData('attendance', data)}
                />
              )}
            </div>

            <div className="mt-8 flex justify-between">
              <button
                type="button"
                onClick={handlePrev}
                className={`inline-flex items-center px-4 py-2 rounded-md text-sm font-medium ${
                  currentStep === 1
                    ? 'text-gray-400 cursor-not-allowed'
                    : 'text-blue-600 hover:text-blue-700'
                }`}
                disabled={currentStep === 1}
              >
                <ChevronLeft className="w-5 h-5 mr-1" />
                Previous
              </button>
              {currentStep < totalSteps ? (
                <button
                  type="button"
                  onClick={handleNext}
                  className="inline-flex items-center px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
                >
                  Next
                  <ChevronRight className="w-5 h-5 ml-1" />
                </button>
              ) : (
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="inline-flex items-center px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
                >
                  Submit Registration
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
