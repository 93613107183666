import React from 'react';
import { Education as EducationType } from '../../../types/resume';

interface Props {
  education: EducationType[];
}

export default function Education({ education }: Props) {
  return (
    <section>
      <h2 className="text-xl font-bold mb-4 text-gray-800">Education</h2>
      <div className="space-y-4">
        {education.map((edu) => (
          <div key={edu.id} className="bg-white rounded-lg p-4 hover:shadow-md transition-shadow">
            <div className="flex flex-col sm:flex-row sm:items-center justify-between">
              <div>
                <h3 className="text-lg font-semibold text-gray-800">{edu.degree}</h3>
                <p className="text-blue-600 font-medium">{edu.institution}</p>
              </div>
              <span className="text-gray-500 text-sm mt-1 sm:mt-0">{edu.duration}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}