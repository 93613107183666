// src/pages/BootcampDetail.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { 
  Calendar, 
  Clock, 
  Users, 
  GraduationCap, 
  Briefcase,
  ChevronDown,
  ChevronUp,
  CheckCircle,
  AlertCircle
} from 'lucide-react';

const BootcampDetail = () => {
    const { slug } = useParams();
    const [searchParams] = useSearchParams();
    const bootcampId = searchParams.get('id'); // Get the ID from query params
  const [bootcamp, setBootcamp] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeFaq, setActiveFaq] = useState(null);

  useEffect(() => {
    fetchBootcampDetails();
  }, [bootcampId]);

  const fetchBootcampDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`https://api.blueblockss.ai:5002/api/bootcamp/${bootcampId}`);
      setBootcamp(response.data);
    } catch (err) {
      setError('Failed to load bootcamp details');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Helper function to get initials from name
  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  if (loading) {
    return (
      <div className="min-h-screen mt-20 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error || !bootcamp) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen  mt-20 bg-gray-50">
      {/* Hero Section */}
      <div className="bg-gradient-to-r rounded-lg from-blue-600 to-purple-600 text-white">
        <div className="container mx-auto px-4 py-16">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">{bootcamp.title}</h1>
          <div className="flex flex-wrap gap-4 text-sm md:text-base">
            <div className="flex items-center gap-2">
              <Calendar className="w-5 h-5" />
              <span>{bootcamp.duration}</span>
            </div>
            <div className="flex items-center gap-2">
              <Clock className="w-5 h-5" />
              <span>{bootcamp.class_time}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Main Content */}
          <div className="lg:col-span-2 space-y-8">
            {/* Description Section */}
            <section className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-2xl font-bold mb-6">About This Bootcamp</h2>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <Users className="w-6 h-6 text-blue-600 flex-shrink-0 mt-1" />
                  <p>{bootcamp.description.beginners}</p>
                </div>
                <div className="flex items-start gap-3">
                  <GraduationCap className="w-6 h-6 text-blue-600 flex-shrink-0 mt-1" />
                  <p>{bootcamp.description.graduates}</p>
                </div>
                <div className="flex items-start gap-3">
                  <Briefcase className="w-6 h-6 text-blue-600 flex-shrink-0 mt-1" />
                  <p>{bootcamp.description.professionals}</p>
                </div>
              </div>
            </section>

            {/* Skills Section */}
            <section className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-2xl font-bold mb-6">Skills You'll Learn</h2>
              <div className="flex flex-wrap gap-3">
                {bootcamp.skills.map((skill) => (
                  <span 
                    key={skill}
                    className="px-4 py-2 bg-blue-50 text-blue-600 rounded-full text-sm font-medium"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </section>

            {/* Curriculum Section */}
            <section className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-2xl font-bold mb-6">Curriculum</h2>
              <div className="space-y-4">
                {bootcamp.curriculum.map((item, index) => (
                  <div 
                    key={item.curriculum_id}
                    className="border-l-4 border-blue-600 pl-4 py-2"
                  >
                    <h3 className="text-lg font-semibold">
                      {index + 1}. {item.title}
                    </h3>
                    <p className="text-gray-600 mt-2">{item.content}</p>
                  </div>
                ))}
              </div>
            </section>

            {/* FAQs Section */}
            <section className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-2xl font-bold mb-6">Frequently Asked Questions</h2>
              <div className="space-y-4">
                {bootcamp.faqs.map((faq) => (
                  <div key={faq.faq_id} className="border-b last:border-b-0">
                    <button
                      onClick={() => setActiveFaq(activeFaq === faq.faq_id ? null : faq.faq_id)}
                      className="flex justify-between items-center w-full py-4 text-left"
                    >
                      <span className="font-medium">{faq.question}</span>
                      {activeFaq === faq.faq_id ? 
                        <ChevronUp className="w-5 h-5" /> : 
                        <ChevronDown className="w-5 h-5" />
                      }
                    </button>
                    {activeFaq === faq.faq_id && (
                      <p className="pb-4 text-gray-600">{faq.answer}</p>
                    )}
                  </div>
                ))}
              </div>
            </section>
          </div>

          {/* Sidebar */}
          <div className="space-y-6">
            {/* Batch Information */}
            <section className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-bold mb-4">Upcoming Batches</h2>
              <div className="space-y-4">
                {bootcamp.batches.map((batch) => (
                  <div 
                    key={batch.batch_id}
                    className="flex justify-between items-center p-3 bg-gray-50 rounded-lg"
                  >
                    <div>
                      <p className="font-medium">
                        {new Date(batch.date).toLocaleDateString()}
                      </p>
                      <span className={`text-sm ${
                        batch.status === 'Ongoing' ? 'text-green-600' : 'text-red-600'
                      }`}>
                        {batch.status}
                      </span>
                    </div>
                    {batch.status === 'Ongoing' && (
                      <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
                        Enroll
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </section>

            {/* Speakers Section */}
            <section className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-bold mb-4">Meet Your Instructors</h2>
              <div className="space-y-4">
                {bootcamp.speakers.map((speaker) => (
                  <div key={speaker.speaker_id} className="flex items-start gap-4">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white flex items-center justify-center flex-shrink-0">
                      {getInitials(speaker.name)}
                    </div>
                    <div>
                      <h3 className="font-medium">{speaker.name}</h3>
                      <p className="text-sm text-gray-600">{speaker.bio}</p>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            {/* Apply Button */}
            <div className="sticky top-4">
              <button className="w-full py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200">
                Apply Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BootcampDetail;