import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  fetchCurriculumById,
  createCurriculum,
  deleteCurriculum,
  updateCurriculum,
} from '../../../../lib/api';

const CurriculumManager = () => {
  const { id: bootcampId } = useParams();
  const [curriculums, setCurriculums] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
  });
  const [editingId, setEditingId] = useState(null); // To track editing state

  useEffect(() => {
    loadCurriculums();
  }, [bootcampId]);

  const loadCurriculums = async () => {
    try {
      const data = await fetchCurriculumById(bootcampId);
      setCurriculums(data);
    } catch (error) {
      console.error('Error loading curriculums:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingId) {
        await updateCurriculum(bootcampId,editingId, { ...formData});
      } else {
        await createCurriculum({ ...formData, bootcampId: bootcampId });
      }
      setFormData({ title: '', content: '' });
      setEditingId(null); // Reset editing state
      await loadCurriculums();
    } catch (error) {
      console.error('Error saving curriculum:', error);
    }
  };

  const handleDelete = async (curriculumId) => {
    if (window.confirm('Are you sure you want to delete this curriculum item?')) {
      try {
        await deleteCurriculum(curriculumId);
        await loadCurriculums();
      } catch (error) {
        console.error('Error deleting curriculum:', error);
      }
    }
  };

  const handleEdit = (curriculum) => {
    setEditingId(curriculum.curriculum_id);
    setFormData({ title: curriculum.title, content: curriculum.content });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <h2 className="text-2xl font-semibold text-blue-800">Curriculum Management</h2>

      {/* Add/Edit Curriculum Form */}
      <div className="bg-white rounded-lg shadow-lg p-6 mt-5">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          {editingId ? 'Edit Curriculum Item' : 'Add New Curriculum Item'}
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Content</label>
            <textarea
              value={formData.content}
              onChange={(e) => setFormData({ ...formData, content: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
              rows="3"
              required
            />
          </div>
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {editingId ? 'Update Curriculum Item' : 'Add Curriculum Item'}
          </button>
          {editingId && (
            <button
              type="button"
              onClick={() => {
                setEditingId(null);
                setFormData({ title: '', content: '' });
              }}
              className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
            >
              Cancel
            </button>
          )}
        </form>
      </div>

      {/* Curriculum List */}
      <div className="bg-white rounded-lg shadow-lg mt-5">
        <div className="p-6">
          <h3 className="text-lg font-medium text-gray-900">Curriculum Items</h3>
        </div>
        <ul className="divide-y divide-gray-200">
          {curriculums.map((curriculum) => (
            <li key={curriculum.curriculum_id} className="px-4 py-4 sm:px-6">
              <h4 className="text-md font-medium text-gray-900">{curriculum.title}</h4>
              <p className="mt-1 text-sm text-gray-600">{curriculum.content}</p>
              <div className="mt-2 flex space-x-4">
                <button
                  onClick={() => handleEdit(curriculum)}
                  className="text-blue-600 hover:text-blue-800 focus:outline-none"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(curriculum.curriculum_id)}
                  className="text-red-600 hover:text-red-800 focus:outline-none"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CurriculumManager;
