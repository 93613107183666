import { FaDatabase, FaRobot, FaNetworkWired, FaFlask, FaExchangeAlt } from "react-icons/fa";

const BigDataAnalyticsPage = () => {
  const sections = [
    {
      category: "Analytics Platforms",
      icon: <FaDatabase />,
      description:
        "Utilize powerful platforms like Apache Spark, Google BigQuery, AWS Redshift, and Databricks for large-scale analytics.",
    },
    {
      category: "Machine Learning",
      icon: <FaRobot />,
      description:
        "Leverage cutting-edge frameworks such as TensorFlow, PyTorch, H2O.ai, and Google AI Platform to build intelligent systems.",
    },
    {
      category: "Data Modeling",
      icon: <FaNetworkWired />,
      description:
        "Design optimized data models using tools like DBT (Data Build Tool), Apache Hive, and Presto for efficient querying.",
    },
    {
      category: "Data Science Tools",
      icon: <FaFlask />,
      description:
        "Empower analytics with Jupyter Notebooks, R, and Python libraries like Pandas and Scikit-learn for exploratory data analysis and predictive modeling.",
    },
    {
      category: "Legacy Database Migration",
      icon: <FaExchangeAlt />,
      description:
        "Migrate legacy databases (Oracle, Sybase, DB2, MySQL) to modern solutions such as SQL Server, PostgreSQL, or Cloud-based databases like AWS RDS and Google Cloud SQL.",
    },
    {
      category: "Decommissioning Tools",
      icon: <FaDatabase />,
      description:
        "Streamline database decommissioning with CloudEndure, AWS DMS, Azure Database Migration Service, and DBMovr for seamless transitions.",
    },
  ];

  return (
    <div className="bg-gray-50">
  <div className="bg-gradient-to-r mt-20 from-blue-500 to-blue-800 text-white py-16 px-6 text-center">
  <h1 className="text-4xl md:text-5xl font-bold">Big Data Analytics & Advanced Analytics</h1>
        <p className="text-lg mt-4">
          Explore advanced tools and platforms to unlock the true potential of your data.
        </p>
        <button class="bg-white text-blue-500 px-6 py-3 rounded-full shadow-md hover:bg-gray-100 transition duration-300">
        Learn More
      </button>
      </div>

      <section className="py-12">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-8">Key Tools and Platforms</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {sections.map((section, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md p-6 text-center hover:shadow-lg transition duration-300"
              >
                <div className="text-4xl text-blue-500 mb-4">{section.icon}</div>
                <h3 className="text-xl font-bold mb-2 text-blue-500">{section.category}</h3>
                <p className="text-gray-600">{section.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div class="flex justify-center items-center h-auto py-10 bg-gray-50">
  <div class="bg-white rounded-lg shadow-md p-6">
    <img
      src="/BigDataAnalytics/napkin-selection (12).svg"
      alt="Descriptive Title"
      class="w-full h-auto object-contain rounded-lg"
    />
  </div>
</div>


<div class="py-10 bg-gray-50">
  {/* <!-- Section 1 --> */}
  <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-6">
    <div class="bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/BigDataAnalytics/napkin-selection (13).svg"
        alt="Big Data Purpose"
        class="w-auto h-72 object-contain rounded-lg"
      />
    </div>
    <div>
      <h2 class="text-2xl font-bold mb-4">Purpose of Big Data Analytics</h2>
      <p class="text-gray-700 mb-2">
        Big Data Analytics and Advanced Analytics empower businesses to process massive datasets for actionable insights. These technologies enhance decision-making, boost operational efficiency, and enable predictive modeling to stay ahead in competitive markets.
      </p>
    </div>
  </div>

  {/* <!-- Section 2 --> */}
  <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-6">
    <div>
      <h2 class="text-2xl font-bold mb-4">Analytics Platforms</h2>
      <p class="text-gray-700 mb-2">
        Tools like Apache Spark, Google BigQuery, AWS Redshift, and Databricks revolutionize data processing. They enable distributed data analysis, high-speed querying, and integration of advanced AI and ML capabilities for robust insights.
      </p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/BigDataAnalytics/napkin-selection (14).svg"
        alt="Analytics Platforms"
        class="w-auto h-72 object-contain rounded-lg"
      />
    </div>
  </div>

  {/* <!-- Section 3 --> */}
  <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-6">
    <div class="bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/BigDataAnalytics/napkin-selection (15).svg"
        alt="Machine Learning Tools"
        class="w-auto h-72 object-contain rounded-lg"
      />
    </div>
    <div>
      <h2 class="text-2xl font-bold mb-4">Machine Learning</h2>
      <p class="text-gray-700 mb-2">
        TensorFlow, PyTorch, H2O.ai, and Google AI Platform enable scalable development of advanced machine learning models. From image recognition to fraud detection, these tools provide the foundation for cutting-edge AI-driven solutions.
      </p>
    </div>
  </div>

  {/* <!-- Section 4 --> */}
  <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-6">
    <div>
      <h2 class="text-2xl font-bold mb-4">Data Modeling</h2>
      <p class="text-gray-700 mb-2">
        Tools like DBT, Apache Hive, and Presto streamline the creation of efficient data models. They support the transformation of raw data into actionable insights, enabling real-time querying and powering modern business intelligence systems.
      </p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/BigDataAnalytics/napkin-selection (16).svg"
        alt="Data Modeling Tools"
        class="w-auto h-72 object-contain rounded-lg"
      />
    </div>
  </div>

  {/* <!-- Section 5 --> */}
  <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-6">
    <div class="bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/BigDataAnalytics/napkin-selection (17).svg"
        alt="Data Science Tools"
        class="w-auto h-72 object-contain rounded-lg"
      />
    </div>
    <div>
      <h2 class="text-2xl font-bold mb-4">Data Science Tools</h2>
      <p class="text-gray-700 mb-2">
        Jupyter Notebooks, R, and Python libraries like Pandas and Scikit-learn are essential for data science workflows. They empower analysts to explore, clean, visualize, and model data for predictive and prescriptive insights.
      </p>
    </div>
  </div>

  {/* <!-- Section 6 --> */}
  <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-6">
    <div>
      <h2 class="text-2xl font-bold mb-4">Legacy Database Migration</h2>
      <p class="text-gray-700 mb-2">
        Migrating from legacy systems to modern cloud-based solutions like AWS RDS or Google Cloud SQL ensures scalability, improved performance, and seamless integration with advanced analytics platforms.
      </p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/BigDataAnalytics/napkin-selection (21).svg"
        alt="Legacy Database Migration"
        class="w-auto h-72 object-contain rounded-lg"
      />
    </div>
  </div>

  {/* <!-- Section 7 --> */}
  <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-6">
    <div class="bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/BigDataAnalytics/napkin-selection (19).svg"
        alt="Decommissioning Tools"
        class="w-auto h-72 object-contain rounded-lg"
      />
    </div>
    <div>
      <h2 class="text-2xl font-bold mb-4">Decommissioning Tools</h2>
      <p class="text-gray-700 mb-2">
        Tools like CloudEndure, AWS DMS, and Azure Database Migration Service simplify migrations with minimal downtime. They ensure accurate schema conversions and efficient data movement across platforms.
      </p>
    </div>
  </div>
</div>



      <footer className="bg-blue-500 text-white py-6 text-center">
        <p className="text-lg">
          Ready to transform your data analytics strategy?{" "}
          <a
            href="#contact"
            className="underline font-bold hover:text-blue-300 transition"
          >
            Contact us today!
          </a>
        </p>
      </footer>
    </div>
  );
};

export default BigDataAnalyticsPage;
