import React from "react";
import { useParams } from "react-router-dom";
import Accordion from "./Accordion";

const CourseDetails = ({ courses }) => {
  const { link } = useParams();
  const course = courses.find((course) => course.link === `/courses/${link}`);

  if (!course) {
    return (
      <div className="container mx-auto text-center py-20">
        <h2 className="text-3xl font-bold">Course Not Found</h2>
        <p className="mt-4 text-gray-600">
          We couldn't find the course you're looking for.
        </p>
      </div>
    );
  }

  return (
    <div className="container my-20 mx-auto px-6">
      {/* Hero Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
        {/* Left Content */}
        <div className="text-center lg:text-left">
          <span className="text-sm font-semibold text-blue-600 uppercase bg-blue-100 rounded-full px-3 py-1 inline-block">
            Skill Development
          </span>
          <h1 className="mt-4 text-4xl md:text-5xl font-extrabold text-gray-800 leading-tight">
            {course.title}
          </h1>
          <p className="mt-6 text-lg text-gray-600 leading-relaxed">
            {course.description}
          </p>
          <div className="mt-8">
            <button className="rounded-lg bg-blue-500 text-white px-6 py-3 text-lg font-semibold shadow-md hover:bg-blue-600 transition duration-300">
              Download Curriculum
            </button>
          </div>
        </div>

        {/* Right Content */}
        <div className="relative px-28 py-20">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img
              src={course.image}
              alt={course.title}
              className="w-full h-auto"
            />
            <div className="text-center py-6">
              <p className="bg-blue-100 text-blue-600 inline-block px-3 py-1 rounded-full text-sm font-semibold">
                Upcoming Batches
              </p>
              <h3 className="mt-4 text-xl font-bold text-gray-800">
                {course.title}
              </h3>
              <div className="flex flex-col sm:flex-row justify-center items-center gap-4 mt-4">
                {course.batches.map((batch, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <div className="p-2 bg-blue-500 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h4V0h2v2h4V0h2v2h4zm0 2H2v14h16V4zM4 8h12v2H4V8zm0 4h12v2H4v-2z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="text-gray-700 font-semibold">
                        {batch.date}
                      </p>
                      <p
                        className={`text-sm ${
                          batch.status === "Ongoing"
                            ? "text-green-600"
                            : "text-red-600"
                        }`}
                      >
                        {batch.status}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <button className="mt-6 bg-blue-500 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-600 transition duration-300">
                Apply Now
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Tech Stacks Section */}
      <div className="mt-20">
        <h2 className="text-3xl font-bold text-gray-800">Tech Stacks</h2>
        <div className="mt-6 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
          {course.techStacks.map((tech, index) => (
            <span
              key={index}
              className="bg-blue-100 text-blue-800 px-4 py-2 text-center rounded-lg font-medium text-sm"
            >
              {tech}
            </span>
          ))}
        </div>
      </div>

      {/* Curriculum Section */}
      <div className="mt-16">
        <h2 className="text-3xl font-bold text-gray-800">Curriculum</h2>
        <div className="mt-6 bg-white shadow-lg rounded-lg divide-y">
          <Accordion items={course.curriculum} />
        </div>
      </div>
    </div>
  );
};

export default CourseDetails;
