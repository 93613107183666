import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SpeakerForm from './SpeakerForm';
import SpeakerList from './SpeakerList';
import Loading from '../common/Loading';
import Error from '../common/Error';
import { fetchSpeakerById } from '../../../../lib/api';

const SpeakerManager = () => {
  const { id: bootcampId } = useParams();
  const [speakers, setSpeakers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false); // Toggle state for the form

  useEffect(() => {
    loadSpeakers();
  }, [bootcampId]);

  const loadSpeakers = async () => {
    try {
      setLoading(true);
      const data = await fetchSpeakerById(bootcampId);
      setSpeakers(data.filter((speaker) => speaker.bootcamp_id === parseInt(bootcampId)));
      setError(null);
    } catch (err) {
      setError('Failed to load speakers. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;
  if (error) return <Error message={error} />;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h2 className="text-3xl font-extrabold text-blue-800 mb-6">Speaker Management</h2>

      {/* Add Speaker Button */}
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-semibold text-gray-800">Speakers</h3>
        <button
          onClick={() => setShowForm((prev) => !prev)}
          className="bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 focus:outline-none"
        >
          {showForm ? 'Cancel' : 'Add Speaker'}
        </button>
      </div>

      {/* Speaker Form - Visible only when showForm is true */}
      {showForm && (
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Add New Speaker</h3>
          <SpeakerForm bootcampId={bootcampId} onSuccess={() => {
            loadSpeakers();
            setShowForm(false); // Hide the form after successful submission
          }} />
        </div>
      )}

      {/* Speaker List */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <SpeakerList speakers={speakers} onUpdate={loadSpeakers} />
      </div>
    </div>
  );
};

export default SpeakerManager;
