import React from 'react';
import { PlusCircle, Trash2 } from 'lucide-react';
import { Project } from '../../../types/resume';
import SkillsInput from './SkillsInput';

interface Props {
  projects: Project[];
  onChange: (projects: Project[]) => void;
}

export default function ProjectsForm({ projects, onChange }: Props) {
  const handleAdd = () => {
    onChange([
      ...projects,
      { 
        id: crypto.randomUUID(), 
        title: '', 
        description: '', 
        skills: [],
        githubLink: '',
        demoLink: ''
      }
    ]);
  };

  const handleRemove = (id: string) => {
    onChange(projects.filter(project => project.id !== id));
  };

  const handleChange = (id: string, field: keyof Project, value: string | string[]) => {
    onChange(
      projects.map(project =>
        project.id === id ? { ...project, [field]: value } : project
      )
    );
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-800">Projects</h2>
        <button
          type="button"
          onClick={handleAdd}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <PlusCircle className="h-5 w-5 mr-2" />
          Add Project
        </button>
      </div>
      <div className="space-y-6">
        {projects.map((project) => (
          <div key={project.id} className="p-4 bg-gray-50 rounded-lg space-y-4">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => handleRemove(project.id)}
                className="text-red-600 hover:text-red-800"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </div>
            <div className="grid gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Project Title</label>
                <input
                  type="text"
                  value={project.title}
                  onChange={(e) => handleChange(project.id, 'title', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  value={project.description}
                  onChange={(e) => handleChange(project.id, 'description', e.target.value)}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div className="grid gap-4 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700">GitHub Link</label>
                  <input
                    type="url"
                    value={project.githubLink}
                    onChange={(e) => handleChange(project.id, 'githubLink', e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Demo Link</label>
                  <input
                    type="url"
                    value={project.demoLink}
                    onChange={(e) => handleChange(project.id, 'demoLink', e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Technologies Used</label>
                <SkillsInput
                  skills={project.skills}
                  onChange={(skills) => handleChange(project.id, 'skills', skills)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}