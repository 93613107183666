import React from "react";

function RTDP() {
  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <div className="relative mt-20 bg-blue-500 text-white py-20">
        <div className="container mx-auto px-6 md:px-12 text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            Real-Time Data Processing
          </h1>
          <p className="text-lg md:text-xl mb-6">
            Transform your business with cutting-edge real-time data streaming,
            event-driven architecture, and high-performance processing engines.
          </p>
          <button className="bg-white text-blue-500 px-6 py-3 rounded-full shadow-md hover:bg-gray-100 transition duration-300">
            Learn More
          </button>
        </div>
      </div>

      {/* Overview Image Section */}
      <div className="flex justify-center items-center py-10 bg-gray-50">
        <div className="bg-white rounded-lg shadow-md p-6 max-w-full md:max-w-lg">
          <img
            src="/RTDP/1.svg"
            alt="Real-Time Data Processing Overview"
            className="w-full h-auto object-contain rounded-lg"
          />
        </div>
      </div>

      {/* Section 1: Real-Time Stream Processing */}
<div className="py-12">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-4 sm:px-6">
    <div className="order-1 md:order-1">
      <h2 className="text-2xl font-bold mb-4 text-center md:text-left">Real-Time Stream Processing</h2>
      <p className="text-gray-700 mb-4 text-center md:text-left">
        Platforms like Apache Kafka, AWS Kinesis, Apache Pulsar, and Google Pub/Sub enable real-time data streaming to process and analyze data as it arrives, ensuring timely insights and decisions.
      </p>
    </div>
    <div className="bg-white rounded-lg shadow-md p-6 flex justify-center order-2 md:order-2">
      <img
        src="/RTDP/2.svg"
        alt="Real-Time Stream Processing"
        className="w-full h-64 object-contain rounded-lg"
      />
    </div>
  </div>
</div>

{/* Section 2: Data Processing Engines */}
<div className="py-12 bg-gray-100">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-4 sm:px-6">
    <div className="order-1 md:order-2">
      <h2 className="text-2xl font-bold mb-4 text-center md:text-left">Data Processing Engines</h2>
      <p className="text-gray-700 mb-4 text-center md:text-left">
        High-performance engines like Apache Flink, Apache Storm, and Spark Streaming process data streams in real-time, delivering low-latency and scalable data pipelines for modern applications.
      </p>
    </div>
    <div className="bg-white rounded-lg shadow-md p-6 flex justify-center order-2 md:order-1">
      <img
        src="/RTDP/3.svg"
        alt="Data Processing Engines"
        className="w-full h-64 object-contain rounded-lg"
      />
    </div>
  </div>
</div>

{/* Section 3: Event-Driven Architecture */}
<div className="py-12">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-4 sm:px-6">
    <div className="order-1 md:order-1">
      <h2 className="text-2xl font-bold mb-4 text-center md:text-left">Event-Driven Architecture</h2>
      <p className="text-gray-700 mb-4 text-center md:text-left">
        Services like AWS Lambda, Google Cloud Functions, and Azure Functions power event-driven workflows, ensuring seamless execution of tasks in response to events or triggers.
      </p>
    </div>
    <div className="bg-white rounded-lg shadow-md p-6 flex justify-center order-2 md:order-2">
      <img
        src="/RTDP/4.svg"
        alt="Event-Driven Architecture"
        className="w-full h-64 object-contain rounded-lg"
      />
    </div>
  </div>
</div>


      {/* Call-to-Action Section */}
      <div className="py-12 bg-blue-500 text-white text-center">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-4">Unlock Real-Time Insights</h2>
          <p className="mb-6">
            Leverage the power of real-time data processing to drive innovation
            and gain a competitive edge.
          </p>
          <button className="bg-white text-blue-500 px-6 py-3 rounded-full shadow-md hover:bg-gray-100 transition duration-300">
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
}

export default RTDP;
