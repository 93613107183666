const courses = [
    {
      id: 1,
      title: "Cybersecurity Bootcamp",
      description:
        "Unlock the world of cybersecurity with our 'Cybersecurity Bootcamp: From Hacking Fundamentals to Governance, Risk & Compliance.' This course covers hacking fundamentals, vulnerability assessment, compliance audits, and more.",
      image: "https://via.placeholder.com/300",
      instructors: [
        {
          name: "Instructor 1",
          image: "https://randomuser.me/api/portraits/men/11.jpg",
        },
        {
          name: "Instructor 2",
          image: "https://randomuser.me/api/portraits/women/15.jpg",
        },
      ],
      link: "/courses/cybersecurity-bootcamp",
      batches: [
        { date: "December 7, 2024", status: "Ongoing" },
        { date: "September 14, 2024", status: "Closed Enrollment" },
      ],
      techStacks: [
        "Kali Linux",
        "Splunk",
        "OSSEC",
        "Nikto",
        "CyberArk",
        "Acunetix",
        "Wireshark",
        "Nessus",
        "Burp Suite",
        "Snort",
      ],
      curriculum: [
        "What is Governance, Risk and Compliance? and its significance?",
        "Understanding of Information Security strategy",
        "Asset and Data Management Fundamentals",
        "Risk Assessment Walkthrough- Part1",
        "Risk Assessment Walkthrough- Part2",
        "Practical Risk Analysis",
        "Information Security Controls Identification and Implementation",
        "Overview of Business Continuity Planning and Disaster Recovery Plan",
        "Supply Chain Risk Assessment",
        "Introductory Linux",
        "Hacking Methodology",
        "Reconnaissance and Information Gathering Passive",
        "Scanning and Enumeration",
        "Exploitation Basics",
      ],
    },
    {
      id: 2,
      title: "Google Cloud Engineering",
      description:
        "This introductory course offers a comprehensive overview of Google Cloud Platform (GCP), providing participants with a foundational understanding of cloud computing concepts.",
      image: "https://via.placeholder.com/300",
      instructors: [
        {
          name: "Instructor 3",
          image: "https://randomuser.me/api/portraits/men/23.jpg",
        },
      ],
      link: "/courses/google-cloud-engineering",
      batches: [{ date: "October 20, 2024", status: "Ongoing" }],
      techStacks: [
        "Google Cloud Storage",
        "BigQuery",
        "Cloud Functions",
        "Cloud Run",
        "Firebase",
      ],
      curriculum: [
        "Introduction to Google Cloud Platform",
        "Virtual Machines and Compute Engine",
        "Google Kubernetes Engine Basics",
        "Networking in Google Cloud",
        "Managing Data Storage and Databases",
        "Cloud Monitoring and Troubleshooting",
      ],
    },
    {
      id: 3,
      title: "Azure Data Engineering",
      description:
        "This course provides a comprehensive introduction to Microsoft Azure, a leading cloud computing platform. Learn how to create, manage, and secure data pipelines.",
      image: "https://via.placeholder.com/300",
      instructors: [
        {
          name: "Instructor 4",
          image: "https://randomuser.me/api/portraits/women/22.jpg",
        },
        {
          name: "Instructor 5",
          image: "https://randomuser.me/api/portraits/men/33.jpg",
        },
      ],
      link: "/courses/azure-data-engineering",
      batches: [
        { date: "January 10, 2025", status: "Upcoming" },
        { date: "November 15, 2024", status: "Closed Enrollment" },
      ],
      techStacks: [
        "Azure Data Factory",
        "Azure Synapse",
        "Azure Databricks",
        "Power BI",
        "Azure Cosmos DB",
      ],
      curriculum: [
        "Introduction to Azure Cloud",
        "Building Data Pipelines with Azure Data Factory",
        "Data Analysis using Azure Synapse",
        "Machine Learning with Azure Databricks",
        "Visualization with Power BI",
        "Securing Data with Azure Cosmos DB",
      ],
    },
  ];

  export default courses;
