import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchAllBootcamps, deleteBootcamp } from '../../../../lib/api';

const BootcampList = () => {
  const [bootcamps, setBootcamps] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadBootcamps();
  }, []);

  const loadBootcamps = async () => {
    try {
      const data = await fetchAllBootcamps();
      setBootcamps(data);
    } catch (error) {
      console.error('Error loading bootcamps:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this bootcamp?')) {
      try {
        await deleteBootcamp(id);
        await loadBootcamps();  // Refresh the list after deleting an item
      } catch (error) {
        console.error('Error deleting bootcamp:', error);
      }
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className="bg-white rounded-lg shadow-lg mx-4 my-5">
      <div className="p-6">
        <h2 className="text-xl font-semibold text-gray-800">Bootcamps</h2>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Title
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Duration
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Class Time
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {bootcamps.map((bootcamp) => (
              <tr key={bootcamp.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{bootcamp.title}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{bootcamp.duration}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{bootcamp.class_time}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <div className="flex items-center space-x-3">
                    <Link to={`/bootcamp/${bootcamp.bootcamp_id}/curriculum`} className="text-blue-600 hover:text-blue-800">Curriculum</Link>
                    <Link to={`/bootcamp/${bootcamp.bootcamp_id}/faqs`} className="text-green-600 hover:text-green-800">FAQs</Link>
                    <Link to={`/bootcamp/${bootcamp.bootcamp_id}/speakers`} className="text-purple-600 hover:text-purple-800">Speakers</Link>
                    <Link to={`/bootcamp/edit/${bootcamp.bootcamp_id}`} className="text-yellow-600 hover:text-yellow-800">Edit</Link>
                    <button onClick={() => handleDelete(bootcamp.id)} className="text-red-600 hover:text-red-800 focus:outline-none">
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BootcampList;
