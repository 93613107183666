import React from 'react';
import { Button } from '../../ui/button';
import { X } from 'lucide-react';

interface ModulesProps {
  formData: {
    modules: {
      curriculum_id?: string;
      title: string;
      content: string;
    }[];
  };
  onChange: (field: string, value: any) => void;
  onNext: () => void;
  onBack: () => void;
}

export function Modules({ formData, onChange, onNext, onBack }: ModulesProps) {
  const addModule = () => {
    onChange('modules', [...formData.modules, { title: '', content: '' }]);
  };

  const removeModule = async (index: number) => {
    const module = formData.modules[index];
    if (module.curriculum_id) {
      // API call to delete the module from the database
      await fetch(`/api/modules/${module.curriculum_id}`, { method: 'DELETE' });
    }
    const newModules = formData.modules.filter((_, i) => i !== index);
    onChange('modules', newModules);
  };

  const updateModule = async (index: number, field: 'title' | 'content', value: string) => {
    const newModules = formData.modules.map((module, i) => 
      i === index ? { ...module, [field]: value } : module
    );
    if (newModules[index].curriculum_id) {
      console.log(newModules[index].curriculum_id);
      
      // API call to update the module in the database
      await fetch(`/api/modules/${newModules[index].curriculum_id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ [field]: value })
      });
    }
    onChange('modules', newModules);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-blue-900">Modules</h2>
      <div className="space-y-6">
        {formData.modules.map((module, index) => (
          <div key={index} className="p-4 border rounded-lg space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">Module {index + 1}</h3>
              <Button
                variant="outline"
                size="icon"
                onClick={() => removeModule(index)}
                className="text-red-500"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Title
              </label>
              <input
                type="text"
                value={module.title}
                onChange={(e) => updateModule(index, 'title', e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                placeholder="Module title"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Content
              </label>
              <textarea
                value={module.content}
                onChange={(e) => updateModule(index, 'content', e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                rows={4}
                placeholder="Module content"
              />
            </div>
          </div>
        ))}
        <Button variant="outline" onClick={addModule}>
          Add Module
        </Button>
      </div>
      <div className="flex justify-between">
        <Button variant="outline" onClick={onBack}>Back</Button>
        <Button onClick={onNext}>Next</Button>
      </div>
    </div>
  );
}