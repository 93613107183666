// src/components/bootcamp/BootcampManagement.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchAllBootcamps, deleteBootcamp } from '../../../../lib/api';
import BootcampTable from './BootcampList';

const BootcampManagement = () => {
  const [bootcamps, setBootcamps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadBootcamps();
  }, []);

  const loadBootcamps = async () => {
    try {
      setLoading(true);
      const data = await fetchAllBootcamps();
      setBootcamps(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this bootcamp?')) {
      try {
        await deleteBootcamp(id);
        await loadBootcamps();
      } catch (err) {
        setError(err.message);
      }
    }
  };

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-red-500 text-center">{error}</div>;

  return (
    <div>
      <div className="mb-6">
        <h1 className="text-3xl font-bold">Bootcamps</h1>
      </div>
      <BootcampTable bootcamps={bootcamps} onDelete={handleDelete} />
    </div>
  );
};

export default BootcampManagement;