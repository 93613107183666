import axios from "axios";

const api = axios.create({
  baseURL: "https://api.blueblockss.ai:5002/api", // Replace with your API base URL
});

// Attach token to all requests
api.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
