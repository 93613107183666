import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import TimezoneSelect from 'react-timezone-select';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';

interface PersonalInfoData {
  fullName: string;
  email: string;
  phone: string;
  residence: string;
  timezone: string;
  role: string;
  password: string;
}

interface Props {
  data: PersonalInfoData;
  onChange: (data: PersonalInfoData) => void;
}

const roles = [
  'Student',
  'Professional',
  'Beginner in AI/IT',
  'Beginner in Data Science',
];

export default function PersonalInfo({ data, onChange }: Props) {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [passwordError, setPasswordError] = useState<string>('');

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    // Update the state for the field
    onChange({ ...data, [name]: value });

    // Address autocomplete logic for 'residence' field
    if (name === 'residence' && value.length > 2) {
      try {
        const response = await axios.get('https://nominatim.openstreetmap.org/search', {
          params: {
            q: value,
            format: 'json',
            addressdetails: 1,
            limit: 10,
          },
        });

        // Extract display names from API response
        const results = response.data.map((item: any) => item.display_name);
        setSuggestions(results);
      } catch (error) {
        console.error('Error fetching address suggestions:', error);
      }
    }

    // Clear suggestions if the input is too short
    if (name === 'residence' && value.length <= 2) {
      setSuggestions([]);
    }
  };

  const handlePhoneChange = (value: string) => {
    onChange({ ...data, phone: value });
  };

  const handleTimezoneChange = (value: any) => {
    onChange({ ...data, timezone: value.value });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;

    if (!passwordPattern.test(password)) {
      setPasswordError(
        'Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, and a number.'
      );
    } else {
      setPasswordError('');
    }

    onChange({ ...data, password });
  };

  const handleSuggestionClick = (suggestion: string) => {
    onChange({ ...data, residence: suggestion });
    setSuggestions([]);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold text-gray-900">Personal Information</h2>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div>
          <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
            Full Name
          </label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={data.fullName}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email Address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={data.email}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
            Phone Number (with country code)
          </label>
          <PhoneInput
            country={'us'} // Default country
            value={data.phone}
            onChange={handlePhoneChange}
            inputClass="!w-full !rounded-md !border-gray-300 !shadow-sm !focus:border-blue-500 !focus:ring-blue-500"
          />
        </div>

        <div className="relative">
          <label htmlFor="residence" className="block text-sm font-medium text-gray-700">
            Place of Residence
          </label>
          <input
            type="text"
            id="residence"
            name="residence"
            value={data.residence}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
          {suggestions.length > 0 && (
            <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="cursor-pointer px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={data.password}
            onChange={handlePasswordChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
          {passwordError && (
            <p className="text-sm text-red-500 mt-1">{passwordError}</p>
          )}
        </div>

        <div>
          <label htmlFor="timezone" className="block text-sm font-medium text-gray-700">
            Time Zone
          </label>
          <TimezoneSelect
            value={data.timezone}
            onChange={handleTimezoneChange}
            className="!mt-1 !block !w-full !rounded-md !border-gray-300 !shadow-sm !focus:border-blue-500 !focus:ring-blue-500"
          />
        </div>

        <div>
          <label htmlFor="role" className="block text-sm font-medium text-gray-700">
            Role/Designation
          </label>
          <select
            id="role"
            name="role"
            value={data.role}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Select a role</option>
            {roles.map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
