import React from 'react'
import UserDashboard from './UserDashboard'
import UserSidebar from '../components/user/UserSidebar'
import Header from '../components/dashboard/Header'
import { useAuth } from '../contexts/AuthContext';

function User_dashboard() {
  const { email } = useAuth();

  return (
    <div className="flex h-screen bg-gray-100">
    <UserSidebar />
    <div className="flex-1 flex flex-col overflow-hidden">
      <Header email={email}/>
      <main className="flex-1 overflow-y-auto">
         
         <UserDashboard />
      </main>
    </div>
  </div>
  )
}

export default User_dashboard