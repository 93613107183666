const BASE_URL = 'https://api.blueblockss.ai:5002/api/bootcamp';


export async function fetchAllBootcamps() {
  const response = await fetch(`${BASE_URL}`);
  if (!response.ok) throw new Error('Failed to fetch bootcamp');
  return response.json();
}



// Basic Bootcamp Operations
export async function fetchBootcampById(id: string) {
  const response = await fetch(`${BASE_URL}/basic/${id}`);
  if (!response.ok) throw new Error('Failed to fetch bootcamp');
  return response.json();
}

export async function updateBootcamp(id: string, data: any) {
    console.log(data);
    
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error('Failed to update bootcamp');
  return response.json();
}

export async function deleteBootcamp(id: string) {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: 'DELETE',
  });
  if (!response.ok) throw new Error('Failed to delete bootcamp');
  return response.json();
}

// Speakers Operations
export async function fetchAllSpeakers() {
  const response = await fetch(`${BASE_URL}/speakers/all`);
  if (!response.ok) throw new Error('Failed to fetch speakers');
  return response.json();
}

export async function fetchSpeakerById(id: string) {
  const response = await fetch(`${BASE_URL}/speakers/${id}`);
  if (!response.ok) throw new Error('Failed to fetch speaker');
  return response.json();
}

export async function createBootcamp(data: any, file?: File) {
    console.log(data);
    
    const formData = new FormData();

    // Append file if provided
    if (file) {
        formData.append("image", file); // Use the key expected by your backend for the file
    }

    // Append other data fields individually
    Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            // Handle arrays, e.g., convert to a comma-separated string
            formData.append(key, value.join(","));
        } else if (value !== null && value !== undefined) {
            // Append other fields (skipping null or undefined)
            formData.append(key, value.toString());
        }
    });

    // Make the request
    const response = await fetch(`${BASE_URL}/create`, {
        method: 'POST',
        body: formData, // FormData automatically sets the appropriate headers
    });

    if (!response.ok) throw new Error('Failed to create bootcamp');
    return response.json();
}


export async function createSpeaker(data: any) {
  const response = await fetch(`${BASE_URL}/speakers/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error('Failed to create speaker');
  return response.json();
}

export async function updateSpeaker(bootcampId: string, speakerId: string, data: any) {
  const response = await fetch(`${BASE_URL}/speakers/update/${bootcampId}/${speakerId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error('Failed to update speaker');
  return response.json();
}

export async function deleteSpeaker(bootcampId: string, speakerId: string) {
  const response = await fetch(`${BASE_URL}/speakers/delete/${bootcampId}/${speakerId}`, {
    method: 'DELETE',
  });
  if (!response.ok) throw new Error('Failed to delete speaker');
  return response.json();
}

// Curriculum Operations
export async function fetchAllCurriculums() {
  const response = await fetch(`${BASE_URL}/curriculum/all`);
  if (!response.ok) throw new Error('Failed to fetch curriculums');
  return response.json();
}

export async function fetchCurriculumById(id: string) {
  const response = await fetch(`${BASE_URL}/curriculum/${id}`);
  if (!response.ok) throw new Error('Failed to fetch curriculum');
  return response.json();
}

export async function createCurriculum(data: any) {
  const response = await fetch(`${BASE_URL}/curriculum`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error('Failed to create curriculum');
  return response.json();
}

export async function updateCurriculum(bootcampId: string, curriculumId: string, data: any) {
  const response = await fetch(`${BASE_URL}/curriculum/${bootcampId}/${curriculumId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error('Failed to update curriculum');
  return response.json();
}

export async function deleteCurriculum(bootcampId: string, curriculumId: string) {
  const response = await fetch(`${BASE_URL}/curriculum/${bootcampId}/${curriculumId}`, {
    method: 'DELETE',
  });
  if (!response.ok) throw new Error('Failed to delete curriculum');
  return response.json();
}

// FAQ Operations
export async function fetchAllFaqs() {
  const response = await fetch(`${BASE_URL}/faqs/all`);
  if (!response.ok) throw new Error('Failed to fetch FAQs');
  return response.json();
}

export async function fetchFaqById(id: string) {
  const response = await fetch(`${BASE_URL}/faqs/${id}`);
  if (!response.ok) throw new Error('Failed to fetch FAQ');
  return response.json();
}

export async function createFaq(data: any) {
  const response = await fetch(`${BASE_URL}/faqs`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error('Failed to create FAQ');
  return response.json();
}

export async function updateFaq(bootcampId: string, faqId: string, data: any) {
  const response = await fetch(`${BASE_URL}/faqs/${bootcampId}/${faqId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error('Failed to update FAQ');
  return response.json();
}

export async function deleteFaq(bootcampId: string, faqId: string) {
  const response = await fetch(`${BASE_URL}/faqs/${bootcampId}/${faqId}`, {
    method: 'DELETE',
  });
  if (!response.ok) throw new Error('Failed to delete FAQ');
  return response.json();
}