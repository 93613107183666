import React from "react";
import { FaRocket, FaShieldAlt, FaSmile, FaChartLine } from "react-icons/fa";

const servicesDescription =
  "We deliver scalable, secure, and AI-driven Big Data solutions to help your business thrive. From data architecture and migration to real-time processing and ongoing support, we turn data into actionable insights that drive growth and efficiency.";

const stats = [
  {
    icon: <FaRocket size={30} className="text-blue-500" />,
    title: "100%",
    subtitle: "Enhanced Efficiencies",
  },
  {
    icon: <FaShieldAlt size={30} className="text-blue-500" />,
    title: "100%",
    subtitle: "Strong Security Safeguards",
  },
  {
    icon: <FaSmile size={30} className="text-blue-500" />,
    title: "100%",
    subtitle: "Completely Accelerated User Satisfaction",
  },
  {
    icon: <FaChartLine size={30} className="text-blue-500" />,
    title: "100%",
    subtitle: "Market Advantage",
  },
];

const ServicesSection = () => {
  return (
    <section className="bg-gradient-to-r mt-20 from-blue-500 to-blue-800 text-white py-16">
      <div className="container mx-auto">
        {/* Services Section */}
        <div className="flex flex-col lg:flex-row items-center lg:space-x-12 mb-16">
  {/* Image Section */}
  <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
    <img
      src="https://images.unsplash.com/photo-1628348068343-c6a848d2b6dd?q=80&w=600&h=400&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      alt="Rapid Software Services"
      className="rounded-lg shadow-md w-full h-auto object-cover"
    />
  </div>

  {/* Text Section */}
  <div className="lg:w-1/2 w-full text-center lg:text-left">
    <h2 className="text-3xl font-bold mb-6">Unlock the Power of Data with BlueBlockss
    </h2>
    <p className="text-lg leading-relaxed">{servicesDescription}</p>
    <button className="bg-white text-blue-500 mt-3 px-6 py-3 rounded-full shadow-md hover:bg-gray-100 transition duration-300">
            Book a free call
          </button>
  </div>
</div>


        {/* Why Choose Section */}
        <div className="text-center">
          <h3 className="text-2xl font-bold mb-12">Why Choose Blueblockss</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {stats.map((stat, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center bg-white text-blue-500 p-6 rounded-full shadow-lg"
              >
                {/* Icon */}
                <div className="mb-4">{stat.icon}</div>
                {/* Title */}
                <h4 className="text-3xl font-bold mb-2">{stat.title}</h4>
                {/* Subtitle */}
                <p className="text-center text-sm">{stat.subtitle}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
