import React from "react";

function ML_AI() {
  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <div className="bg-gradient-to-r mt-20 from-blue-500 to-blue-800 text-white py-16 px-6 text-center">
      <div className="container mx-auto px-6 md:px-12 text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            Machine Learning & AI Integration
          </h1>
          <p className="text-lg md:text-xl mb-6">
            Unlock the potential of AI and Machine Learning with cutting-edge frameworks, platforms, and tools.
          </p>
          <button className="bg-white text-blue-500 px-6 py-3 rounded-full shadow-md hover:bg-gray-100 transition duration-300">
            Learn More
          </button>
        </div>
      </div>

      {/* Overview Image Section */}
      <div className="flex justify-center items-center py-10 bg-gray-50">
        <div className="bg-white rounded-lg shadow-md p-6 max-w-full md:max-w-lg">
          <img
            src="/ML_AI/5.svg"
            alt="Machine Learning & AI Overview"
            className="w-full h-auto object-contain rounded-lg"
          />
        </div>
      </div>

{/* Section 1: Machine Learning Frameworks */}
<div className="py-12">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-4 sm:px-6">
    <div className="order-1">
      <h2 className="text-2xl font-bold mb-4 text-center md:text-left">Machine Learning Frameworks</h2>
      <p className="text-gray-700 mb-4 text-center md:text-left">
        Tools like TensorFlow, Keras, PyTorch, and Scikit-learn empower developers to build, train, and deploy machine learning models with ease and efficiency.
      </p>
    </div>
    <div className="bg-white rounded-lg shadow-md p-6 flex justify-center order-2 md:order-2">
      <img
        src="/ML_AI/1.svg"
        alt="Machine Learning Frameworks"
        className="w-full max-w-sm h-auto object-contain rounded-lg"
      />
    </div>
  </div>
</div>

{/* Section 2: ML Operations (MLOps) */}
<div className="py-12 bg-gray-100">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-4 sm:px-6">
    <div className="order-1 md:order-2">
      <h2 className="text-2xl font-bold mb-4 text-center md:text-left">ML Operations (MLOps)</h2>
      <p className="text-gray-700 mb-4 text-center md:text-left">
        MLflow, Kubeflow, and TensorFlow Extended (TFX) streamline the machine learning lifecycle, ensuring reproducibility, scalability, and seamless deployment.
      </p>
    </div>
    <div className="bg-white rounded-lg shadow-md p-6 flex justify-center order-2 md:order-1">
      <img
        src="/ML_AI/2.svg"
        alt="ML Operations (MLOps)"
        className="w-full max-w-sm h-auto object-contain rounded-lg"
      />
    </div>
  </div>
</div>

{/* Section 3: AI Platforms */}
<div className="py-12">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-4 sm:px-6">
    <div className="order-1">
      <h2 className="text-2xl font-bold mb-4 text-center md:text-left">AI Platforms</h2>
      <p className="text-gray-700 mb-4 text-center md:text-left">
        Platforms like AWS SageMaker, Google AI Platform, and Azure Machine Learning provide comprehensive solutions for building and managing AI-driven applications.
      </p>
    </div>
    <div className="bg-white rounded-lg shadow-md p-6 flex justify-center order-2 md:order-2">
      <img
        src="/ML_AI/3.svg"
        alt="AI Platforms"
        className="w-full max-w-sm h-auto object-contain rounded-lg"
      />
    </div>
  </div>
</div>

{/* Section 4: Deep Learning Tools */}
<div className="py-12 bg-gray-100">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-4 sm:px-6">
    <div className="order-1 md:order-2">
      <h2 className="text-2xl font-bold mb-4 text-center md:text-left">Deep Learning Tools</h2>
      <p className="text-gray-700 mb-4 text-center md:text-left">
        Explore the power of tools like OpenCV, Fast.ai, and DeepLearning4J for advanced image processing, computer vision, and deep learning model development.
      </p>
    </div>
    <div className="bg-white rounded-lg shadow-md p-6 flex justify-center order-2 md:order-1">
      <img
        src="/ML_AI/4.svg"
        alt="Deep Learning Tools"
        className="w-full max-w-sm h-auto object-contain rounded-lg"
      />
    </div>
  </div>
</div>



      {/* Call-to-Action Section */}
      <div className="py-12 bg-blue-500 text-white text-center">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-4">Start Your AI Journey</h2>
          <p className="mb-6">
            Discover how Machine Learning and AI integration can transform your business and accelerate innovation.
          </p>
          <button className="bg-white text-blue-500 px-6 py-3 rounded-full shadow-md hover:bg-gray-100 transition duration-300">
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
}

export default ML_AI;
