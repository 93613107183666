import React from "react";
import { ResumeData } from "../../../types/resume";
import {
  Mail,
  Phone,
  Linkedin,
  Github,
  MapPin,
  ExternalLink,
} from "lucide-react";

interface Props {
  data: ResumeData;
  onClose: () => void;
}

export default function ResumePreview({ data, onClose }: Props) {
  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white p-4 border-b flex justify-between items-center">
          <h2 className="text-xl font-semibold">Resume Preview</h2>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-md"
          >
            Close
          </button>
        </div>

        <div className="p-6 space-y-8">
          {/* Header with side-by-side layout */}
          <div className="p-6">
            <div className="flex flex-col md:flex-row items-center md:items-start gap-8">
              {/* Left side - Image */}
              <div className="flex-shrink-0">
                <img
                  src={
                    typeof data.personalInfo.profileImage === "string"
                      ? data.personalInfo.profileImage
                      : "default-avatar-url.png" // Add your default image URL here
                  }
                  alt={data.personalInfo.name}
                  className="w-32 h-32 md:w-40 md:h-40 rounded-full border-4 border-white shadow-xl object-cover"
                />
              </div>

              {/* Right side - Information */}
              <div className="flex-grow text-center md:text-left">
                <h1 className="text-3xl font-bold text-gray-900">
                  {data.personalInfo.name}
                </h1>
                <p className="text-xl text-gray-600 mt-2">
                  {data.personalInfo.title}
                </p>

                <div className="flex flex-wrap justify-center md:justify-start gap-4 mt-4 text-gray-600">
                  <span className="flex items-center gap-1">
                    <MapPin className="h-4 w-4" />
                    {data.personalInfo.location}
                  </span>
                  <span className="flex items-center gap-1">
                    <Mail className="h-4 w-4" />
                    {data.contactInfo.email}
                  </span>
                  <span className="flex items-center gap-1">
                    <Phone className="h-4 w-4" />
                    {data.contactInfo.phone}
                  </span>
                </div>

                <div className="flex justify-center md:justify-start gap-4 mt-4">
                  <a
                    href={data.contactInfo.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800"
                  >
                    <Linkedin className="h-5 w-5" />
                  </a>
                  <a
                    href={data.contactInfo.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800"
                  >
                    <Github className="h-5 w-5" />
                  </a>
                </div>

                {/* About Me section */}
                {data.personalInfo.aboutMe && (
                  <div className="mt-4 text-gray-700">
                    <p className="text-sm leading-relaxed">
                      {data.personalInfo.aboutMe}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Skills */}
          {data.skills.length > 0 && (
            <section>
              <h2 className="text-xl font-semibold mb-3">Skills</h2>
              <div className="flex flex-wrap gap-2">
                {data.skills.map((skill) => (
                  <span
                    key={skill}
                    className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </section>
          )}

          {/* Work Experience */}
          {data.workExperience.length > 0 && (
            <section>
              <h2 className="text-xl font-semibold mb-4">Work Experience</h2>
              <div className="space-y-6">
                {data.workExperience.map((exp) => (
                  <div key={exp.id}>
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="text-lg font-medium">{exp.title}</h3>
                        <p className="text-gray-600">{exp.company}</p>
                      </div>
                      <span className="text-gray-500">{exp.duration}</span>
                    </div>
                    <p className="mt-2 text-gray-700">{exp.description}</p>
                    {exp.skills.length > 0 && (
                      <div className="flex flex-wrap gap-2 mt-2">
                        {exp.skills.map((skill) => (
                          <span
                            key={skill}
                            className="px-2 py-1 bg-gray-100 text-gray-700 rounded text-sm"
                          >
                            {skill}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </section>
          )}

          {/* Education */}
          {data.education.length > 0 && (
            <section>
              <h2 className="text-xl font-semibold mb-4">Education</h2>
              <div className="space-y-4">
                {data.education.map((edu) => (
                  <div key={edu.id} className="flex justify-between">
                    <div>
                      <h3 className="text-lg font-medium">{edu.degree}</h3>
                      <p className="text-gray-600">{edu.institution}</p>
                    </div>
                    <span className="text-gray-500">{edu.duration}</span>
                  </div>
                ))}
              </div>
            </section>
          )}

          {/* Projects */}
          {data.projects.length > 0 && (
            <section>
              <h2 className="text-xl font-semibold mb-4">Projects</h2>
              <div className="space-y-6">
                {data.projects.map((project) => (
                  <div key={project.id}>
                    <div className="flex justify-between items-start">
                      <h3 className="text-lg font-medium">{project.title}</h3>
                      <div className="flex gap-3">
                        {project.githubLink && (
                          <a
                            href={project.githubLink}
                            className="text-gray-600 hover:text-blue-600"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Github className="h-5 w-5" />
                          </a>
                        )}
                        {project.demoLink && (
                          <a
                            href={project.demoLink}
                            className="text-gray-600 hover:text-blue-600"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <ExternalLink className="h-5 w-5" />
                          </a>
                        )}
                      </div>
                    </div>
                    <p className="mt-2 text-gray-700">{project.description}</p>
                    {project.skills.length > 0 && (
                      <div className="flex flex-wrap gap-2 mt-2">
                        {project.skills.map((skill) => (
                          <span
                            key={skill}
                            className="px-2 py-1 bg-gray-100 text-gray-700 rounded text-sm"
                          >
                            {skill}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
}
