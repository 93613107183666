import React, { useEffect, useState } from 'react';
import { ResumeData } from '../../../types/resume';
import Header from '../resume/Header';
import Skills from '../resume/Skills';
import Experience from '../resume/Experience';
import Education from '../resume/Education';
import Projects from '../resume/Projects';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RouteEncoder } from '../../../utils/routeEncoder';

interface Props {
  data: ResumeData;
}

export default function LiveResume() {
const{resumeId} =useParams()
  const [resumeData, setResumeData] = useState<ResumeData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const decodedId = RouteEncoder.decode(resumeId);

  useEffect(() => {
    const fetchResumeData = async () => {
      const token = sessionStorage.getItem("token");

      if (!token) {
        setError("No token found. Please log in.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`https://api.blueblockss.ai:5002/api/resume/user/${decodedId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setResumeData(response.data.resumes[0].resume_data); // Assuming the API returns the resume data in the response body
        console.log(resumeData);
        
        setLoading(false);
      } catch (err) {
        console.error("Error fetching resume data:", err);
        setError("Failed to fetch resume data. Please try again.");
        setLoading(false);
      }
    };

    fetchResumeData();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Add your loading state component here
  }

  if (error) {
    toast.error(error, {
      position: "top-right",
      autoClose: 3000,
    });
    return <div>{error}</div>; // Show error message
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <Header
          name={resumeData?.personalInfo.name || ''}
          title={resumeData?.personalInfo.title || ''}
          location={resumeData?.personalInfo.location || ''}
          email={resumeData?.contactInfo.email || ''}
          phone={resumeData?.contactInfo.phone || ''}
          linkedin={resumeData?.contactInfo.linkedin || ''}
          github={resumeData?.contactInfo.github || ''}
        />
        <div className="p-6 space-y-8">
          <Skills skills={resumeData?.skills || []} />
          <Experience experiences={resumeData?.workExperience || []} />
          <Education education={resumeData?.education || []} />
          <Projects projects={resumeData?.projects || []} />
        </div>
      </div>
    </div>
  );
}
