import React from 'react';
import { deleteSpeaker } from '../../../../lib/api';
import { Trash2 } from 'lucide-react'; // Importing Lucide icons

const SpeakerList = ({ speakers, onUpdate }) => {
  const handleDelete = async (speakerId) => {
    if (window.confirm('Are you sure you want to delete this speaker?')) {
      try {
        await deleteSpeaker(speakerId);
        onUpdate();
      } catch (error) {
        console.error('Error deleting speaker:', error);
      }
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg">
      <div className="px-6 py-4 border-b border-gray-200">
        <h3 className="text-xl font-semibold text-blue-800">Speaker List</h3>
      </div>
      <div className="divide-y divide-gray-200">
        {speakers.map((speaker) => (
          <div
            key={speaker.speaker_id}
            className="flex items-center space-x-4 p-4 hover:bg-gray-50"
          >
            {speaker.image_url ? (
              <img
                src={speaker.image_url}
                alt={speaker.name}
                className="w-16 h-16 rounded-full object-cover"
              />
            ) : (
              <div className="w-16 h-16 flex items-center justify-center rounded-full bg-blue-100 text-blue-500">
                {speaker.name[0].toUpperCase()}
              </div>
            )}
            <div className="flex-1">
              <h4 className="font-medium text-gray-900 text-lg">{speaker.name}</h4>
              {speaker.role && (
                <p className="text-sm text-gray-500">{speaker.role}</p>
              )}
              <p className="mt-2 text-gray-600 text-sm">{speaker.bio}</p>
              {speaker.email && (
                <p className="mt-1 text-sm text-gray-500">{speaker.email}</p>
              )}
            </div>
            <button
              onClick={() => handleDelete(speaker.speaker_id)}
              className="flex items-center justify-center text-red-600 hover:text-red-800"
            >
              <Trash2 size={20} />
            </button>
          </div>
        ))}
        {speakers.length === 0 && (
          <div className="p-6 text-center text-gray-500">
            No speakers available.
          </div>
        )}
      </div>
    </div>
  );
};

export default SpeakerList;
