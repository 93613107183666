import BackButton from "../components/BackButton";
import React, { useState } from "react";

const DiscoveryCallScheduler = () => {
  const [purpose, setPurpose] = useState("Talent Discussion");
  const [dateTime, setDateTime] = useState("");
  const [timeZone, setTimeZone] = useState("GMT");
  const [communicationMethod, setCommunicationMethod] = useState("Video Call");

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Call Scheduled Successfully!\nPurpose: ${purpose}\nDate and Time: ${dateTime}\nTime Zone: ${timeZone}\nCommunication Method: ${communicationMethod}`);
  };

  return (
    <div className="min-h-screen mt-20 text-white">
      {/* Hero Section */}
      <header className="text-center py-12 px-4 bg-blue-600">
        <h1 className="text-4xl font-bold mb-4">Schedule Your Discovery Call</h1>
        <p className="text-lg">Connect with our team to discuss your needs and find the right solutions.</p>
      </header>
      <div className="mt-5 mx-10">
      <BackButton/>
      </div>
      {/* Form Section */}
      <section className="bg-white text-black p-8 rounded-lg shadow-md max-w-3xl mx-auto mt-8">
        <h2 className="text-2xl font-semibold mb-6">Discovery Call Scheduler</h2>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Purpose of Call */}
          <div>
            <label htmlFor="purpose" className="block font-medium mb-2">Purpose of Call</label>
            <select
              id="purpose"
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
              className="w-full p-2 border rounded-lg focus:outline-blue-500"
            >
              <option value="Talent Discussion">Talent Discussion</option>
              <option value="Project Clarification">Project Clarification</option>
              <option value="General Inquiry">General Inquiry</option>
            </select>
          </div>

          {/* Date and Time Selection */}
          <div>
            <label htmlFor="dateTime" className="block font-medium mb-2">Date and Time</label>
            <input
              type="datetime-local"
              id="dateTime"
              value={dateTime}
              onChange={(e) => setDateTime(e.target.value)}
              className="w-full p-2 border rounded-lg focus:outline-blue-500"
              required
            />
          </div>

          {/* Time Zone Selection */}
          <div>
            <label htmlFor="timeZone" className="block font-medium mb-2">Time Zone</label>
            <select
              id="timeZone"
              value={timeZone}
              onChange={(e) => setTimeZone(e.target.value)}
              className="w-full p-2 border rounded-lg focus:outline-blue-500"
            >
              <option value="GMT">GMT</option>
              <option value="PST">PST</option>
              <option value="EST">EST</option>
              <option value="CET">CET</option>
              <option value="IST">IST</option>
            </select>
          </div>

          {/* Preferred Communication Method */}
          <div>
            <p className="block font-medium mb-2">Preferred Communication Method</p>
            <div className="space-y-2">
              <label className="flex items-center">
                <input
                  type="radio"
                  value="Video Call"
                  checked={communicationMethod === "Video Call"}
                  onChange={(e) => setCommunicationMethod(e.target.value)}
                  className="mr-2"
                />
                Video Call (Zoom, Microsoft Teams, Google Meet)
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  value="Phone Call"
                  checked={communicationMethod === "Phone Call"}
                  onChange={(e) => setCommunicationMethod(e.target.value)}
                  className="mr-2"
                />
                Phone Call
              </label>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition"
          >
            Schedule Call
          </button>
        </form>
      </section>
    </div>
  );
};

export default DiscoveryCallScheduler;
