import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Sidebar from "./components/dashboard/Sidebar";
import UserSidebar from "./components/user/UserSidebar";
import Header from "./components/dashboard/Header";
import HomePage from "./pages/index";
import AboutUs from "./pages/AboutUs";
import ITStaffResources from "./pages/ITStaffResources";
import EventPage from "./pages/events";
import EnterprisePage from "./pages/EnterprisePage";
import DataIntegrationPage from "./pages/DataIntegrationPage";
import DataIntegrationETLPage from "./pages/DataIntegrationETLPage";
import Course from "./pages/Course";
import CourseDetails from "./components/course/CourseDetails";
import courses from "./data/courses";
import DataGovernanceSecurityPage from "./pages/DataGovernanceSecurityPage";
import BigDataAnalyticsPage from "./pages/BigDataAnalyticsPage";
import Bi_Tools from "./pages/Bi_Tools";
import Cloud_data_solutions from "./pages/Cloud_data_solutions";
import ML_AI from "./pages/ML_AI";
import RTDP from "./pages/RTDP";
import EmployerDashboard from "./pages/EmployerDashboard";
import RFQManagementPanel from "./pages/RFQManagementPanel";
import DiscoveryCallScheduler from "./pages/DiscoveryCallScheduler";
import ResumeView from "./pages/ResumeView";
import LiveResume from "./components/Resume/preview/LiveResume";
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import UserDashboard from "./pages/UserDashboard";
import Admin_dashboard from "./pages/Admin_dashboard";
import User_dashboard from "./pages/User_dashboard";
import BootcampView from "./components/BootcampView";
import Bootcamps from "./pages/Bootcamps";
import ResumeForm from "./components/Resume/ResumeForm";
import BootcampGrid from "./components/BootcampGrid";
import SkillDevelopment from "./pages/SkillDevelopment";
import BootcampDetail from "./pages/BootcampDetail";
import UserDashboardLayout from "./components/Layout/UserDashboardLayout";
import ResumeList from "./pages/ResumeList";
import LearningJourneyShowcase from "./pages/LearningJourneyShowcase";
import LearningJourneyRoadmap from "./pages/LearningJourneyShowcase";
import UserManagementPage from "./pages/user/UserManagementPage";
import BootcampForm from "./pages/admin/BootcampForm";
import { CreateBootcamp } from "./pages/admin/CreateBootcamp";
import { EditBootcamp } from "./pages/admin/EditBootcamp";
import BootcampList from "./components/bootcamp/form/bootcamp/BootcampList";
import CurriculumManager from "./components/bootcamp/form/curriculum/CurriculumManager";
import FAQManager from "./components/bootcamp/form/faqs/FAQs";
import SpeakerManager from "./components/bootcamp/form/speakers/Speakers";
import BootcampManagement from "./components/bootcamp/form/bootcamp/BootcampManagement";
import BootcampFormNew from "./components/bootcamp/form/bootcamp/BootcampForm";
import BootcampFormTemp from "./pages/admin/BootcampFormTemp";

const App = () => {
  // Mock authentication state
  const isAdmin = true;

  return (
    <Router>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <Admin_dashboard>
              <Dashboard />
            </Admin_dashboard>
          }
        />
        <Route path="/user/dashboard" element={<User_dashboard />} />
        <Route path="/user/create-resume" element={<ResumeForm />} />
        <Route
          path="/dashboard/users"
          element={
            <Admin_dashboard>
              <UserManagementPage />
            </Admin_dashboard>
          }
        />
        <Route
          path="/dashboard/bootcamps"
          element={
            <Admin_dashboard>
              <BootcampFormTemp />
            </Admin_dashboard>
          }
        />
         <Route
          path="/dashboard/edit/bootcamps"
          element={
            <Admin_dashboard>
              <EditBootcamp />
            </Admin_dashboard>
          }
        />

        <Route
          path="/user/bootcamps"
          element={
            <UserDashboardLayout>
              <SkillDevelopment />
            </UserDashboardLayout>
          }
        />
        <Route
          path="/user/resumes"
          element={
            <UserDashboardLayout>
              <ResumeList />
            </UserDashboardLayout>
          }
        />


            <Route path="/bootcamp/live" element={<BootcampList />} />
            <Route path="/bootcamp/create" element={<BootcampFormNew />} />
            <Route path="/bootcamp/edit/:id" element={<BootcampManagement />} />
            <Route path="/bootcamp/:id/curriculum" element={<CurriculumManager />} />
            <Route path="/bootcamp/:id/faqs" element={<FAQManager />} />
            <Route path="/bootcamp/:id/speakers" element={<SpeakerManager />} />

        <Route path="/live-resume" element={<LiveResume />} />
        <Route path="/user/resume/view/:resumeId" element={<LiveResume />} />

        {/* Routes With Layout */}
        <Route
          path="/*"
          element={
            <Layout>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/talent" element={<ITStaffResources />} />
                <Route path="/events" element={<EventPage />} />
                <Route path="/enterprise" element={<EnterprisePage />} />
                <Route path="/temp" element={<LearningJourneyRoadmap />} />

                <Route
                  path="/big-data-architecture-infrastructure-design"
                  element={<DataIntegrationPage />}
                />
                <Route
                  path="/data-integration-etl-processes"
                  element={<DataIntegrationETLPage />}
                />
                <Route
                  path="/data-governance-security"
                  element={<DataGovernanceSecurityPage />}
                />
                <Route
                  path="/big-data-analytics-advanced-analytics"
                  element={<BigDataAnalyticsPage />}
                />
                <Route
                  path="/business-intelligence-visualization"
                  element={<Bi_Tools />}
                />
                <Route path="/registration" element={<Registration />} />
                <Route
                  path="/cloud-data-solutions"
                  element={<Cloud_data_solutions />}
                />
                <Route
                  path="/machine-learning-ai-integration"
                  element={<ML_AI />}
                />
                <Route
                  path="/skill-development"
                  element={<SkillDevelopment />}
                />
                <Route
                  path="/skill-development/:id"
                  element={<BootcampDetail />}
                />
                <Route path="/real-time-data-processing" element={<RTDP />} />
                <Route path="/course" element={<BootcampGrid />} />
                <Route path="/sign-up" element={<EmployerDashboard />} />
                <Route
                  path="/rfq-management"
                  element={<RFQManagementPanel />}
                />
                <Route
                  path="/call-scheduler"
                  element={<DiscoveryCallScheduler />}
                />
                <Route path="/user/bootcamps" element={<SkillDevelopment />} />
                <Route
                  path="/bootcamps/:bootcampId"
                  element={<BootcampView />}
                />

                <Route path="/sign-in" element={<Login />} />

                <Route path="/user-dashboard" element={<UserDashboard />} />
                <Route
                  path="/courses/:link"
                  element={<CourseDetails courses={courses} />}
                />
              </Routes>
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
