import React from 'react';
import { WorkExperience as WorkExperienceType } from '../../../types/resume';

interface Props {
  experiences: WorkExperienceType[];
}

export default function Experience({ experiences }: Props) {
  return (
    <section>
      <h2 className="text-xl font-bold mb-4 text-gray-800">Work Experience</h2>
      <div className="space-y-6">
        {experiences.map((exp) => (
          <div key={exp.id} className="bg-white rounded-lg p-4 hover:shadow-md transition-shadow">
            <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-2">
              <div>
                <h3 className="text-lg font-semibold text-gray-800">{exp.title}</h3>
                <p className="text-blue-600 font-medium">{exp.company}</p>
              </div>
              <span className="text-gray-500 text-sm mt-1 sm:mt-0">{exp.duration}</span>
            </div>
            <p className="text-gray-600 mb-3">{exp.description}</p>
            {exp.skills.length > 0 && (
              <div className="flex flex-wrap gap-2">
                {exp.skills.map((skill) => (
                  <span
                    key={skill}
                    className="px-2 py-1 bg-gray-100 text-gray-700 rounded text-sm"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}