import React from 'react';
import { Button } from '../../ui/button';
import { X } from 'lucide-react';

interface FAQsProps {
  formData: {
    faqs: {
      question: string;
      answer: string;
    }[];
  };
  onChange: (field: string, value: any) => void;
  onNext: () => void;
  onBack: () => void;
}

export function FAQs({ formData, onChange, onNext, onBack }: FAQsProps) {
  const addFAQ = () => {
    onChange('faqs', [...formData.faqs, { question: '', answer: '' }]);
  };

  const removeFAQ = (index: number) => {
    const newFAQs = formData.faqs.filter((_, i) => i !== index);
    onChange('faqs', newFAQs);
  };

  const updateFAQ = (index: number, field: 'question' | 'answer', value: string) => {
    const newFAQs = formData.faqs.map((faq, i) => 
      i === index ? { ...faq, [field]: value } : faq
    );
    onChange('faqs', newFAQs);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-blue-900">FAQs</h2>
      <div className="space-y-6">
        {formData.faqs.map((faq, index) => (
          <div key={index} className="p-4 border rounded-lg space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">FAQ {index + 1}</h3>
              <Button
                variant="outline"
                size="icon"
                onClick={() => removeFAQ(index)}
                className="text-red-500"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Question
              </label>
              <input
                type="text"
                value={faq.question}
                onChange={(e) => updateFAQ(index, 'question', e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                placeholder="Enter question"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Answer
              </label>
              <textarea
                value={faq.answer}
                onChange={(e) => updateFAQ(index, 'answer', e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                rows={4}
                placeholder="Enter answer"
              />
            </div>
          </div>
        ))}
        <Button variant="outline" onClick={addFAQ}>
          Add FAQ
        </Button>
      </div>
      <div className="flex justify-between">
        <Button variant="outline" onClick={onBack}>Back</Button>
        <Button onClick={onNext}>Next</Button>
      </div>
    </div>
  );
}