import React, { useEffect, useState } from 'react';
import { ChevronDown, Calendar, Download } from 'lucide-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

interface Bootcamp {
  title: string;
  description: {
    beginners: string;
  };
  image?: string |null;
  skills: string[];
  curriculum: { title: string; content: string }[];
  faqs: { question: string; answer: string }[];
  batches: { date: string; status: string }[];
}

interface Props {
  bootcampId?: string;
  bootcamp?: Bootcamp;
}

export default function BootcampView({  bootcamp: initialBootcamp }: Props) {
  const { bootcampId } = useParams(); // Get bootcampId from URL

  const [bootcamp, setBootcamp] = useState<Bootcamp | null>(initialBootcamp || null);
  const [loading, setLoading] = useState(!initialBootcamp);
  const [openSection, setOpenSection] = useState<string | null>(null);

  const toggleSection = (section: string) => {
    setOpenSection(openSection === section ? null : section);
  };

  useEffect(() => {
    if (!bootcampId || initialBootcamp) return;

    const fetchBootcamp = async () => {
      try {
        const response = await axios.get(`https://api.blueblockss.ai:5002/api/bootcamp/${bootcampId}`);
        setBootcamp(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching bootcamp:', error);
        toast.error('Failed to fetch bootcamp details. Please try again later.', {
          position: 'top-right',
          autoClose: 3000,
        });
        setLoading(false);
      }
    };

    fetchBootcamp();
  }, [bootcampId, initialBootcamp]);

  if (loading) {
    return <div className="text-center py-12">Loading bootcamp details...</div>;
  }

  if (!bootcamp) {
    return <div className="text-center py-12 text-gray-600">Bootcamp not found.</div>;
  }

  const { title, description, image, skills, curriculum, faqs, batches } = bootcamp;

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-blue-600 text-white">
        <div className="container mx-auto px-4 py-16">
          <div className="flex flex-col lg:flex-row gap-8 items-center">
            <div className="flex-1">
              <div className="inline-block px-3 py-1 bg-blue-500 rounded-full text-sm mb-4">
                SKILL DEVELOPMENT
              </div>
              <h1 className="text-4xl font-bold mb-4">{title}</h1>
              <p className="text-blue-100 mb-6">{description?.beginners}</p>
              <button className="inline-flex items-center px-6 py-3 bg-white text-blue-600 rounded-lg hover:bg-blue-50 transition-colors">
                <Download className="w-5 h-5 mr-2" />
                Download Curriculum
              </button>
            </div>
            {image && (
              <div className="lg:w-1/2">
                <img
                  src={image}
                  alt={title}
                  className="rounded-lg shadow-lg w-full"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-12">
        <div className="grid lg:grid-cols-3 gap-8">
          {/* Main Content */}
          <div className="lg:col-span-2 space-y-8">
            {/* Skills Section */}
            <section className="bg-white rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-bold mb-6">Skills You'll Gain</h2>
              <div className="flex flex-wrap gap-3">
                {skills.map((skill) => (
                  <span
                    key={skill}
                    className="px-4 py-2 bg-blue-100 text-blue-800 rounded-full text-sm font-medium"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </section>

            {/* Curriculum Section */}
            <section className="bg-white rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-bold mb-6">Curriculum</h2>
              <div className="space-y-4">
                {curriculum.map((item, index) => (
                  <div key={index} className="border rounded-lg">
                    <button
                      className="w-full px-6 py-4 flex items-center justify-between text-left"
                      onClick={() => toggleSection(`curriculum-${index}`)}
                    >
                      <span className="font-medium">{item.title}</span>
                      <ChevronDown
                        className={`w-5 h-5 transition-transform ${
                          openSection === `curriculum-${index}` ? 'transform rotate-180' : ''
                        }`}
                      />
                    </button>
                    {openSection === `curriculum-${index}` && (
                      <div className="px-6 py-4 border-t">
                        <div dangerouslySetInnerHTML={{ __html: item.content }} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </section>

            {/* FAQ Section */}
            <section className="bg-white rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-bold mb-6">Frequently Asked Questions</h2>
              <div className="space-y-4">
                {faqs.map((faq, index) => (
                  <div key={index} className="border rounded-lg">
                    <button
                      className="w-full px-6 py-4 flex items-center justify-between text-left"
                      onClick={() => toggleSection(`faq-${index}`)}
                    >
                      <span className="font-medium">{faq.question}</span>
                      <ChevronDown
                        className={`w-5 h-5 transition-transform ${
                          openSection === `faq-${index}` ? 'transform rotate-180' : ''
                        }`}
                      />
                    </button>
                    {openSection === `faq-${index}` && (
                      <div className="px-6 py-4 border-t">
                        <p className="text-gray-600">{faq.answer}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </section>
          </div>

          {/* Sidebar */}
          <div className="lg:col-span-1">
            <div className="bg-white rounded-lg shadow-lg p-6 sticky top-8">
              <h3 className="text-xl font-bold mb-6">Upcoming Batches</h3>
              <div className="space-y-4">
                {batches.map((batch, index) => (
                  <div key={index} className="flex items-center justify-between p-4 border rounded-lg">
                    <div className="flex items-center gap-3">
                      <Calendar className="w-5 h-5 text-blue-600" />
                      <div>
                        <p className="font-medium">{batch.date}</p>
                        <p className={`text-sm ${
                          batch.status === 'Ongoing' ? 'text-green-600' : 'text-red-600'
                        }`}>
                          {batch.status}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button className="w-full mt-6 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
                Apply Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
