import React from "react";

const features = [
  {
    title: "AI and ML Integration",
    description: "Leverage AI and machine learning to drive smarter, automated decisions.",
    link: "/machine-learning-ai-integration"
  },
  {
    title: "Big Data Architecture",
    description: "Build future-proof AI based data systems that scale with your business.",
    link: "/big-data-architecture-infrastructure-design"
  },
  {
    title: "Business Intelligence (BI)",
    description: "Turn complex data into clear, actionable visual insights.",
    link: "/business-intelligence-visualization"
  },
  {
    title: "Cloud Data Solutions",
    description: "Scale your data effortlessly with secure, cloud-powered solutions.",
    link: "/cloud-data-solutions"
  },
  {
    title: "Data Integration & ETL",
    description: "Seamlessly connect and transform your data for maximum impact.",
    link: "/data-integration-etl-processes"
  },
  {
    title: "Data Governance & Security",
    description: "Protect your data with robust security and governance frameworks.",
    link: "/data-governance-security"
  },
  {
    title: "Big Data Analytics",
    description: "Unlock actionable insights with powerful, data-driven analytics.",
    link: "/big-data-analytics-advanced-analytics"
  },
  {
    title: "Real-Time Data Processing",
    description: "Make data-driven decisions in the moment with real-time processing.",
    link: "/real-time-data-processing"
  }
];


const FeatureCards = () => {
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="border border-blue-500 rounded-lg p-6 text-center hover:shadow-lg transition duration-300 group"
            >
              <h3 className="text-lg font-bold text-blue-600 mb-4 group-hover:text-blue-700 transition duration-300">
                {feature.title}
              </h3>
              <p className="text-gray-700 mb-6 group-hover:text-gray-900 transition duration-300">
                {feature.description}
              </p>
              <a
                href={feature.link}
                className="bg-blue-500 text-white py-2 px-4 rounded-full text-sm font-semibold hover:bg-blue-600 transition duration-300"
              >
                Tech Stack →
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};





export default FeatureCards;
