import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  LayoutDashboard,
  Users,
  GraduationCap,
  Calendar,
  Settings,
  BarChart2,
  BookOpen,
  LogOut,
} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const navigation = [
  { name: 'Dashboard', icon: LayoutDashboard, href: '/dashboard' },
  { name: 'Users', icon: Users, href: '/dashboard/users' },
  { name: 'Bootcamps', icon: BookOpen, href: '/dashboard/bootcamps' },
  { name: 'Students', icon: GraduationCap, href: '/dashboard/students' },
  { name: 'Events', icon: Calendar, href: '/dashboard/events' },
  { name: 'Analytics', icon: BarChart2, href: '/dashboard/analytics' },
  { name: 'Settings', icon: Settings, href: '/dashboard/settings' },
];

export default function Sidebar() {
  const { logout } = useAuth();
  const handleLogout = () => {
    logout(); // Call the logout function
    window.location.href = "/"; // Redirect to login page
  };
  return (
    <div className="flex flex-col h-full bg-white border border-slate-800/20 w-64">
      {/* Logo */}
      <div className="px-6 py-8 border-b border-slate-800/20">
        <h1 className="text-2xl font-bold text-blue-600">Admin Portal</h1>
      </div>

      {/* Navigation */}
      <nav className="flex-1 px-4 py-4 space-y-1">
        {navigation.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            className={({ isActive }) =>
              `flex items-center px-4 py-3 text-sm font-medium rounded-lg transition-colors    ${
                isActive
                  ? 'bg-blue-50 text-blue-600'
                  : 'text-gray-600 hover:bg-gray-50 hover:hover:text-blue-600'
              }`
            }
          >
            <item.icon className="w-5 h-5 mr-3" />
            {item.name}
          </NavLink>
        ))}
      </nav>

      {/* Logout */}
      <div className="p-4 border-t border-slate-800/20">
        <button       onClick={handleLogout}
 className="flex items-center w-full px-4 py-3 text-sm font-medium text-gray-300 rounded-lg hover:bg-gray-800 hover:text-black transition-colors">
          <LogOut className="w-5 h-5 mr-3" />
          Logout
        </button>
      </div>
    </div>
  );
}