import React from 'react';

interface AttendanceData {
  mode: string;
  contactConsent: boolean;
  dataConsent: boolean;
}

interface Props {
  data: AttendanceData;
  onChange: (data: AttendanceData) => void;
}

export default function AttendanceConsent({ data, onChange }: Props) {
  const handleChange = (field: keyof AttendanceData, value: string | boolean) => {
    onChange({
      ...data,
      [field]: value,
    });
  };

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-xl font-semibold text-gray-900">Preferred Mode of Attendance</h2>
        <div className="mt-4 space-y-4">
          <div className="flex items-center">
            <input
              type="radio"
              id="online"
              name="mode"
              value="online"
              defaultChecked
              checked={data.mode === 'online'}
              onChange={(e) => handleChange('mode', e.target.value)}
              className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label htmlFor="online" className="ml-3 block text-sm font-medium text-gray-700">
              Online
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="inPerson"
              name="mode"
              value="inPerson"
              checked={data.mode === 'inPerson'}
              onChange={(e) => handleChange('mode', e.target.value)}
              className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label htmlFor="inPerson" className="ml-3 block text-sm font-medium text-gray-700">
              In-Person
            </label>
          </div>
        </div>
      </div>

      <div>
        <h2 className="text-xl font-semibold text-gray-900">Consent for Contact and Data Usage</h2>
        <div className="mt-4 space-y-4">
          <div className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                type="checkbox"
                checked={data.contactConsent}
                onChange={(e) => handleChange('contactConsent', e.target.checked)}
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label className="font-medium text-gray-700">
                Do you give permission to contact you via email/phone regarding this event and future opportunities?
              </label>
            </div>
          </div>

          <div className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                type="checkbox"
                checked={data.dataConsent}
                onChange={(e) => handleChange('dataConsent', e.target.checked)}
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label className="font-medium text-gray-700">
                Do you consent to having your data stored and used for future event invitations and related purposes?
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}