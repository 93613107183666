import React from 'react';
import { Calendar, Clock } from 'lucide-react';

interface Props {
  name: string;
  nextClass: {
    title: string;
    time: string;
    date: string;
  };
}

export default function WelcomeCard({ name, nextClass }: Props) {
  return (
    <div className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-lg shadow-lg p-6 text-white">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
        <div>
          <h1 className="text-3xl font-bold mb-2">Welcome back, {name}! 👋</h1>
          <p className="text-blue-100">Ready to continue your learning journey?</p>
        </div>
        <div className="mt-4 md:mt-0 bg-white/10 rounded-lg p-4">
          <h3 className="font-semibold mb-2">Next Class</h3>
          <p className="text-lg font-medium">{nextClass.title}</p>
          <div className="flex items-center gap-4 mt-2 text-sm">
            <div className="flex items-center">
              <Calendar className="w-4 h-4 mr-1" />
              {nextClass.date}
            </div>
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-1" />
              {nextClass.time}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}