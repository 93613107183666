import React, { createContext, useReducer } from 'react';

const BootcampContext = createContext();

const initialState = {
    basicInfo: null,
    speakers: [],
    faqs: [],
    curriculums: [],
    isLoading: false,
    error: null
};

function bootcampReducer(state, action) {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, isLoading: true };
        case 'SET_BASIC_INFO':
            return { ...state, basicInfo: action.payload, isLoading: false };
        case 'SET_SPEAKERS':
            return { ...state, speakers: action.payload, isLoading: false };
        case 'SET_FAQS':
            return { ...state, faqs: action.payload, isLoading: false };
        case 'SET_CURRICULUMS':
            return { ...state, curriculums: action.payload, isLoading: false };
        case 'SET_ERROR':
            return { ...state, error: action.payload, isLoading: false };
        default:
            return state;
    }
}

const BootcampProvider = ({ children }) => {
    const [state, dispatch] = useReducer(bootcampReducer, initialState);

    const apiCall = async (url, options = {}, onSuccess, onError) => {
        dispatch({ type: 'SET_LOADING' });
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if (response.ok) {
                onSuccess(data);
            } else {
                throw new Error(data.message || "Something went wrong");
            }
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
        }
    };

    const fetchBootcampByIdBasic = (id) => {
        apiCall(`/api/basic/${id}`, {}, 
            data => dispatch({ type: 'SET_BASIC_INFO', payload: data }),
            error => dispatch({ type: 'SET_ERROR', payload: error })
        );
    };

    const updateBootcamp = (id, bootcampData) => {
        apiCall(`/api/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bootcampData)
        },
        data => dispatch({ type: 'SET_BASIC_INFO', payload: data }),
        error => dispatch({ type: 'SET_ERROR', payload: error })
        );
    };

    // const deleteBootcamp = (id) => {
    //     apiCall(`/api/${id}`, { method: 'DELETE' },
    //         () => fetchAllBootcamps(),  // Refresh the list or handle appropriately
    //         error => dispatch({ type: 'SET_ERROR', payload: error })
    //     );
    // };

    const fetchAllSpeakers = () => {
        apiCall(`/api/speakers/all`, {}, 
            data => dispatch({ type: 'SET_SPEAKERS', payload: data }),
            error => dispatch({ type: 'SET_ERROR', payload: error })
        );
    };

    const fetchAllCurriculums = () => {
        apiCall(`/api/curriculum/all`, {}, 
            data => dispatch({ type: 'SET_CURRICULUMS', payload: data }),
            error => dispatch({ type: 'SET_ERROR', payload: error })
        );
    };

    const fetchAllFaqs = () => {
        apiCall(`/api/faqs/all`, {}, 
            data => dispatch({ type: 'SET_FAQS', payload: data }),
            error => dispatch({ type: 'SET_ERROR', payload: error })
        );
    };

   // Create Speaker
const createSpeaker = (bootcampId, speakerData) => {
    apiCall(`/api/speakers/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ bootcampId, ...speakerData })
    },
    data => fetchAllSpeakers(), // Refresh the list of speakers after creation
    error => dispatch({ type: 'SET_ERROR', payload: error })
    );
};

// Update Speaker
const updateSpeaker = (bootcampId, id, speakerData) => {
    apiCall(`/api/speakers/update/${bootcampId}/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(speakerData)
    },
    data => fetchAllSpeakers(), // Refresh the list of speakers after update
    error => dispatch({ type: 'SET_ERROR', payload: error })
    );
};

// Delete Speaker
const deleteSpeaker = (bootcampId, id) => {
    apiCall(`/api/speakers/delete/${bootcampId}/${id}`, { method: 'DELETE' },
    () => fetchAllSpeakers(), // Refresh the list of speakers after deletion
    error => dispatch({ type: 'SET_ERROR', payload: error })
    );
};

// Create Curriculum
const createCurriculum = (bootcampId, curriculumData) => {
    apiCall(`/api/curriculum`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ bootcampId, ...curriculumData })
    },
    data => fetchAllCurriculums(), // Refresh the list of curriculums after creation
    error => dispatch({ type: 'SET_ERROR', payload: error })
    );
};

// Update Curriculum
const updateCurriculum = (bootcampId, id, curriculumData) => {
    apiCall(`/api/curriculum/${bootcampId}/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(curriculumData)
    },
    data => fetchAllCurriculums(), // Refresh the list of curriculums after update
    error => dispatch({ type: 'SET_ERROR', payload: error })
    );
};

// Delete Curriculum
const deleteCurriculum = (bootcampId, id) => {
    apiCall(`/api/curriculum/${bootcampId}/${id}`, { method: 'DELETE' },
    () => fetchAllCurriculums(), // Refresh the list of curriculums after deletion
    error => dispatch({ type: 'SET_ERROR', payload: error })
    );
};

// Create FAQ
const createFaq = (bootcampId, faqData) => {
    apiCall(`/api/faqs`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ bootcampId, ...faqData })
    },
    data => fetchAllFaqs(), // Refresh the list of FAQs after creation
    error => dispatch({ type: 'SET_ERROR', payload: error })
    );
};

// Update FAQ
const updateFaq = (bootcampId, id, faqData) => {
    apiCall(`/api/faqs/${bootcampId}/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(faqData)
    },
    data => fetchAllFaqs(), // Refresh the list of FAQs after update
    error => dispatch({ type: 'SET_ERROR', payload: error })
    );
};

// Delete FAQ
const deleteFaq = (bootcampId, id) => {
    apiCall(`/api/faqs/${bootcampId}/${id}`, { method: 'DELETE' },
    () => fetchAllFaqs(), // Refresh the list of FAQs after deletion
    error => dispatch({ type: 'SET_ERROR', payload: error })
    );
};

    // Expose the state and all functions as context value
const value = {
    state,
    fetchBootcampByIdBasic,
    updateBootcamp,
    // deleteBootcamp,
    fetchAllSpeakers,
    createSpeaker,
    updateSpeaker,
    deleteSpeaker,
    fetchAllCurriculums,
    createCurriculum,
    updateCurriculum,
    deleteCurriculum,
    fetchAllFaqs,
    createFaq,
    updateFaq,
    deleteFaq
};

return (
    <BootcampContext.Provider value={value}>
        {children}
    </BootcampContext.Provider>
);

};

export { BootcampContext, BootcampProvider };
