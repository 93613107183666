import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext"; // Example: If using context
import reportWebVitals from "./reportWebVitals";
import { BootcampContext } from "./contexts/BootcampContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
    <BootcampContext>

      <App />
      </BootcampContext>

    </AuthProvider>
  </React.StrictMode>
);

// Performance measurement (optional)
reportWebVitals();
