import Education from '../components/Resume/Education';
import Experience from '../components/Resume/Experience';
import Header from '../components/Resume/Header';
import Projects from '../components/Resume/Projects';
import Skills from '../components/Resume/Skills';
import React from 'react';

function ResumeView() {
  return (
    <div className="min-h-screen bg-white">
      <Header />
      <main>
        <Skills />
        <Experience />
        <Education />
        <Projects />
      </main>
    </div>
  );
}

export default ResumeView;