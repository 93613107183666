// src/utils/routeEncoder.ts
export class RouteEncoder {
    // Encode a number to URL-safe base64
    static encode(id: number): string {
      try {
        // Convert number to string and encode
        const encoded = btoa(id.toString());
        
        // Make URL-safe by replacing special characters
        return encoded
          .replace(/\+/g, '-')   // Replace + with -
          .replace(/\//g, '_')   // Replace / with _
          .replace(/=/g, '');    // Remove padding
      } catch (error) {
        console.error('Encoding error', error);
        return '';
      }
    }
  
    // Decode URL-safe base64 back to number
    static decode(encodedId?: string | null): number | null {
      try {
        // Handle null or undefined
        if (!encodedId) return null;
  
        // Restore base64 padding and replace URL-safe characters
        const base64 = encodedId
          .replace(/-/g, '+')    // Restore + 
          .replace(/_/g, '/')    // Restore /
          .padEnd(
            Math.ceil(encodedId.length / 4) * 4, 
            '='
          );
  
        // Decode and parse
        const decoded = atob(base64);
        const parsedId = parseInt(decoded, 10);
  
        // Validate parsed number
        return Number.isNaN(parsedId) ? null : parsedId;
      } catch (error) {
        console.error('Decoding error', { encodedId, error });
        return null;
      }
    }
  
    // Advanced: Encode with optional salt for extra security
    static secureEncode(id: number, salt: string = 'default'): string {
      try {
        // Combine id with salt before encoding
        const saltedValue = `${salt}:${id}`;
        const encoded = btoa(saltedValue);
        
        return encoded
          .replace(/\+/g, '-')
          .replace(/\//g, '_')
          .replace(/=/g, '');
      } catch (error) {
        console.error('Secure encoding error', error);
        return '';
      }
    }
  
    // Corresponding secure decode
    static secureDecode(encodedId?: string | null, salt: string = 'default'): number | null {
      try {
        if (!encodedId) return null;
  
        const base64 = encodedId
          .replace(/-/g, '+')
          .replace(/_/g, '/')
          .padEnd(Math.ceil(encodedId.length / 4) * 4, '=');
  
        const decoded = atob(base64);
        
        // Validate salt
        const [decodedSalt, decodedId] = decoded.split(':');
        
        if (decodedSalt !== salt) {
          console.error('Salt mismatch');
          return null;
        }
  
        const parsedId = parseInt(decodedId, 10);
        return Number.isNaN(parsedId) ? null : parsedId;
      } catch (error) {
        console.error('Secure decoding error', { encodedId, error });
        return null;
      }
    }
  }
  
  // Comprehensive Type-Safe Encoder/Decoder
  export interface EncodingResult<T> {
    success: boolean;
    value: T | null;
    error?: string;
  }
  
  export class AdvancedRouteEncoder {
    // Type-safe encoding with detailed result
    static encode<T extends number>(value: T): EncodingResult<T> {
      try {
        const encoded = RouteEncoder.encode(value);
        return {
          success: !!encoded,
          value: encoded ? value : null,
          error: !encoded ? 'Encoding failed' : undefined
        };
      } catch (error) {
        return {
          success: false,
          value: null,
          error: error instanceof Error ? error.message : 'Unknown error'
        };
      }
    }
  
    // Type-safe decoding with detailed result
    static decode<T extends number>(encodedValue?: string | null): EncodingResult<T> {
      try {
        const decoded = RouteEncoder.decode(encodedValue);
        return {
          success: decoded !== null,
          value: decoded as T,
          error: decoded === null ? 'Decoding failed' : undefined
        };
      } catch (error) {
        return {
          success: false,
          value: null,
          error: error instanceof Error ? error.message : 'Unknown error'
        };
      }
    }
  }