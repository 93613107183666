import React from 'react';
import StatsCards from '../components/dashboard/StatsCards';
import RevenueChart from '../components/dashboard/RevenueChart';
import RecentActivity from '../components/dashboard/RecentActivity';
import { DashboardStats, ChartData, ActivityLog } from '../types/dashboard';

// Mock data
const stats: DashboardStats = {
  totalUsers: 1234,
  activeUsers: 892,
  totalBootcamps: 15,
  totalRevenue: 125000,
  recentRegistrations: 45,
  completionRate: 87,
};

const chartData: ChartData[] = [
  { name: 'Jan', students: 400, revenue: 24000 },
  { name: 'Feb', students: 500, revenue: 35000 },
  { name: 'Mar', students: 600, revenue: 42000 },
  { name: 'Apr', students: 780, revenue: 58000 },
  { name: 'May', students: 850, revenue: 62000 },
  { name: 'Jun', students: 920, revenue: 78000 },
];

const activities: ActivityLog[] = [
  {
    id: '1',
    user: 'Sarah Johnson',
    action: 'enrolled in Cybersecurity Bootcamp',
    timestamp: '2 hours ago',
    details: 'Batch starting December 2024',
  },
  {
    id: '2',
    user: 'Mike Chen',
    action: 'completed AI/ML Module 3',
    timestamp: '4 hours ago',
    details: 'Scored 95% in the assessment',
  },
  {
    id: '3',
    user: 'Emily Davis',
    action: 'submitted a project',
    timestamp: '6 hours ago',
    details: 'Full Stack Development - Final Project',
  },
];

export default function Dashboard() {
  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Dashboard Overview</h1>
        <div className="flex space-x-3">
          <select className="border border-gray-300 rounded-lg px-4 py-2 bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option>Last 7 days</option>
            <option>Last 30 days</option>
            <option>Last 3 months</option>
            <option>Last year</option>
          </select>
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
            Download Report
          </button>
        </div>
      </div>

      {/* Stats Cards */}
      <StatsCards stats={stats} />

      {/* Charts and Activity */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <RevenueChart data={chartData} />
        </div>
        <div>
          <RecentActivity activities={activities} />
        </div>
      </div>
    </div>
  );
}