import React from 'react';
import { Button } from '../../ui/button';
import { X } from 'lucide-react';

interface SpeakersProps {
  formData: {
    speakers: {
      id: string;
      name: string;
      role: string;
      bio: string;
      email?: string;
      imageUrl?: string;
    }[];
  };
  onChange: (field: string, value: any) => void;
  onNext: () => void;
  onBack: () => void;
}

export function Speakers({ formData, onChange, onNext, onBack }: SpeakersProps) {
  const addSpeaker = () => {
    const newSpeaker = {
      id: Date.now().toString(),
      name: '',
      role: '',
      bio: '',
      email: '',
      imageUrl: ''
    };
    onChange('speakers', [...formData.speakers, newSpeaker]);
  };

  const removeSpeaker = (id: string) => {
    const newSpeakers = formData.speakers.filter(speaker => speaker.id !== id);
    onChange('speakers', newSpeakers);
  };

  const updateSpeaker = (id: string, field: string, value: string) => {
    const newSpeakers = formData.speakers.map(speaker => 
      speaker.id === id ? { ...speaker, [field]: value } : speaker
    );
    onChange('speakers', newSpeakers);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-blue-900">Speakers</h2>
      <div className="space-y-6">
        {formData.speakers.map((speaker) => (
          <div key={speaker.id} className="p-4 border rounded-lg space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">Speaker</h3>
              <Button
                variant="outline"
                size="icon"
                onClick={() => removeSpeaker(speaker.id)}
                className="text-red-500"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  value={speaker.name}
                  onChange={(e) => updateSpeaker(speaker.id, 'name', e.target.value)}
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  placeholder="Speaker name"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Role
                </label>
                <input
                  type="text"
                  value={speaker.role}
                  onChange={(e) => updateSpeaker(speaker.id, 'role', e.target.value)}
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  placeholder="Speaker role"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={speaker.email}
                  onChange={(e) => updateSpeaker(speaker.id, 'email', e.target.value)}
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  placeholder="Speaker email"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Image URL
                </label>
                <input
                  type="text"
                  value={speaker.imageUrl}
                  onChange={(e) => updateSpeaker(speaker.id, 'imageUrl', e.target.value)}
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  placeholder="Speaker image URL"
                />
              </div>
              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Bio
                </label>
                <textarea
                  value={speaker.bio}
                  onChange={(e) => updateSpeaker(speaker.id, 'bio', e.target.value)}
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  rows={4}
                  placeholder="Speaker bio"
                />
              </div>
            </div>
          </div>
        ))}
        <Button variant="outline" onClick={addSpeaker}>
          Add Speaker
        </Button>
      </div>
      <div className="flex justify-between">
        <Button variant="outline" onClick={onBack}>Back</Button>
        <Button onClick={onNext}>Submit</Button>
      </div>
    </div>
  );
}