// components/bootcamp/BootcampForm.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createBootcamp, updateBootcamp, fetchBootcampById } from '../../../../lib/api';

const BootcampFormNew = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    duration: '',
    class_time: '',
    description: {
      beginners: '',
      graduates: '',
      professionals: '',
    },
    skills: [],
  });

  useEffect(() => {
    if (id) {
      loadBootcamp();
    }
  }, [id]);

  const loadBootcamp = async () => {
    try {
      const data = await fetchBootcampById(id);
      setFormData(data);
    } catch (error) {
      console.error('Error loading bootcamp:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await updateBootcamp(id, formData);
      } else {
        await createBootcamp(formData);
      }
      navigate('/');
    } catch (error) {
      console.error('Error saving bootcamp:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDescriptionChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      description: {
        ...prev.description,
        [name]: value,
      },
    }));
  };

  return (
    <div className="max-w-2xl mx-auto bg-white rounded-lg shadow p-6">
      <h2 className="text-xl font-semibold mb-6">
        {id ? 'Edit Bootcamp' : 'Create Bootcamp'}
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Duration</label>
            <input
              type="text"
              name="duration"
              value={formData.duration}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Class Time</label>
            <input
              type="text"
              name="class_time"
              value={formData.class_time}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description for Beginners
            </label>
            <textarea
              name="beginners"
              value={formData.description.beginners}
              onChange={handleDescriptionChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              rows="3"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description for Graduates
            </label>
            <textarea
              name="graduates"
              value={formData.description.graduates}
              onChange={handleDescriptionChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              rows="3"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description for Professionals
            </label>
            <textarea
              name="professionals"
              value={formData.description.professionals}
              onChange={handleDescriptionChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              rows="3"
            />
          </div>
        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            {id ? 'Update Bootcamp' : 'Create Bootcamp'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BootcampFormNew;