import React from 'react';

interface EventData {
  bigDataAI: string[];
  coding: string[];
  itManagement: string[];
}

interface Props {
  data: EventData;
  onChange: (data: EventData) => void;
}

const events = {
  bigDataAI: [
    'AI and ML Integration',
    'Big Data Architecture',
    'Big Data Analytics',
    'Real-Time Data Processing',
    'Machine Learning Algorithms',
    'Deep Learning for AI',
    'Natural Language Processing (NLP)',
    'AI in Business Applications',
  ],
  coding: [
    'Business Intelligence (BI)',
    'Data Integration & ETL',
    'Cloud Data Solutions',
    'Full Stack Development',
    'Data Structures and Algorithms',
    'Python for Data Science',
    'JavaScript and Front-End Development',
    'Cloud Computing with AWS',
  ],
  itManagement: [
    'Data Governance & Security',
    'Cloud Security Management',
    'IT Strategy and Digital Transformation',
    'IT Project Management',
    'Cybersecurity for IT Managers',
    'Business Continuity Planning',
    'IT Infrastructure Management',
    'IT Risk Management',
  ],
};

export default function EventSelection({ data, onChange }: Props) {
  const handleChange = (category: keyof EventData, event: string) => {
    const currentEvents = data[category];
    const updatedEvents = currentEvents.includes(event)
      ? currentEvents.filter((e) => e !== event)
      : [...currentEvents, event];

    onChange({
      ...data,
      [category]: updatedEvents,
    });
  };

  const EventCategory = ({ title, category, items }: { title: string; category: keyof EventData; items: string[] }) => (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900">{title}</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {items.map((event) => (
          <div key={event} className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                type="checkbox"
                checked={data[category].includes(event)}
                onChange={() => handleChange(category, event)}
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label className="font-medium text-gray-700">{event}</label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-xl font-semibold text-gray-900">Event Details</h2>
        <p className="mt-1 text-sm text-gray-500">
          Which event(s) would you like to attend? (Select one or more)
        </p>
      </div>

      <EventCategory title="Big Data AI/ML" category="bigDataAI" items={events.bigDataAI} />
      <EventCategory title="Coding" category="coding" items={events.coding} />
      <EventCategory title="IT Management" category="itManagement" items={events.itManagement} />
    </div>
  );
}