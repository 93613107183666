import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  AcademicCapIcon, 
  CodeBracketIcon, 
  StarIcon,
  ClockIcon
} from '@heroicons/react/24/solid';
import axios from 'axios';
import LeetCodeComponent from '../components/leetcode/LeetCodeComponent';

// Interfaces
interface LearningMilestone {
  id: number;
  date: string;
  type: 'certification' | 'project' | 'bootcamp' | 'course';
  title: string;
  description: string;
  skills: string[];
  link?: string;
  icon?: React.ReactNode;
}

interface ContributionData {
    date: string;
    count: number;
    level: number;
  }
  
interface ContributionTotal {
    [year: string]: number;
  }
const LearningJourneyRoadmap: React.FC = () => {
  // Fake Data Generation
  const generateMilestones = (): LearningMilestone[] => [
    {
      id: 1,
      date: 'January 2022',
      type: 'course',
      title: 'Web Development Fundamentals',
      description: 'Started learning HTML, CSS, and JavaScript basics',
      skills: ['HTML', 'CSS', 'JavaScript'],
      icon: <CodeBracketIcon className="h-6 w-6 text-blue-500" />
    },
    {
      id: 2,
      date: 'April 2022',
      type: 'bootcamp',
      title: 'Intensive React Bootcamp',
      description: 'Completed intensive training in modern React development',
      skills: ['React', 'State Management', 'Hooks'],
      link: '/certificates/react-bootcamp',
      icon: <AcademicCapIcon className="h-6 w-6 text-green-500" />
    },
    {
      id: 3,
      date: 'August 2022',
      type: 'project',
      title: 'E-Commerce Platform',
      description: 'Built a full-stack e-commerce application with modern tech stack',
      skills: ['React', 'Node.js', 'MongoDB', 'GraphQL'],
      link: 'https://github.com/username/ecommerce-project',
      icon: <StarIcon className="h-6 w-6 text-purple-500" />
    },
    {
      id: 4,
      date: 'December 2022',
      type: 'certification',
      title: 'AWS Certified Developer',
      description: 'Obtained AWS Developer Associate Certification',
      skills: ['Cloud Computing', 'AWS', 'Serverless'],
      link: '/certificates/aws-developer',
      icon: <ClockIcon className="h-6 w-6 text-red-500" />
    }
  ];

  // State Management
  const [milestones] = useState<LearningMilestone[]>(generateMilestones());
  const [contributions, setContributions] = useState<ContributionData[]>([]);
  const [total, setTotal] = useState<ContributionTotal>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch Contributions
  useEffect(() => {
    const fetchContributions = async () => {
      try {
        setIsLoading(true);
        // Replace with your actual API endpoint
        const response = await axios.get('https://github-contributions-api.jogruber.de/v4/grubersjoe?y=2024');
        
        setContributions(response.data.contributions);
        setTotal(response.data.total);
      } catch (err) {
        setError('Failed to fetch contributions');
        console.error(err);
        
        // Fallback to generated data if API fails
        const generatedContributions = generateContributionData();
        setContributions(generatedContributions);
      } finally {
        setIsLoading(false);
      }
    };

    fetchContributions();
  }, []);

  // Fallback Contribution Data Generation
  const generateContributionData = (): ContributionData[] => {
    const contributions: ContributionData[] = [];
    const currentDate = new Date();

    // Generate contribution data for the last 365 days
    for (let i = 0; i < 365; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - i);

      contributions.push({
        date: date.toISOString().split('T')[0],
        count: Math.floor(Math.random() * 10),
        level: Math.floor(Math.random() * 5)
      });
    }

    return contributions;
  };

  // Contribution Graph Component
  const ContributionGraph = () => {
    const getContributionColor = (level: number) => {
      const colors = [
        'bg-gray-100',
        'bg-green-100',
        'bg-green-300',
        'bg-green-500',
        'bg-green-700'
      ];
      return colors[level];
    };

    // Group contributions by week
    const groupedContributions = contributions.reduce((acc, contrib, index) => {
      const weekIndex = Math.floor(index / 7);
      if (!acc[weekIndex]) {
        acc[weekIndex] = [];
      }
      acc[weekIndex].push(contrib);
      return acc;
    }, {} as Record<number, ContributionData[]>);

    if (isLoading) {
      return (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-blue-500"></div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      );
    }

    return (
      <div className="bg-white rounded-xl shadow-lg hover:shadow-blue-600/50 p-6 ">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">
            Github Contributions
          </h2>
          <div className="text-sm text-gray-600">
            Total Contributions: {Object.values(total).reduce((a, b) => a + b, 0)}
          </div>
        </div>
        
        <div className="flex overflow-x-auto space-x-2">
          {Object.values(groupedContributions).map((week, weekIndex) => (
            <div key={weekIndex} className="flex flex-col space-y-1">
              {week.map((contribution, dayIndex) => (
                <motion.div
                  key={contribution.date}
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ 
                    delay: weekIndex * 0.1 + dayIndex * 0.05,
                    duration: 0.3 
                  }}
                  className={`
                    w-4 h-4 rounded 
                    ${getContributionColor(contribution.level)}
                    hover:scale-125 transition-transform
                    cursor-pointer
                  `}
                  title={`Date: ${contribution.date}
                    Contributions: ${contribution.count}
                    Level: ${contribution.level}
                  `}
                />
              ))}
            </div>
          ))}
        </div>
        
        <div className="mt-4 flex justify-between text-xs text-gray-500">
          <span>Less</span>
          <div className="flex space-x-1">
            {[0, 1, 2, 3, 4].map(level => (
              <div 
                key={level} 
                className={`w-4 h-4 rounded ${getContributionColor(level)}`} 
              />
            ))}
          </div>
          <span>More</span>
        </div>

        {/* Yearly Contribution Breakdown */}
        <div className="mt-6 border-t pt-4">
          <h3 className="text-lg font-semibold text-gray-800 mb-3">
            Yearly Contributions
          </h3>
          <div className="grid grid-cols-3 md:grid-cols-5 gap-2">
            {Object.entries(total)
              .sort(([yearA], [yearB]) => parseInt(yearA) - parseInt(yearB))
              .map(([year, count]) => (
                <div 
                  key={year} 
                  className="bg-gray-100 rounded p-2 text-center"
                >
                  <div className="font-bold text-blue-600">{year}</div>
                  <div className="text-sm text-gray-600">{count} contributions</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  // Learning Insights Component
  const LearningInsights = () => (
    <div className="bg-white rounded-xl shadow-lg hover:shadow-blue-600/50 p-6">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">
        Learning Insights
      </h2>
      <div className="space-y-4">
        {[
          { label: 'Total Projects', value: '12', color: 'blue' },
          { label: 'Certifications', value: '5', color: 'green' },
          { label: 'Learning Hours', value: '500+', color: 'purple' },
          { label: 'Skills Learned', value: '20+', color: 'red' }
        ].map(({ label, value, color }) => (
          <div 
            key={label} 
            className="flex justify-between items-center border-b pb-2 last:border-b-0"
          >
            <span className="text-gray-600">{label}</span>
            <span className={`font-bold text-${color}-600`}>{value}</span>
          </div>
        ))}
      </div>
    </div>
  );

  // Timeline Rendering
  const renderTimeline = () => {
    const groupedMilestones = milestones.reduce((acc, milestone) => {
      const year = milestone.date.split(' ')[1];
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(milestone);
      return acc;
    }, {} as Record<string, LearningMilestone[]>);

    return (
      <div className="bg-white rounded-xl shadow-lg hover:shadow-blue-600/50 p-6 mt-8">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Learning Timeline
        </h2>
        <div className="relative border-l-4 border-blue-200 pl-4">
          {Object.entries(groupedMilestones).map(([year, yearMilestones]) => (
            <div key={year} className="mb-8">
              <h3 className="text-2xl font-bold text-gray-800 mb-6 
                sticky top-0 bg-white z-10 py-2">
                {year}
              </h3>
              {yearMilestones.map(milestone => (
                <motion.div 
                  key={milestone.id}
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5 }}
                  className="flex items-start mb-8 group relative"
                >
                  <div className="absolute w-4 h-4 bg-blue-500 rounded-full -left-2 mt-2 
                    group-hover:bg-blue-600 transition-all transform group-hover:scale-110">
                  </div>
                  <div className="ml-6 p-4 bg-gray-50 rounded-xl shadow-md w-full 
                    border-l-4 border-transparent group-hover:border-blue-500 transition-all">
                    <div className="flex items-center mb-2">
                      {milestone.icon}
                      <h4 className="ml-3 font-bold text-xl text-gray-800">
                        {milestone.title}
                      </h4>
                      <span 
                        
                          className="text-xs ml-3 px-2 py-1 rounded-full 
                          bg-green-100 text-green-800"
                        >{milestone.type}</span>
                    </div>
                    <p className="text-gray-600 mb-3">{milestone.description}
                    
                    </p>
                    <div className="flex flex-wrap gap-2 mb-3">
                      {milestone.skills.map(skill => (
                        <span 
                          key={skill} 
                          className="text-xs px-2 py-1 rounded-full 
                          bg-blue-100 text-blue-800"
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
<div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
  <div className="max-w-7xl mx-auto">
    {/* Page Header */}
    <motion.div 
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      className="text-center mb-12"
    >
      <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
        My Learning Journey
      </h1>
      <p className="mt-4 text-xl text-gray-600">
        A comprehensive view of my skills, achievements, and contributions
      </p>
    </motion.div>

    {/* Top Section */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
      <div className="md:col-span-2 space-y-8">
        <ContributionGraph />
        <LeetCodeComponent />
      </div>
      
      {/* Learning Insights - Full width on small screens */}
      <div className="md:col-span-1">
        <LearningInsights />
      </div>
    </div>

    {/* Timeline */}
    <div className="mt-8">
      {renderTimeline()}
    </div>
  </div>
</div>
  );
};

export default LearningJourneyRoadmap;