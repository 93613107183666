import React from "react";

const ContactUs = () => {
  return (
    <footer className="bg-gradient-to-r  from-blue-500 to-blue-800 text-white py-12">
      <div className="container mx-auto px-4">
        {/* Main Content */}
        {/* Main Content */}
        <div className="flex flex-col lg:flex-row justify-between gap-8">
          {/* Contact Form */}
          <div className="lg:w-1/2 w-full">
            <h2 className="text-2xl font-bold mb-4">Hey, Contact Us</h2>
            <p className="mb-6">
              Learn how Blueblockss can help you modernize your legacy apps
              quickly.
            </p>
            <form>
              <div className="grid grid-cols-1 gap-4">
                <input
                  type="text"
                  placeholder="Your Name"
                  className="w-full p-3 rounded-md text-black"
                />
                <input
                  type="email"
                  placeholder="Email"
                  className="w-full p-3 rounded-md text-black"
                />
                <input
                  type="text"
                  placeholder="Phone"
                  className="w-full p-3 rounded-md text-black"
                />
                <textarea
                  rows="4"
                  placeholder="Message"
                  className="w-full p-3 rounded-md text-black"
                ></textarea>
                <button
                  type="submit"
                  className="w-full py-3 border-2 border-white text-white rounded-md hover:bg-white hover:text-blue-500 transition duration-300"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>

          {/* Company Information */}
          <div className="lg:w-1/2 w-full flex flex-wrap gap-y-6">
            <div className="w-1/2">
              <h3 className="text-lg font-bold mb-2">Company</h3>
              <ul>
                <li>About Us</li>
                <li>Services</li>
                <li>Legal</li>
                <li>Privacy Policy</li>
                <li>Developers</li>
                <li>Press</li>
              </ul>
            </div>
            <div className="w-1/2">
              <h3 className="text-lg font-bold mb-2">Find Us</h3>
              <ul>
                <li>GTA Toronto, ON, CA</li>
                <li>CA</li>
              </ul>
              <h3 className="text-lg font-bold mt-4">Social</h3>
              <ul>
                <li>Facebook</li>
                <li>LinkedIn</li>
                <li>YouTube</li>
                <li>Twitter</li>
              </ul>
            </div>
            <div className="w-1/2">
              <h3 className="text-lg font-bold mb-2">Links</h3>
              <ul>
                <li>Software Development</li>
                <li>App Design</li>
                <li>Custom Software Development</li>
                <li>Software Developing Company</li>
                <li>Bespoke Software Developer</li>
                <li>Mobile App Development</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="border-t border-white mt-8 pt-4 flex flex-col lg:flex-row justify-between text-center lg:text-left">
          <p>© Copyright Blueblockss. All Rights Reserved</p>
          <div className="flex justify-center lg:justify-end gap-4 mt-4 lg:mt-0">
            <a href="#" className="hover:underline">
              Home
            </a>
            <a href="#" className="hover:underline">
              Blog
            </a>
            <a href="#" className="hover:underline">
              Terms and Conditions
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default ContactUs;
