// src/pages/ResumeList.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Pencil, Eye, Loader2, AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { RouteEncoder } from '../utils/routeEncoder';

const ResumeList = () => {
  const [resumes, setResumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchResumes();
  }, []);

  // Add this debug log
  console.log('Resumes:', resumes);

  const fetchResumes = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.get('https://api.blueblockss.ai:5002/api/resume/user', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setResumes(response.data.resumes || []);
    } catch (err) {
      setError('Failed to load resumes');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Safe getter functions
  const getSkillsArray = (resume) => {
    try {
      const skills = resume?.resume_data?.skills;
      return Array.isArray(skills) ? skills : [];
    } catch (error) {
      console.error('Error parsing skills:', error);
      return [];
    }
  };

  const getPersonalInfo = (resume) => {
    try {
      return resume?.resume_data?.personalInfo || {
        name: 'N/A',
        title: 'N/A',
        location: 'N/A'
      };
    } catch (error) {
      console.error('Error getting personal info:', error);
      return {
        name: 'N/A',
        title: 'N/A',
        location: 'N/A'
      };
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="w-8 h-8 animate-spin text-blue-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">My Resumes</h1>
        <p className="text-gray-600 mt-2">Manage your created resumes</p>
      </div>

      {/* Desktop Table */}
      <div className="hidden md:block overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg overflow-hidden shadow-md">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Title
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Location
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Skills
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {resumes.map((resume, index) => {
              const personalInfo = getPersonalInfo(resume);
              const skills = getSkillsArray(resume);

              return (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {personalInfo.name}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">
                      {personalInfo.title}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">
                      {personalInfo.location}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex flex-wrap gap-2">
                      {skills.length > 0 ? (
                        <>
                          {skills.slice(0, 3).map((skill, idx) => (
                            <span
                              key={idx}
                              className="px-2 py-1 text-xs font-medium bg-blue-100 text-blue-800 rounded-full"
                            >
                              {skill}
                            </span>
                          ))}
                          {skills.length > 3 && (
                            <span className="text-xs text-gray-500">
                              +{skills.length - 3} more
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="text-xs text-gray-500">No skills listed</span>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <div className="flex space-x-3">
                      <Link
                        to={`/user/resume/view/${RouteEncoder.encode(index)}`}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        <Eye className="w-5 h-5" />
                      </Link>
                      <Link
                        to={`/user/resume/edit/${index}`}
                        className="text-green-600 hover:text-green-900"
                      >
                        <Pencil className="w-5 h-5" />
                      </Link>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Mobile Cards */}
      <div className="md:hidden space-y-4">
        {resumes.map((resume, index) => {
          const personalInfo = getPersonalInfo(resume);
          const skills = getSkillsArray(resume);

          return (
            <div key={index} className="bg-white rounded-lg shadow-md p-4">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    {personalInfo.name}
                  </h3>
                  <p className="text-sm text-gray-500">
                    {personalInfo.title}
                  </p>
                </div>
                <div className="flex space-x-2">
                  <Link
                    to={`/resume/view/${index}`}
                    className="p-2 text-blue-600 hover:text-blue-900"
                  >
                    <Eye className="w-5 h-5" />
                  </Link>
                  <Link
                    to={`/resume/edit/${index}`}
                    className="p-2 text-green-600 hover:text-green-900"
                  >
                    <Pencil className="w-5 h-5" />
                  </Link>
                </div>
              </div>
              <div className="text-sm text-gray-500 mb-2">
                {personalInfo.location}
              </div>
              <div className="flex flex-wrap gap-2">
                {skills.length > 0 ? (
                  <>
                    {skills.slice(0, 3).map((skill, idx) => (
                      <span
                        key={idx}
                        className="px-2 py-1 text-xs font-medium bg-blue-100 text-blue-800 rounded-full"
                      >
                        {skill}
                      </span>
                    ))}
                    {skills.length > 3 && (
                      <span className="text-xs text-gray-500">
                        +{skills.length - 3} more
                      </span>
                    )}
                  </>
                ) : (
                  <span className="text-xs text-gray-500">No skills listed</span>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {(!resumes || resumes.length === 0) && (
        <div className="text-center py-12">
          <p className="text-gray-500">No resumes found</p>
          <Link
            to="/resume/create"
            className="mt-4 inline-block px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Create Resume
          </Link>
        </div>
      )}
    </div>
  );
};

export default ResumeList;