import React from "react";
import { FaShieldAlt, FaDatabase, FaKey, FaGavel } from "react-icons/fa";

const HeroSection = () => (
  <div className="bg-gradient-to-r mt-20 from-blue-500 to-blue-800 text-white py-16 px-6 text-center">
    <h1 className="text-4xl md:text-6xl font-bold mb-4">Data Governance & Security</h1>
    <p className="text-lg md:text-xl mb-6">
      Empower your organization with robust security tools and data governance frameworks.
    </p>
    <a
      href="#contact"
      className="bg-white text-blue-500 font-semibold py-3 px-6 rounded-full shadow-md hover:bg-blue-100 transition duration-300"
    >
      Learn More
    </a>
  </div>
);

const ToolsSection = () => {
    const tools = [
      {
        category: "Security Tools",
        icon: <FaShieldAlt />,
        description:
          "Ensure secure access management and robust policy enforcement using tools like Apache Ranger, AWS IAM, and Azure Active Directory.",
      },
      {
        category: "Data Governance",
        icon: <FaDatabase />,
        description:
          "Centralize, manage, and enforce high-quality governance frameworks with tools such as Collibra, Alation, and Informatica Data Governance.",
      },
      {
        category: "Encryption & Data Masking",
        icon: <FaKey />,
        description:
          "Protect sensitive data using encryption and masking solutions like HashiCorp Vault, Microsoft Azure Key Vault, and AWS KMS.",
      },
      {
        category: "Compliance",
        icon: <FaGavel />,
        description:
          "Adhere to global standards such as GDPR, HIPAA, SOC 2, and PCI DSS to ensure compliance and organizational trust.",
      },
    ];
  
    return (
      <section className="py-12 bg-gray-50">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-8">Key Tools and Compliance</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {tools.map((tool, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md p-6 text-center hover:shadow-lg transition duration-300"
              >
                <div className="text-4xl text-blue-500 mb-4">{tool.icon}</div>
                <h3 className="text-xl font-bold mb-2 text-blue-500">
                  {tool.category}
                </h3>
                <p className="text-gray-600">{tool.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  

const CallToAction = () => (
  <div className="bg-blue-500 text-white text-center py-12">
    <h2 className="text-2xl md:text-4xl font-bold mb-4">
      Ready to Strengthen Your Data Security?
    </h2>
    <a
      href="#contact"
      className="bg-white text-blue-500 font-semibold py-3 px-6 rounded-full shadow-md hover:bg-blue-100 transition duration-300"
    >
      Contact Us Today
    </a>
  </div>
);

const DataGovernanceSecurityPage = () => (
  <div>
    <HeroSection />
    <ToolsSection />
    <div class="flex justify-center items-center h-auto py-10 bg-gray-50">
  <div class="bg-white rounded-lg shadow-md p-6">
    <img
      src="/DataGovernanceSecurity/napkin-selection (11).svg"
      alt="Descriptive Title"
      class="w-full h-auto object-contain rounded-lg"
    />
  </div>
</div>

{/* <div class="grid grid-cols-2 gap-6 justify-center items-center h-auto py-10 bg-gray-50 p-6">
  <div class="bg-white rounded-lg shadow-md p-6  h-[600px]">
    <img
      src="/DataGovernanceSecurity/napkin-selection (6).svg"
      alt="Descriptive Title 1"
      class="w-auto h-full object-contain rounded-lg"
    />
  </div>
  <div class="bg-white rounded-lg shadow-md p-6 h-[600px]">
    <img
      src="/DataGovernanceSecurity/napkin-selection (7).svg"
      alt="Descriptive Title 2"
      class="w-auto h-full object-contain rounded-lg"
    />
  </div>
</div>

<div class="grid grid-cols-2 gap-6 justify-center items-center h-auto py-10 bg-gray-50 p-6">
  <div class="bg-white rounded-lg shadow-md p-6 h-[600px]">
    <img
      src="/DataGovernanceSecurity/napkin-selection (8).svg"
      alt="Descriptive Title 1"
      class="w-auto h-full object-contain rounded-lg"
    />
  </div>
  <div class="bg-white rounded-lg shadow-md p-6 flex justify-center items-center h-[600px]">
    <img
      src="/DataGovernanceSecurity/napkin-selection (9).svg"
      alt="Descriptive Title 2"
      class="w-auto h-full object-contain rounded-lg"
    />
  </div>
</div> */}

<div class="py-10 bg-gray-50">
  {/* <!-- Section 1 --> */}
  <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-6">
    <div class="bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/DataGovernanceSecurity/napkin-selection (6).svg"
        alt="Benefits of Data Governance"
        class="w-auto h-64 object-contain rounded-lg"
      />
    </div>
    <div>
      <h2 class="text-2xl font-bold mb-4">Exploring Security Tools</h2>
      <p class="text-gray-700 mb-2">
      Security tools like Apache Ranger, AWS IAM, and Azure Active Directory strengthen access control. They enforce policies to protect data from unauthorized access and manage identity securely.
      </p>
    </div>
  </div>

  {/* <!-- Section 2 --> */}
  <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-6">
    <div>
      <h2 class="text-2xl font-bold mb-4">Exploring Security Tools</h2>
      <p class="text-gray-700 mb-2">
      Collibra: Centralized data catalog simplifying data organization and discovery, fostering collaboration and ensuring consistency and accessibility.
Alation: Empowers data democratization with intuitive self-service exploration, enabling teams to easily access and leverage data for insights.
Informatica Data Governance: Enforces robust frameworks to ensure data quality, accuracy, consistency, and compliance with regulatory standards.      </p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/DataGovernanceSecurity/napkin-selection (7).svg"
        alt="Data Governance Tools"
        class="w-auto h-64 object-contain rounded-lg"
      />
    </div>
  </div>

  {/* <!-- Section 3 --> */}
  <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-6">
    <div class="bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/DataGovernanceSecurity/napkin-selection (8).svg"
        alt="Data Security Tools"
        class="w-auto h-64 object-contain rounded-lg"
      />
    </div>
    <div>
      <h2 class="text-2xl font-bold mb-4">Data Security Tools</h2>
      <p class="text-gray-700 mb-2">
        Tools like AWS KMS, HashiCorp Vault, and Azure Key Vault offer cutting-edge encryption techniques. They ensure secure data masking and robust key management for sensitive information.
      </p>
    </div>
  </div>

  {/* <!-- Section 4 --> */}
  <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-6">
    <div>
      <h2 class="text-2xl font-bold mb-4">Data Security Compliance</h2>
      <p class="text-gray-700 mb-2">
        Compliance standards like GDPR, HIPAA, and PCI DSS help organizations adhere to global regulations. They protect user data and enhance trustworthiness across industries.
      </p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex justify-center">
      <img
        src="/DataGovernanceSecurity/napkin-selection (9).svg"
        alt="Data Security Compliance"
        class="w-auto h-64 object-contain rounded-lg"
      />
    </div>
  </div>
</div>

    <CallToAction />
  </div>
);

export default DataGovernanceSecurityPage;
