import React, { useRef, useState } from 'react';
import { X, Upload } from 'lucide-react'; // Import icons
import { toast } from 'react-toastify';

interface PersonalInfo {
  name: string;
  title: string;
  location: string;
  aboutMe: string;
  profileImage: string;
  profileImageFile?: File | null;
}

interface Props {
  data: PersonalInfo;
  onChange: (data: PersonalInfo) => void;
}

export default function PersonalInfoForm({ data, onChange }: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    onChange({ ...data, [name]: value });
  };

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
  
    if (file.size > 5 * 1024 * 1024) {
      toast.error('Image size should be less than 5MB');
      return;
    }
  
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result as string;
      setPreviewImage(result);
      onChange({
        ...data,
        profileImage: result,
        profileImageFile: file
      });
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = () => {
    setPreviewImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    onChange({
      ...data,
      profileImage: '',
      profileImageFile: null
    });
  };
  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold text-gray-800">Personal Information</h2>
      
      {/* Image Upload Section */}
      <div className="flex items-center space-x-6">
        <div className="relative w-32 h-32">
        {(previewImage || data.profileImage) ? (
        <div className="relative group">
          <img
            src={previewImage || data.profileImage || ''}
            alt="Profile"
            className="w-full h-full object-cover rounded-full border-4 border-white shadow-lg"
          />
          <button
            type="button"
            onClick={handleRemoveImage}
            className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full 
                     hover:bg-red-600 transition-colors duration-200 shadow-sm"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
          ) : (
            <div className="w-full h-full bg-gray-100 rounded-full flex items-center justify-center border-4 border-white shadow-lg">
              <Upload className="w-8 h-8 text-gray-400" />
            </div>
          )}
        </div>
        
        <div className="space-y-2">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImageSelect}
            accept="image/*"
            className="hidden"
          />
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 
                     transition-colors duration-200 flex items-center space-x-2"
          >
            <Upload className="w-4 h-4" />
            <span>Upload Photo</span>
          </button>
          <p className="text-sm text-gray-500">
            Recommended: Square image, max 5MB
          </p>
        </div>
      </div>

      {/* Form Fields */}
      <div className="grid gap-6 sm:grid-cols-2">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Full Name *
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={data.name}
            required
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                     focus:border-blue-500 focus:ring-blue-500"
            placeholder="John Doe"
          />
        </div>

        <div>
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
            Professional Title *
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={data.title}
            required
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                     focus:border-blue-500 focus:ring-blue-500"
            placeholder="Full Stack Developer"
          />
        </div>

        <div className="sm:col-span-2">
          <label htmlFor="location" className="block text-sm font-medium text-gray-700">
            Location *
          </label>
          <input
            type="text"
            id="location"
            name="location"
            value={data.location}
            required
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                     focus:border-blue-500 focus:ring-blue-500"
            placeholder="City, Country"
          />
        </div>

        <div className="sm:col-span-2">
          <label htmlFor="aboutMe" className="block text-sm font-medium text-gray-700">
            About Me
          </label>
          <textarea
            id="aboutMe"
            name="aboutMe"
            value={data.aboutMe}
            onChange={handleChange}
            rows={4}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                     focus:border-blue-500 focus:ring-blue-500"
            placeholder="Write a brief introduction about yourself..."
          />
          <p className="mt-1 text-sm text-gray-500">
            Brief introduction about your professional background and career objectives
          </p>
        </div>
      </div>
    </div>
  );
}