// src/pages/SkillDevelopment.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BootcampGrid from '../components/bootcamp/BootcampGrid';
import { Loader2 } from 'lucide-react'; // For loading spinner

const SkillDevelopment = () => {
  const [bootcamps, setBootcamps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBootcamps();
  }, []);

  const fetchBootcamps = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://api.blueblockss.ai:5002/api/bootcamp/');
      setBootcamps(response.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching bootcamps:', err);
      setError('Failed to load bootcamps. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <Loader2 className="w-10 h-10 animate-spin text-blue-600 mx-auto" />
          <p className="mt-4 text-gray-600">Loading bootcamps...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="text-red-600 text-xl mb-4">⚠️</div>
          <p className="text-gray-800">{error}</p>
          <button
            onClick={fetchBootcamps}
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 
                     transition-colors duration-200"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-20 mx-auto py-12 px-4">
      <div className="text-center max-w-3xl mx-auto mb-16">
        <h1 className="relative">
          <span className="text-4xl md:text-5xl font-extrabold 
                         bg-gradient-to-r from-blue-600 to-purple-600 
                         text-transparent bg-clip-text
                         leading-tight tracking-tight
                         mb-4 block">
            Our Featured Skill Program
          </span>
          {/* Decorative underline */}
          <span className="block h-1.5 w-40 bg-gradient-to-r from-blue-600 to-purple-600 
                         mx-auto mt-4 rounded-full"></span>
        </h1>
        
        <p className="mt-8 text-lg md:text-xl text-gray-600 leading-relaxed 
                    font-normal max-w-2xl mx-auto
                    tracking-wide">
          Develop new skills by enrolling in these featured programs and 
          <span className="text-blue-600 font-medium"> uplift your career</span>. 
          Our programs come with complete 
          <span className="text-purple-600 font-medium"> hands-on experience</span>.
        </p>
      </div>

      {/* Category tags */}
      <div className="flex flex-wrap justify-center gap-4 mb-12">
        <span className="px-4 py-2 bg-blue-50 text-blue-600 rounded-full text-sm font-medium">
          Professional Development
        </span>
        <span className="px-4 py-2 bg-purple-50 text-purple-600 rounded-full text-sm font-medium">
          Hands-on Learning
        </span>
        <span className="px-4 py-2 bg-gray-50 text-gray-600 rounded-full text-sm font-medium">
          Career Growth
        </span>
      </div>

      {bootcamps.length > 0 ? (
        <BootcampGrid bootcamps={bootcamps} />
      ) : (
        <div className="text-center text-gray-600 py-12">
          No bootcamps available at the moment.
        </div>
      )}
    </div>
  );
};

export default SkillDevelopment;