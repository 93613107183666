import React, { useState } from 'react';
import { createSpeaker } from '../../../../lib/api';
import { UploadCloud, Trash } from 'lucide-react'; // Importing Lucide icons

const SpeakerForm = ({ bootcampId, onSuccess }) => {
  const [formData, setFormData] = useState({
    name: '',
    bio: '',
    role: '',
    email: '',
    image_url: '',
  });
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const dataToSubmit = {
      ...formData,
      bootcamp_id: parseInt(bootcampId),
    };

    const formDataPayload = new FormData();
    if (imageFile) {
      formDataPayload.append('image', imageFile);
    }
    formDataPayload.append('data', JSON.stringify(dataToSubmit));

    try {
      await createSpeaker(formDataPayload); // API handles FormData
      resetForm();
      onSuccess();
    } catch (err) {
      setError('Failed to create speaker. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      name: '',
      bio: '',
      role: '',
      email: '',
      image_url: '',
    });
    setImageFile(null);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setFormData({ ...formData, image_url: URL.createObjectURL(file) });
    }
  };

  const handleImageRemove = () => {
    setImageFile(null);
    setFormData({ ...formData, image_url: '' });
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h3 className="text-2xl font-semibold text-blue-800 mb-4">Add New Speaker</h3>
      {error && (
        <div className="mb-4 text-red-600 bg-red-50 p-3 rounded">{error}</div>
      )}
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Name Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Enter speaker name"
            required
          />
        </div>
        {/* Bio Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Bio <span className="text-red-500">*</span>
          </label>
          <textarea
            value={formData.bio}
            onChange={(e) => setFormData({ ...formData, bio: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Write a brief bio"
            rows="3"
            required
          />
        </div>
        {/* Role Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Role</label>
          <input
            type="text"
            value={formData.role}
            onChange={(e) => setFormData({ ...formData, role: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Enter speaker role"
          />
        </div>
        {/* Email Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Enter email address"
          />
        </div>
        {/* Image Upload */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Upload Image
          </label>
          <div className="mt-2 flex items-center space-x-4">
            <label
              htmlFor="image-upload"
              className="cursor-pointer inline-flex items-center justify-center w-12 h-12 rounded-full bg-blue-50 text-blue-600 hover:bg-blue-100"
            >
              <UploadCloud size={24} />
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="hidden"
              />
            </label>
            {formData.image_url && (
              <div className="relative">
                <img
                  src={formData.image_url}
                  alt="Preview"
                  className="h-20 w-20 rounded-md object-cover"
                />
                <button
                  type="button"
                  onClick={handleImageRemove}
                  className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                >
                  <Trash size={16} />
                </button>
              </div>
            )}
          </div>
        </div>
        {/* Submit Button */}
        <button
          type="submit"
          disabled={loading}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 disabled:bg-blue-300"
        >
          {loading ? 'Adding...' : 'Add Speaker'}
        </button>
      </form>
    </div>
  );
};

export default SpeakerForm;
