// components/faq/FAQList.jsx
import React from 'react';
import { deleteFaq } from '../../../../lib/api';

const FAQList = ({ bootcampId,faqs, onUpdate }) => {
  const handleDelete = async (faqId) => {
    if (window.confirm('Are you sure you want to delete this FAQ?')) {
      try {
        await deleteFaq(bootcampId,faqId);
        onUpdate();
      } catch (error) {
        console.error('Error deleting FAQ:', error);
      }
    }
  };

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium">FAQ List</h3>
      </div>
      <div className="border-t border-gray-200">
        {faqs.map((faq) => (
          <div
            key={faq.faq_id}
            className="px-4 py-5 sm:px-6 border-b border-gray-200 last:border-b-0"
          >
            <div className="flex justify-between items-start">
              <div>
                <h4 className="font-medium text-gray-900">{faq.question}</h4>
                <p className="mt-2 text-gray-600">{faq.answer}</p>
              </div>
              <button
                onClick={() => handleDelete(faq.faq_id)}
                className="text-red-600 hover:text-red-900"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
        {faqs.length === 0 && (
          <div className="px-4 py-5 text-center text-gray-500">
            No FAQs available
          </div>
        )}
      </div>
    </div>
  );
};

export default FAQList;