import React from 'react'
import Courses from '../components/course/Courses'

function Course() {
  return (
    <div className="mt-10">
      <div className="mx-auto px-4 sm:px-6 py-12">
    <Courses/>
    </div>
    </div>
  )
}

export default Course