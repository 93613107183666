import React from "react";
import { FaRoute, FaDatabase, FaSync, FaLifeRing } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";


const features = [
  {
    icon: <FaRoute size={40} className="text-blue-600" />,
    title: "Data Strategy & Roadmap Development",
    description:
      "Develop a data strategy that drives at least a 20% increase in operational efficiency and aligns with your long-term business goals.",
  },
  {
    icon: <FaDatabase size={40} className="text-blue-600" />,
    title: "Data Migration & Legacy Database Migration",
    description:
      "Migrate up to 99% of your legacy data with minimal downtime and ensure seamless integration for faster decision-making.",
  },
  {
    icon: <FaSync size={40} className="text-blue-600" />,
    title: "Decommissioning & Application Modernizations",
    description:
      "Reduce maintenance costs by at least 30% and improve system performance with efficient decommissioning and modernization.",
  },
  {
    icon: <BiSupport  size={40} className="text-blue-600" />,
    title: "Ongoing Support",
    description:
      "Achieve 99.9% system uptime with proactive support and 24/7 monitoring to ensure uninterrupted data operations.",
  },
];

const FeaturesGridWithImage = () => {
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto flex flex-col lg:flex-row items-center space-x-1 justify-between">
        {/* Left Side Image */}
        <div className="lg:w-1/2 w-full mb-8 mr-3 lg:mb-0">
          <img
            src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Analytics"
            className="rounded-lg shadow-lg"
          />
        </div>

        {/* Right Side Grid */}
        <div className="lg:w-1/2 w-full grid grid-cols-1 sm:grid-cols-2 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center p-6 border border-gray-200 rounded-lg hover:shadow-lg transition duration-300"
            >
              {/* Icon */}
              <div className="mb-4">{feature.icon}</div>
              {/* Title */}
              <h3 className="text-lg font-bold text-gray-800 mb-2">
                {feature.title}
              </h3>
              {/* Description */}
              <p className="text-sm text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesGridWithImage;
