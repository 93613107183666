import React, { useState, useEffect, useContext } from 'react';
import { BasicInfo } from '../../components/bootcamp/BootcampForm/BasicInfo';
import { Description } from '../../components/bootcamp/BootcampForm/Description';
import { SkillsAndCriteria } from '../../components/bootcamp/BootcampForm/SkillsAndCriteria';
import { Modules } from '../../components/bootcamp/BootcampForm/Modules';
import { FAQs } from '../../components/bootcamp/BootcampForm/FAQs';
import { Speakers } from '../../components/bootcamp/BootcampForm/Speakers';
import type { BootcampFormData } from '../../types/bootcampForm';
import { BootcampContext } from '../../contexts/BootcampContext'; // Path to your context

// Mock data for testing edit functionality
// const mockBootcampData: BootcampFormData = {
//   title: 'Full Stack Development Bootcamp',
//   duration: '12 weeks',
//   timing: 'Mon-Fri 9AM-5PM',
//   description: {
//     beginners: 'Perfect for those starting their coding journey...',
//     graduates: 'Enhance your computer science degree with practical skills...',
//     professionals: 'Transition to a new career in tech...',
//   },
//   skills: [
//     { value: 'JavaScript' },
//     { value: 'React' },
//     { value: 'Node.js' },
//   ],
//   challenges: 'Build real-world projects...',
//   completionCriteria: 'Complete all assignments and final project...',
//   modules: [
//     {
//       curriculum_id: '1',
//       title: 'Web Fundamentals',
//       content: 'HTML, CSS, JavaScript basics...',
//     },
//     {
//       curriculum_id: '2',
//       title: 'React Fundamentals',
//       content: 'Components, state, props...',
//     },
//   ],
//   faqs: [
//     {
//       question: 'Do I need prior experience?',
//       answer: 'No prior experience needed...',
//     },
//   ],
//   speakers: [
//     {
//       id: '1',
//       name: 'John Doe',
//       role: 'Senior Developer',
//       bio: 'Over 10 years of experience...',
//       email: 'john@example.com',
//       imageUrl: 'https://example.com/john.jpg',
//     },
//   ],
// };

export function EditBootcamp() {
  const [step, setStep] = useState(1);
  const { state, fetchBootcampByIdBasic, updateBootcamp } = useContext(BootcampContext);
  const [formData, setFormData] = useState<BootcampFormData | undefined>();
  const bootcampId=1

  useEffect(() => {
    fetchBootcampByIdBasic(bootcampId);
  }, [bootcampId, fetchBootcampByIdBasic]);

  useEffect(() => {
    if (state.basicInfo) {
      setFormData(state.basicInfo);
    }
  }, [state.basicInfo]);

  const handleChange = (field: string, value: any) => {
    setFormData((prev) => {
      // If prev is undefined, initialize it properly
      const safePrev: BootcampFormData = prev ?? {
        title: '',
        duration: '',
        timing: '',
        description: {
          beginners: '',
          graduates: '',
          professionals: '',
        },
        skills: [],
        challenges: '',
        completionCriteria: '',
        modules: [],
        faqs: [],
        speakers: []
      };
  
      const newData = { ...safePrev };
      if (field.includes('.')) {
        const [parent, child] = field.split('.');
        (newData as any)[parent][child] = value;
      } else {
        (newData as any)[field] = value;
      }
      return newData;
    });
  };
  
  const handleSubmit = () => {
    updateBootcamp(bootcampId, formData); // Assuming updateBootcamp accepts bootcamp ID and formData
    console.log('Form updated:', formData);
  };

  const renderStep = () => {
    if (!formData) return <p>Loading...</p>; // Handle undefined formData

    switch (step) {
      case 1:
        return (
          <BasicInfo
            formData={formData}
            onChange={handleChange}
            onNext={() => setStep(2)}
          />
        );
      case 2:
        return (
          <Description
            formData={formData}
            onChange={handleChange}
            onNext={() => setStep(3)}
            onBack={() => setStep(1)}
          />
        );
      case 3:
        return (
          <SkillsAndCriteria
            formData={formData}
            onChange={handleChange}
            onNext={() => setStep(4)}
            onBack={() => setStep(2)}
          />
        );
      case 4:
        return (
          <Modules
            formData={formData}
            onChange={handleChange}
            onNext={() => setStep(5)}
            onBack={() => setStep(3)}
          />
        );
      case 5:
        return (
          <FAQs
            formData={formData}
            onChange={handleChange}
            onNext={() => setStep(6)}
            onBack={() => setStep(4)}
          />
        );
      case 6:
        return (
          <Speakers
            formData={formData}
            onChange={handleChange}
            onNext={handleSubmit}
            onBack={() => setStep(5)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto py-8 px-4">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-blue-900 mb-8">Edit Bootcamp</h1>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          {renderStep()}
        </div>
      </div>
    </div>
  );
}