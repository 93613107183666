// components/faq/FAQManager.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FAQForm from './FAQForm';
import FAQList from './FAQList';
import Loading from '../common/Loading';
import Error from '../common/Error';
import { fetchAllFaqs, fetchFaqById } from '../../../../lib/api';

const FAQManager = () => {
  const { id: bootcampId } = useParams();
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadFaqs();
  }, [bootcampId]);

  const loadFaqs = async () => {
    try {
      setLoading(true);
      const data = await fetchFaqById(bootcampId);
      setFaqs(data.filter(faq => faq.bootcamp_id === parseInt(bootcampId)));
      setError(null);
    } catch (err) {
      setError('Failed to load FAQs. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;
  if (error) return <Error message={error} />;

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">FAQ Management</h2>
      <FAQForm bootcampId={bootcampId} onSuccess={loadFaqs} />
      <FAQList bootcampId={bootcampId} faqs={faqs} onUpdate={loadFaqs} />
    </div>
  );
};

export default FAQManager;