import React from "react";
import { FaTools, FaDatabase, FaStream, FaLink } from "react-icons/fa";
import { motion } from "framer-motion";
import { ETLAnimatedWorkflow } from "../components/DataIntegrationPage/ETLAnimatedWorkflow";
import GridImagesComponent from "../components/DataIntegrationPage/GridImagesComponent";
const HeroSection = () => (
  <motion.div
    className="mt-20 bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20 px-6 text-center"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1 }}
  >
    <h1 className="text-4xl md:text-6xl font-bold mb-4">
      Data Integration & ETL Processes
    </h1>
    <p className="text-lg md:text-xl mb-6">
      Build seamless and efficient ETL pipelines for real-time and batch
      processing.
    </p>
    <a
      href="#contact"
      className="bg-white text-blue-500 font-semibold py-3 px-6 rounded-full shadow-md hover:bg-blue-100 transition duration-300"
    >
      Get Started Today
    </a>
  </motion.div>
);

const ETLToolsSection = () => {
  const tools = [
    {
      title: "ETL Tools",
      description: "Apache NiFi, Talend, Informatica, Microsoft SSIS",
      icon: <FaTools />,
    },
    {
      title: "Data Pipelines",
      description: "Apache Kafka, AWS Kinesis, Google Pub/Sub",
      icon: <FaStream />,
    },
    {
      title: "Batch & Real-Time Processing",
      description: "Apache Spark, Apache Flink, Apache Storm",
      icon: <FaDatabase />,
    },
    {
      title: "Data Integration",
      description: "MuleSoft, Apache Camel, Apache Airflow",
      icon: <FaLink />,
    },
  ];

  return (
    <section className="py-12 bg-gray-50">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">
          ETL Tools and Technologies
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {tools.map((tool, index) => (
            <motion.div
              key={index}
              className="border rounded-lg p-6 text-center hover:bg-blue-500 hover:text-white transition duration-300"
              whileHover={{ scale: 1.05 }}
            >
              <div className="text-4xl mb-4">{tool.icon}</div>
              <h3 className="text-xl font-bold mb-2">{tool.title}</h3>
              <p>{tool.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const WorkflowSteps = () => {
  const steps = [
    {
      title: "Data Collection",
      description: "Gather data from multiple sources.",
    },
    {
      title: "Transformation",
      description: "Convert data into usable formats.",
    },
    { title: "Data Load", description: "Load data into storage systems." },
    { title: "Monitoring", description: "Ensure ETL jobs run smoothly." },
  ];

  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">ETL Workflow</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              className="text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <div className="bg-blue-500 text-white w-16 h-16 mx-auto rounded-lg flex items-center justify-center mb-4 font-bold text-lg">
                {index + 1}
              </div>
              <h3 className="text-lg font-bold">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const CallToAction = () => (
  <div className="bg-blue-500 text-white text-center py-12">
    <h2 className="text-2xl md:text-4xl font-bold mb-4">
      Ready to Build Your ETL Processes?
    </h2>
    <a
      href="#contact"
      className="bg-white text-blue-500 font-semibold py-3 px-6 rounded-full shadow-md hover:bg-blue-100 transition duration-300"
    >
      Contact Us Today
    </a>
  </div>
);

const DataIntegrationETLPage = () => (
  <div>
    <HeroSection />
    <ETLToolsSection />
    <div class="flex justify-center items-center h-auto py-10 bg-gray-50">
  <div class="bg-white rounded-lg shadow-md p-6">
    <img
      src="/ETL/napkin-selection.svg"
      alt="Descriptive Title"
      class="w-full h-auto object-contain rounded-lg"
    />
  </div>
</div>

<div class="grid grid-cols-2 gap-6 justify-center items-center h-auto py-10 bg-gray-50 p-6">
  <div class="bg-white rounded-lg shadow-md p-6  h-[600px]">
    <img
      src="/ETL/napkin-selection (1).svg"
      alt="Descriptive Title 1"
      class="w-auto h-full object-contain rounded-lg"
    />
  </div>
  <div class="bg-white rounded-lg shadow-md p-6 h-[600px]">
    <img
      src="/ETL/napkin-selection (2).svg"
      alt="Descriptive Title 2"
      class="w-auto h-full object-contain rounded-lg"
    />
  </div>
</div>

<div class="grid grid-cols-2 gap-6 justify-center items-center h-auto py-10 bg-gray-50 p-6">
  <div class="bg-white rounded-lg shadow-md p-6 h-[600px]">
    <img
      src="/ETL/napkin-selection (3).svg"
      alt="Descriptive Title 1"
      class="w-auto h-full object-contain rounded-lg"
    />
  </div>
  <div class="bg-white rounded-lg shadow-md p-6 flex justify-center items-center h-[600px]">
    <img
      src="/ETL/napkin-selection (4).svg"
      alt="Descriptive Title 2"
      class="w-auto h-full object-contain rounded-lg"
    />
  </div>
</div>


    <WorkflowSteps />
    <ETLAnimatedWorkflow />
    <CallToAction />
  </div>
);

export default DataIntegrationETLPage;
