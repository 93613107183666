import React, { useEffect, useState } from "react";
import axios from "axios";
import { Calendar, Clock } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function BootcampGrid() {
  const [bootcamps, setBootcamps] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBootcamps = async () => {
      try {
        const response = await axios.get("https://api.blueblockss.ai:5002/api/bootcamp/");
        setBootcamps(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bootcamps:", error);
        toast.error("Failed to fetch bootcamps. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      }
    };

    fetchBootcamps();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  const handleView = (id: number) => {
    navigate(`/bootcamps/${id}`);
  };
  const filteredBootcamps = bootcamps.filter((bootcamp) =>
    bootcamp.title.toLowerCase().includes(search)
  );

  if (loading) {
    return <div className="text-center py-12">Loading bootcamps...</div>;
  }

  return (
    <div className="container mt-10 mx-auto px-4 py-8">
      {/* Search Bar */}
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search bootcamps..."
          value={search}
          onChange={handleSearch}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Bootcamp Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredBootcamps.map((bootcamp) => (
          <div
            key={bootcamp.bootcamp_id}
            className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col"
          >
            {/* Image */}
            {bootcamp.image && (
              <img
                src={bootcamp.image}
                alt={bootcamp.title}
                className="w-full h-48 object-cover"
              />
            )}

            {/* Content */}
            <div className="p-6 flex flex-col flex-grow">
              <h3 className="text-xl font-bold text-gray-800 mb-2">
                {bootcamp.title}
              </h3>

              {/* Schedule Info */}
              <div className="flex space-x-4 text-gray-600 mb-4">
                <div className="flex items-center">
                  <Calendar className="w-5 h-5 mr-2" />
                  {bootcamp.duration}
                </div>
                <div className="flex items-center">
                  <Clock className="w-5 h-5 mr-2" />
                  {bootcamp.class_time}
                </div>
              </div>

              {/* Description */}
              <p className="text-gray-600 mb-4">
                {bootcamp.description.beginners}
              </p>

              {/* Skills */}
              <div className="flex flex-wrap gap-2 mt-auto">
                {bootcamp.skills.slice(0, 4).map((skill) => (
                  <span
                    key={skill}
                    className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
                  >
                    {skill}
                  </span>
                ))}
                {bootcamp.skills.length > 4 && (
                  <span className="px-3 py-1 bg-gray-100 text-gray-800 rounded-full text-sm">
                    +{bootcamp.skills.length - 4} more
                  </span>
                )}
              </div>

              {/* View Button */}
              <button
  onClick={() => handleView(bootcamp.bootcamp_id)}
  className="mt-4 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
>
  View Bootcamp
</button>

            </div>
          </div>
        ))}
      </div>

      {filteredBootcamps.length === 0 && (
        <div className="text-center py-12 bg-white rounded-lg shadow">
          <p className="text-gray-500">
            No bootcamps found. Adjust your search criteria!
          </p>
        </div>
      )}
    </div>
  );
}
