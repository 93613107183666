import React, { useState } from "react";
import { Bootcamp } from "../types/bootcamp";
import BootcampList from "../components/BootcampList";
import BootcampForm from "../components/BootcampForm";
import { PlusCircle } from "lucide-react";
import BootcampView from "../components/BootcampView";

const initialBootcamp: Bootcamp = 
  {
    id: "1",
    title: "Big Data & AI/ML Bootcamp",
    duration: "15 Weeks",
    class_time: "Mon-Fri, 6-9 PM",
    description: {
      beginners: "Designed for absolute beginners in AI/ML.",
      graduates: "Best suited for recent graduates looking to upskill.",
      professionals: "Great for working professionals transitioning to AI/ML.",
    },
    image: "https://cdn.buttercms.com/IEH4zsfcQ6qoqUAr8Qh3",
    skills: ["Python", "Big Data", "AI/ML"],
    curriculum: [
      { title: "Week 1: Python Basics", content: "Learn Python fundamentals." },
      { title: "Week 2: Big Data", content: "Understand Big Data concepts." },
    ],
    faqs: [
      { question: "What is the duration?", answer: "15 weeks." },
      {
        question: "Are there any prerequisites?",
        answer: "Basic programming knowledge.",
      },
    ],
    batches: [
      { date: "2023-01-01", status: "Ongoing" },
      { date: "2023-02-01", status: "Closed Enrollment" },
    ],
  };

function Bootcamps() {
  const [bootcamps, setBootcamps] = useState<Bootcamp[]>([initialBootcamp]);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [selectedBootcamp, setSelectedBootcamp] = useState<Bootcamp | null>(null);

  const handleCreate = (bootcamp: Bootcamp) => {
    setBootcamps([...bootcamps, bootcamp]);
    setShowForm(false);
  };

  const handleUpdate = (updatedBootcamp: Bootcamp) => {
    setBootcamps(
      bootcamps.map((b) => (b.id === updatedBootcamp.id ? updatedBootcamp : b))
    );
    setEditingId(null);
  };

  const handleDelete = (id: string) => {
    setBootcamps(bootcamps.filter((b) => b.id !== id));
  };

  const handleEdit = (id: string) => {
    setEditingId(id);
    setShowForm(true);
  };
  const handleView = (bootcamp: Bootcamp) => {
    setSelectedBootcamp(bootcamp);
  };


  return (
    <div className="min-h-screen mt-20 bg-gray-50">
      {/* Header */}
      <header className=" text-white py-6">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            {/* <h1 className="text-3xl font-bold">Bootcamp Management</h1> */}
            <button
              onClick={() => {
                setEditingId(null);
                setShowForm(!showForm);
              }}
              className="inline-flex items-center px-4 py-2 bg-white text-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
            >
              <PlusCircle className="w-5 h-5 mr-2" />
              {showForm ? "Close Form" : "Add Bootcamp"}
            </button>
          </div>
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        <div className="grid gap-8 lg:grid-cols-2">
          {/* Form Section */}
          {showForm && (
            <div className="lg:sticky lg:top-8">
              <BootcampForm
                onSubmit={editingId ? handleUpdate : handleCreate}
                initialData={
                  editingId
                    ? bootcamps.find((b) => b.id === editingId)
                    : undefined
                }
              />
            </div>
          )}

          {/* List Section */}
          <div className={showForm ? "lg:col-start-2" : "lg:col-span-2"}>
            <BootcampList
              bootcamps={bootcamps}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onView={handleView}
              />
                  {selectedBootcamp && <BootcampView bootcamp={selectedBootcamp} />}

          </div>
        </div>
      </main>
    </div>
  );
}

export default Bootcamps;
