import React, { useState } from 'react';
import { Eye, ExternalLink } from 'lucide-react';
import PersonalInfoForm from './form/PersonalInfoForm';
import SkillsForm from './form/SkillsForm';
import WorkExperienceForm from './form/WorkExperienceForm';
import EducationForm from './form/EducationForm';
import ProjectsForm from './form/ProjectsForm';
import ContactForm from './form/ContactForm';
import ResumePreview from './preview/ResumePreview';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import UserDashboardLayout from '../Layout/UserDashboardLayout';

// Define types for all fields
interface WorkExperience {
  id: string; // Add id field
  title: string;
  company: string;
  duration: string;
  description: string;
  skills: string[];
}

interface Education {
  id: string; // Add id field
  degree: string;
  institution: string;
  duration: string;
}

interface Project {
  id: string; // Add id field
  title: string;
  description: string;
  skills: string[];
  githubLink: string;
  demoLink: string;
}

interface ResumeData {
  personalInfo: {
    name: string;
    title: string;
    location: string;
    aboutMe: string;
    profileImage: string;
    profileImageFile?: File | null;
  };
  contactInfo: {
    email: string;
    phone: string;
    linkedin: string;
    github: string;
  };
  skills: string[];
  workExperience: WorkExperience[];
  education: Education[];
  projects: Project[];
}
const initialData: ResumeData = {
  personalInfo: {
    name: '',
    title: '',
    location: '',
    aboutMe: '',
    profileImage: '',
    profileImageFile: null,
  },
  contactInfo: {
    email: '',
    phone: '',
    linkedin: '',
    github: '',
  },
  skills: [],
  workExperience: [
    {
      id: '1', // Unique ID for each work experience
      title: '',
      company: '',
      duration: '',
      description: '',
      skills: [],
    },
  ],
  education: [
    {
      id: '1', // Unique ID for each education
      degree: '',
      institution: '',
      duration: '',
    },
  ],
  projects: [
    {
      id: '1', // Unique ID for each project
      title: '',
      description: '',
      skills: [],
      githubLink: '',
      demoLink: '',
    },
  ],
};


export default function ResumeForm() {
  const [data, setData] = useState<ResumeData>(initialData);
  const [showPreview, setShowPreview] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    const token = sessionStorage.getItem('token');
    if (!token) {
      toast.error('No token found. Please log in.');
      return;
    }
  
    // Create FormData object
    const formData = new FormData();
  
    // Append all text data
    formData.append('name', data.personalInfo.name);
    formData.append('title', data.personalInfo.title);
    formData.append('location', data.personalInfo.location);
    formData.append('aboutMe', data.personalInfo.aboutMe);
    formData.append('email', data.contactInfo.email);
    formData.append('phone', data.contactInfo.phone);
    formData.append('linkedin', data.contactInfo.linkedin);
    formData.append('github', data.contactInfo.github);
    formData.append('skills', JSON.stringify(data.skills));
    formData.append('workExperience', JSON.stringify(data.workExperience));
    formData.append('education', JSON.stringify(data.education));
    formData.append('projects', JSON.stringify(data.projects));
  
    // Append the profile image if it exists
    if (data.personalInfo.profileImageFile) {
      formData.append('profileImage', data.personalInfo.profileImageFile);
    }
  
    // Validation logic
    const requiredFields = [
      data.personalInfo.name,
      data.personalInfo.title,
      data.personalInfo.location,
      data.contactInfo.email,
      data.contactInfo.phone,
      data.contactInfo.linkedin,
      data.contactInfo.github,
    ];
  
    // ... rest of your validation logic ...
  
    try {
      const response = await axios.post(
        'https://api.blueblockss.ai:5002/api/resume/create',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      toast.success('Resume created successfully!');
      navigate('/live-resume', { state: { data: response.data } });
    } catch (err) {
      console.error('Error creating resume:', err);
      toast.error('Failed to create resume. Please try again.');
    }
  };
  
  const handlePreview =()=>{
    setShowPreview(true);
  }

  return (
    <UserDashboardLayout>
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="bg-white shadow rounded-lg p-6 space-y-8">
            <PersonalInfoForm
              data={data.personalInfo}
              onChange={(personalInfo) => setData({ ...data, personalInfo })}
            />
            <SkillsForm
              skills={data.skills}
              onChange={(skills) => setData({ ...data, skills })}
            />
            <WorkExperienceForm
              experiences={data.workExperience}
              onChange={(workExperience) => setData({ ...data, workExperience })}
            />
            <EducationForm
              education={data.education}
              onChange={(education) => setData({ ...data, education })}
            />
            <ProjectsForm
              projects={data.projects}
              onChange={(projects) => setData({ ...data, projects })}
            />
            <ContactForm
              data={data.contactInfo}
              onChange={(contactInfo) => setData({ ...data, contactInfo })}
            />
            <div className="flex justify-end gap-4">
            <button
                  type="button"
                  onClick={handlePreview}
                  className="inline-flex items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <Eye className="h-5 w-5 mr-2" />
                  Preview Resume
                </button>
              <button
                type="submit"
                className="inline-flex items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <Eye className="h-5 w-5 mr-2" />
                Submit
              </button>
           
            </div>
          </div>
        </form>
      </div>
      {showPreview && (
        <ResumePreview
          data={data}
          onClose={() => setShowPreview(false)}
        />
      )}
    </div>
    </UserDashboardLayout>
  );
}
