import { motion } from "framer-motion";
import ReactFlow, { Background, Controls } from "reactflow";
import "reactflow/dist/style.css";
import { Card } from "antd";

const HeroSection = () => (
  <motion.div
    className="mt-20 bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20 px-6 text-center"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1 }}
  >
    <h1 className="text-4xl md:text-6xl font-bold mb-4">
      Big Data Architecture & Infrastructure Design
    </h1>
    <p className="text-lg md:text-xl mb-6">
      Design scalable, efficient, and secure data processing systems for the future.
    </p>
    <a
      href="#contact"
      className="bg-white text-blue-500 font-semibold py-3 px-6 rounded-full shadow-md hover:bg-blue-100 transition duration-300"
    >
      Contact Us Today
    </a>
  </motion.div>
);


const AboutSection = () => (
    <section className="py-12 bg-gray-50">
      <div className="container mx-auto">
        <motion.h2
          className="text-3xl font-bold text-center mb-6"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          Why Choose Us?
        </motion.h2>
        <motion.p
          className="text-center text-lg text-gray-600 max-w-3xl mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Our expertise empowers businesses to efficiently process, store, and analyze large-scale datasets with secure and scalable solutions.
        </motion.p>
      </div>
    </section>
  );
  
  

  const KeyFeatures = () => {
    const features = [
      { title: "Data Storage", description: "Hadoop HDFS, Amazon S3, Google Cloud Storage", icon: "🗂️" },
      { title: "Data Processing", description: "Apache Spark, Flink, Beam", icon: "⚙️" },
      { title: "Orchestration", description: "Kubernetes, Docker, Mesos", icon: "🚀" },
      { title: "Cloud Platforms", description: "AWS, Google BigQuery, Azure Synapse Analytics", icon: "☁️" },
      { title: "Distributed Systems", description: "Apache Hadoop, Cassandra", icon: "📡" },
      { title: "Database Tuning", description: "SQL Server, PostgreSQL, NoSQL", icon: "📈" },
      { title: "Caching Systems", description: "Redis, Memcached", icon: "🧳" },
      { title: "Search Engines", description: "Elasticsearch, Solr", icon: "🔍" },
    ];
  
    return (
      <section className="py-12">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-8">Key Features</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                className="border rounded-lg p-6 text-center hover:bg-blue-500 hover:text-white transition duration-300"
                whileHover={{ scale: 1.05 }}
              >
                <div className="text-4xl mb-4">{feature.icon}</div>
                <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                <p>{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  


const nodes = [
  { id: "1", type: "input", position: { x: 50, y: 50 }, data: { label: "Extract" } },
  { id: "2", position: { x: 250, y: 50 }, data: { label: "Transform" } },
  { id: "3", type: "output", position: { x: 450, y: 50 }, data: { label: "Load" } },
];

const edges = [
  { id: "e1-2", source: "1", target: "2", animated: true },
  { id: "e2-3", source: "2", target: "3", animated: true },
];

const ETLWorkflow = () => (
  <section className="py-12 bg-gray-100">
    <div className="container mx-auto">
      <h2 className="text-3xl font-bold text-center mb-8">ETL Workflow</h2>
      <div style={{ height: 400 }}>
        <ReactFlow nodes={nodes} edges={edges} fitView>
          <Background />
          <Controls />
        </ReactFlow>
      </div>
    </div>
  </section>
);

const KPICard = ({ title, value, icon }) => (
  <Card className="flex items-center space-x-4 p-4 border rounded-lg">
    <div className="text-blue-500 text-3xl">{icon}</div>
    <div>
      <h3 className="text-lg font-bold">{title}</h3>
      <p className="text-gray-700">{value}</p>
    </div>
  </Card>
);

const KPISection = () => (
  <section className="py-12 bg-gray-50">
    <div className="container mx-auto">
      <h2 className="text-3xl font-bold text-center mb-8">ETL Metrics</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        <KPICard title="Data Processed" value="1.2 TB" icon="📊" />
        <KPICard title="Active Jobs" value="32" icon="⚙️" />
        <KPICard title="Success Rate" value="98%" icon="✅" />
        <KPICard title="Processing Time" value="2.1 sec" icon="⏱️" />
      </div>
    </div>
  </section>
);

  const WorkProcess = () => {
    const steps = [
      { title: "Requirement Gathering", description: "Understand your business needs and data sources." },
      { title: "Data Integration", description: "Connect multiple data sources into a unified system." },
      { title: "Data Transformation", description: "Transform data into meaningful formats for analysis." },
      { title: "Deployment", description: "Deploy the solution into your live systems securely." },
    ];
  
    return (
      <section className="py-12 bg-gray-50">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-8">Our Work Process</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {steps.map((step, index) => (
              <div key={index} className="text-center ">
                <div className="bg-blue-500 text-white w-16 h-16 mx-auto rounded-full flex items-center justify-center font-bold text-lg">
                  {index + 1}
                </div>
                <h3 className="text-lg font-bold mt-4">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  const Testimonials = () => (
    <section className="py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">What Our Clients Say</h2>
        <div className="flex justify-center gap-6">
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg max-w-md">
            <p className="italic text-gray-600">
              "Their ETL solutions transformed our data pipeline and improved our
              analytics significantly."
            </p>
            <p className="mt-4 font-bold text-gray-700">- Client A</p>
          </div>
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg max-w-md">
            <p className="italic text-gray-600">
              "The integration process was seamless, and their support was
              exceptional."
            </p>
            <p className="mt-4 font-bold text-gray-700">- Client B</p>
          </div>
        </div>
      </div>
    </section>
  );
  const CallToAction = () => (
    <div className="bg-blue-500 text-white text-center py-12">
      <h2 className="text-2xl md:text-4xl font-bold mb-4">
        Ready to Optimize Your Data?
      </h2>
      <a
        href="#contact"
        className="bg-white text-blue-500 font-semibold py-3 px-6 rounded-full shadow-md hover:bg-blue-100 transition duration-300"
      >
        Contact Us Today
      </a>
    </div>
  );
  
const DataIntegrationPage = () => (
    <div>
      <HeroSection />
      <AboutSection />
      <KeyFeatures />
      <WorkProcess />
      {/* <ETLWorkflow /> */}
      {/* <KPISection /> */}
      <Testimonials />
      <CallToAction />
    </div>
  );
  
  export default DataIntegrationPage;
  