import React, { useState } from "react";
import HeroSection from "../components/enterprise/HeroSection";
import TabNavigation from "../components/enterprise/TabNavigation";
import WorkProcess from "../components/enterprise/WorkProcess";
import ContactFormSection from "../components/enterprise/ContactFormSection";
import WorkSteps from "../components/enterprise/WorkProcess";
import ITStaffResources from "./ITStaffResources";
import FeatureCards from "../components/FeatureCards";

const EnterprisePage = () => {
  const [activeTab, setActiveTab] = useState("Big Data, AI, and ML Solutions");

  const tabs = [
    "Big Data, AI, and ML Solutions",
    "Upskilling",
    "Train to Hire",
    "Staff Augmentation",
  ];

  const workSteps = {
    "Big Data, AI, and ML Solutions": [
      {
        title: "Free Consultation",
        description:
          "Discuss your business challenges and workforce objectives.",
      },
      {
        title: "Needs Assessment",
        description: "Analyze current capabilities and identify skill gaps.",
      },
      {
        title: "Discovery Phase",
        description: "Explore opportunities to align training with your goals.",
      },
      {
        title: "Custom Proposal",
        description: "Craft a tailored training plan for optimal results.",
      },
      {
        title: "100% Guaranteed Success",
        description:
          "Deliver the promised training, ensuring your team is upskilled and your business goals are achieved.",
      },
    ],
    Upskilling: [
      {
        title: "Skill Assessment",
        description: "Identifying skill gaps and training requirements.",
      },
      {
        title: "Custom Training Design",
        description: "Developing tailored training programs.",
      },
      {
        title: "Hands-On Training",
        description: "Interactive sessions to ensure practical learning.",
      },
      {
        title: "Skill Validation",
        description: "Assessing trainees’ skills post-training.",
      },
      {
        title: "Continuous Support",
        description: "Providing resources and support for ongoing learning.",
      },
    ],
    "Train to Hire": [
      {
        title: "Candidate Selection",
        description: "Shortlisting candidates for targeted training.",
      },
      {
        title: "Tailored Training Programs",
        description:
          "Preparing candidates with necessary skills for specific roles.",
      },
      {
        title: "Skill Validation",
        description:
          "Evaluating candidate readiness for real-world challenges.",
      },
      {
        title: "Onboarding Support",
        description: "Assisting with a smooth transition into the workforce.",
      },
      {
        title: "Performance Monitoring",
        description: "Ensuring candidates meet organizational expectations.",
      },
    ],
  };

  const heroContent = {
    "Big Data, AI, and ML Solutions": {
      title:
        "Empowering Teams with Big Data, AI, and ML Expertise to Lead the Future!",
      description:
        "We equip teams with cutting-edge tools and technologies to make smarter decisions, optimize operations, and stay ahead of the competition.",
      img: "https://images.unsplash.com/photo-1531498860502-7c67cf02f657?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Unsplash Big Data, AI, and ML Solutions Image
    },
    Upskilling: {
      title: "Upskilling Services",
      description:
        "Equip your team with the latest tech skills to drive growth.",
      img: "https://images.unsplash.com/photo-1637855195094-992d3d578f42?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Unsplash Upskilling Image
    },
    "Train to Hire": {
      title: "Train to Hire Programs",
      description:
        "Prepare talent for your organization through targeted training.",
      img: "https://images.unsplash.com/photo-1582004531564-50f300aae039?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Unsplash Training Image
    },
  };

  return (
    <div className="mt-10">
      <div className="mx-auto px-4 sm:px-6 py-12">
        <ContactFormSection />

        <TabNavigation
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={setActiveTab}
        />

        {activeTab === "Staff Augmentation" ? (
          <ITStaffResources />
        ) : (
          <>
            <HeroSection
              title={heroContent[activeTab]?.title}
              description={heroContent[activeTab]?.description}
              img={heroContent[activeTab]?.img}
            />
            {activeTab === "Big Data, AI, and ML Solutions" && <FeatureCards />}
            <div className="flex justify-center mb-3">
              <h2 className="text-blue-500 text-3xl font-bold py-8 text-center">
                Our Work Process
              </h2>
            </div>
            <WorkSteps steps={workSteps[activeTab]} />
          </>
        )}
      </div>
    </div>
  );
};

export default EnterprisePage;
