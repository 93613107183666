import React, { useState } from "react";

function EmployerDashboard() {
  const [formData, setFormData] = useState({
    companyName: "",
    businessNature: "",
    businessPlace: "",
    taxId: "",
    companyWebsite: "",
    representativeName: "",
    jobTitle: "",
    email: "",
    contactNumber: "",
    companySize: "",
    industryType: "",
    painPoints: "",
    customPainPoint: "",
    talentRequirements: "",
    followUpMethod: "",
    discoveryCallDate: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
  };

  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <div className="relative mt-20 bg-blue-500 text-white py-20 text-center">
        <div className="container mx-auto px-6 md:px-12">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            Employer Dashboard - Sign-Up and RFQ Management
          </h1>
          <p className="text-lg md:text-xl mb-6">
            Streamline your recruitment and talent acquisition process with our powerful platform.
          </p>
        </div>
      </div>

      {/* Form Section */}
       {/* Form Section */}
       <div className="container mx-auto py-12 px-6 md:px-12">
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-8 space-y-6">
          {/* Company Information */}
          <h2 className="text-2xl font-bold mb-4">Company Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-gray-700">Company Name</label>
              <input
                type="text"
                name="companyName"
                placeholder="Enter your company name"
                value={formData.companyName}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg focus:outline-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700">Nature of Business</label>
              <input
                type="text"
                name="businessNature"
                placeholder="E.g., IT, Healthcare"
                value={formData.businessNature}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg focus:outline-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700">Place of Business</label>
              <input
                type="text"
                name="businessPlace"
                placeholder="City, State, Country"
                value={formData.businessPlace}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg focus:outline-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700">Tax Identification/Registration Number</label>
              <input
                type="text"
                name="taxId"
                placeholder="Optional"
                value={formData.taxId}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg focus:outline-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700">Company Website</label>
              <input
                type="text"
                name="companyWebsite"
                placeholder="Optional"
                value={formData.companyWebsite}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg focus:outline-blue-500"
              />
            </div>
          </div>

          {/* Contact Person Details */}
          <h2 className="text-2xl font-bold mb-4">Contact Person Details</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-gray-700">Full Name</label>
              <input
                type="text"
                name="representativeName"
                placeholder="Enter full name"
                value={formData.representativeName}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg focus:outline-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700">Job Title</label>
              <input
                type="text"
                name="jobTitle"
                placeholder="E.g., HR Manager"
                value={formData.jobTitle}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg focus:outline-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700">Email Address</label>
              <input
                type="email"
                name="email"
                placeholder="Enter email address"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg focus:outline-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700">Contact Number</label>
              <input
                type="text"
                name="contactNumber"
                placeholder="Enter contact number"
                value={formData.contactNumber}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg focus:outline-blue-500"
              />
            </div>
          </div>

          {/* Company Size */}
          <h2 className="text-2xl font-bold mb-4">Company Size</h2>
          <select
            name="companySize"
            value={formData.companySize}
            onChange={handleInputChange}
            className="w-full p-3 border rounded-lg focus:outline-blue-500"
          >
            <option value="">Select Size</option>
            <option value="Small">Small (1–50 employees)</option>
            <option value="Medium">Medium (51–250 employees)</option>
            <option value="Large">Large (250+ employees)</option>
          </select>

          {/* Industry Type */}
          <h2 className="text-2xl font-bold mb-4">Industry Type</h2>
          <input
            type="text"
            name="industryType"
            placeholder="E.g., IT, Retail"
            value={formData.industryType}
            onChange={handleInputChange}
            className="w-full p-3 border rounded-lg focus:outline-blue-500"
          />

          {/* Pain Points */}
          <h2 className="text-2xl font-bold mb-4">Pain Points</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <select
              name="painPoints"
              value={formData.painPoints}
              onChange={handleInputChange}
              className="w-full p-3 border rounded-lg focus:outline-blue-500"
            >
              <option value="">Select Pain Point</option>
              <option value="Talent shortage">Talent shortage</option>
              <option value="Lack of expertise">Lack of expertise</option>
              <option value="Delayed projects">Delayed projects</option>
            </select>
            <input
              type="text"
              name="customPainPoint"
              placeholder="Other Pain Points"
              value={formData.customPainPoint}
              onChange={handleInputChange}
              className="w-full p-3 border rounded-lg focus:outline-blue-500"
            />
          </div>

          {/* Talent Requirements */}
          <h2 className="text-2xl font-bold mb-4">Talent Requirements</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <label className="flex items-center">
              <input
                type="radio"
                name="talentRequirements"
                value="Single Talent"
                onChange={handleInputChange}
                className="mr-2"
              />
              A Single Talent
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="talentRequirements"
                value="Team for Entire Project"
                onChange={handleInputChange}
                className="mr-2"
              />
              A Team for an Entire Project
            </label>
          </div>

          {/* Preferred Follow-Up Method */}
          <h2 className="text-2xl font-bold mb-4">Preferred Follow-Up Method</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <label className="block">
              <input
                type="radio"
                name="followUpMethod"
                value="Discovery Call"
                onChange={handleInputChange}
                className="mr-2"
              />
              Book a Discovery Call
            </label>
            <label className="block">
              <input
                type="radio"
                name="followUpMethod"
                value="Request for Quote"
                onChange={handleInputChange}
                className="mr-2"
              />
              Request for Quote (RFQ)
            </label>
          </div>

          {/* Discovery Call Date */}
          {formData.followUpMethod === "Discovery Call" && (
            <div>
              <label className="block text-gray-700">Discovery Call Date</label>
              <input
                type="datetime-local"
                name="discoveryCallDate"
                value={formData.discoveryCallDate}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg focus:outline-blue-500"
              />
            </div>
          )}

          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default EmployerDashboard;
