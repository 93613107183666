import React from 'react';
import { Button } from '../../ui/button';
import { X } from 'lucide-react';

interface SkillsAndCriteriaProps {
  formData: {
    skills: { value: string }[];
    challenges: string;
    completionCriteria: string;
  };
  onChange: (field: string, value: any) => void;
  onNext: () => void;
  onBack: () => void;
}

export function SkillsAndCriteria({ formData, onChange, onNext, onBack }: SkillsAndCriteriaProps) {
  const addSkill = () => {
    onChange('skills', [...formData.skills, { value: '' }]);
  };

  const removeSkill = (index: number) => {
    const newSkills = formData.skills.filter((_, i) => i !== index);
    onChange('skills', newSkills);
  };

  const updateSkill = (index: number, value: string) => {
    const newSkills = formData.skills.map((skill, i) => 
      i === index ? { value } : skill
    );
    onChange('skills', newSkills);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-blue-900">Skills & Criteria</h2>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Skills
          </label>
          {formData.skills.map((skill, index) => (
            <div key={index} className="flex gap-2 mb-2">
              <input
                type="text"
                value={skill.value}
                onChange={(e) => updateSkill(index, e.target.value)}
                className="flex-1 px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                placeholder="Enter a skill"
              />
              <Button
                variant="outline"
                size="icon"
                onClick={() => removeSkill(index)}
                className="text-red-500"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          ))}
          <Button variant="outline" onClick={addSkill} className="mt-2">
            Add Skill
          </Button>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Challenges
          </label>
          <textarea
            value={formData.challenges}
            onChange={(e) => onChange('challenges', e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
            rows={4}
            placeholder="Describe the challenges"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Completion Criteria
          </label>
          <textarea
            value={formData.completionCriteria}
            onChange={(e) => onChange('completionCriteria', e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
            rows={4}
            placeholder="Enter completion criteria"
          />
        </div>
      </div>
      <div className="flex justify-between">
        <Button variant="outline" onClick={onBack}>Back</Button>
        <Button onClick={onNext}>Next</Button>
      </div>
    </div>
  );
}