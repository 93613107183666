import React, { useState } from "react";

const Accordion = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="bg-white rounded-lg">
      {items.map((topic, index) => (
        <div
          key={index}
          className="my-4 shadow-md rounded-md bg-white hover:shadow-lg transition-shadow"
        >
          <button
            className={`w-full text-left px-6 py-4 flex justify-between items-center font-medium transition-all duration-300 hover:bg-blue-100 text-blue-800 ${
              openIndex === index
                ? "bg-blue-50 text-blue-800 border-l-4 border-blue-500"
                : "text-gray-800 hover:bg-gray-100"
            }`}
            onClick={() => toggleAccordion(index)}
          >
            <span>{topic}</span>
            <span>
              {openIndex === index ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 10a1 1 0 0 1 1-1h8a1 1 0 0 1 0 2H6a1 1 0 0 1-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 5a1 1 0 0 1 1 1v8a1 1 0 0 1-2 0V6a1 1 0 0 1 1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </span>
          </button>
          {openIndex === index && (
            <div className="px-6 py-4 text-gray-600 bg-gray-50 border-l-4 border-blue-500 rounded-b-md">
              {topic.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
