import React from 'react'

function Bi_Tools() {
  return (
<div class="bg-gray-50">
  {/* <!-- Hero Section --> */}
  <div className="bg-gradient-to-r mt-20 from-blue-500 to-blue-800 text-white py-16 px-6 text-center">
    <div class="container mx-auto px-6 md:px-12 text-center">
      <h1 class="text-4xl md:text-5xl font-bold mb-6">
        Business Intelligence (BI) & Visualization
      </h1>
      <p class="text-lg md:text-xl mb-6">
        Unlock the power of data-driven insights and visually compelling dashboards to make smarter decisions.
      </p>
      <button class="bg-white text-blue-500 px-6 py-3 rounded-full shadow-md hover:bg-gray-100 transition duration-300">
        Learn More
      </button>
    </div>
  </div>

  {/* <!-- Section 1: BI Tools --> */}
  <div class="py-12">
    <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-6">
      <div class="bg-white rounded-lg shadow-md p-6 flex justify-center">
        <img
          src="/BI/1.svg"
          alt="BI Tools"
          class="w-auto h-64 object-contain rounded-lg"
        />
      </div>
      <div>
        <h2 class="text-2xl font-bold mb-4">BI Tools</h2>
        <p class="text-gray-700 mb-4">
          Leverage powerful tools like Power BI, Tableau, Looker, and Qlik to transform raw data into actionable insights. These tools enable interactive dashboards and reports to simplify complex data analysis.
        </p>
      </div>
    </div>
  </div>

  {/* <!-- Section 2: Data Visualization --> */}
  <div class="py-12 bg-gray-100">
    <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-6">
      <div>
        <h2 class="text-2xl font-bold mb-4">Data Visualization</h2>
        <p class="text-gray-700 mb-4">
          Tools like D3.js, Plotly, Matplotlib, and Seaborn bring data to life with visually stunning and customizable charts. They are perfect for creating dashboards and storytelling through data.
        </p>
      </div>
      <div class="bg-white rounded-lg shadow-md p-6 flex justify-center">
        <img
          src="/BI/2.svg"
          alt="Data Visualization Tools"
          class="w-auto h-64 object-contain rounded-lg"
        />
      </div>
    </div>
  </div>

  {/* <!-- Section 3: Reporting & Dashboards --> */}
  <div class="py-12">
    <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-6">
      <div class="bg-white rounded-lg shadow-md p-6 flex justify-center">
        <img
          src="/BI/3.svg"
          alt="Reporting & Dashboards"
          class="w-auto h-64 object-contain rounded-lg"
        />
      </div>
      <div>
        <h2 class="text-2xl font-bold mb-4">Reporting & Dashboards</h2>
        <p class="text-gray-700 mb-4">
          Reporting platforms like Power BI, Tableau, and Google Data Studio simplify data presentation through interactive dashboards, helping organizations track metrics in real time and make data-driven decisions.
        </p>
      </div>
    </div>
  </div>


</div>
  )
}

export default Bi_Tools