import React from 'react';

interface Props {
  data: string[];
  onChange: (data: string[]) => void;
}

const careerGoals = [
  'Build a Professional Profile for My Next Career',
  'Start a Job in AI',
  'Start a Job in IT Coding',
  'Start a Job in IT Management',
  'Enhance Skills for Career Advancement',
  'Networking and Job Opportunities',
];

export default function CareerGoals({ data, onChange }: Props) {
  const handleChange = (goal: string) => {
    if (data.includes(goal)) {
      onChange(data.filter((g) => g !== goal));
    } else {
      onChange([...data, goal]);
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-xl font-semibold text-gray-900">Desired Outcome (Career Goals)</h2>
        <p className="mt-1 text-sm text-gray-500">
          What is your desired outcome from attending this event? (Select one or more)
        </p>
      </div>

      <div className="space-y-4">
        {careerGoals.map((goal) => (
          <div key={goal} className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                type="checkbox"
                checked={data.includes(goal)}
                onChange={() => handleChange(goal)}
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label className="font-medium text-gray-700">{goal}</label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}