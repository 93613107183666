import React from 'react';
import { Mail, MapPin, Linkedin, Github, Phone } from 'lucide-react';

interface Props {
  name: string;
  title: string;
  location: string;
  email: string;
  phone: string;
  linkedin: string;
  github: string;
}

export default function Header({
  name,
  title,
  location,
  email,
  phone,
  linkedin,
  github
}: Props) {
  return (
    <div className="relative bg-gradient-to-r from-blue-600 to-blue-800 text-white py-8 px-6">
      <div className="flex flex-col md:flex-row items-center gap-6">
        <img
          src="https://deepkumar.vercel.app/78338569.png"
          alt={name}
          className="w-32 h-32 rounded-full border-4 border-white shadow-xl"
        />
        <div className="text-center md:text-left">
          <h1 className="text-3xl font-bold mb-2">{name}</h1>
          <h2 className="text-xl mb-4">{title}</h2>
          <div className="flex flex-wrap justify-center md:justify-start gap-4 text-sm">
            <div className="flex items-center gap-1">
              <MapPin size={16} />
              <span>{location}</span>
            </div>
            <div className="flex items-center gap-1">
              <Mail size={16} />
              <a href={`mailto:${email}`} className="hover:underline">{email}</a>
            </div>
            <div className="flex items-center gap-1">
              <Phone size={16} />
              <span>{phone}</span>
            </div>
            <div className="flex items-center gap-4">
              <a href={linkedin} className="hover:text-blue-200 transition-colors">
                <Linkedin size={20} />
              </a>
              <a href={github} className="hover:text-blue-200 transition-colors">
                <Github size={20} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}