import React from "react";
import { Link } from "react-router-dom";

const EventCard = ({ event, view }) => {
  function generateCorporateBlueGradient() {
    const randomBlueShade = () => {
      const blueComponent = Math.floor(Math.random() * 156) + 100; // Blue shades: 100-255
      const greenComponent = Math.floor(Math.random() * 100); // Green: 0-100 for balance
      const redComponent = Math.floor(Math.random() * 50); // Red: 0-50 for a hint
      return `rgb(${redComponent}, ${greenComponent}, ${blueComponent})`;
    };
  
    const randomPercentage = () => `${Math.floor(Math.random() * 100)}%`;
  
    return {
      backgroundColor: randomBlueShade(),
      backgroundImage: `
        radial-gradient(at ${randomPercentage()} ${randomPercentage()}, ${randomBlueShade()} 0%, transparent 60%),
        radial-gradient(at ${randomPercentage()} ${randomPercentage()}, ${randomBlueShade()} 0%, transparent 50%),
        radial-gradient(at ${randomPercentage()} ${randomPercentage()}, ${randomBlueShade()} 0%, transparent 40%),
        radial-gradient(at ${randomPercentage()} ${randomPercentage()}, ${randomBlueShade()} 0%, transparent 30%)
      `,
    };
  }
  
  
  return (
    <div
      className={`p-4 border rounded-lg ${
        view === "list" ? "flex items-center space-x-4" : "text-center"
      }`}
    >
      {/* <img
        src={event.image}
        alt={event.title}
        className={`${view === "list" ? "w-24 h-24" : "w-full h-40"} object-cover rounded`}
      /> */}
      <div
  className={`${
    view === "list" ? "w-24 h-24" : "w-full h-40"
  } flex items-center justify-center rounded`}
  style={generateCorporateBlueGradient()} // Apply random gradient styles

>
  <span className="text-white font-semibold text-center">
    {event.title}
  </span>
</div>

      <div>
        <h3 className="text-lg font-bold">{event.title}</h3>
        <p className="text-sm text-gray-600">{event.description}</p>
        <div className="flex space-x-2 mt-2">
          <span className="text-xs bg-blue-100 text-blue-600 px-2 py-1 rounded">
            {event.category}
          </span>
          <span className="text-xs bg-green-100 text-green-600 px-2 py-1 rounded">
            {event.deliveryMode}
          </span>
        </div>
        <Link to="/Registration" className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
        Register Today
        </Link>
      </div>
    </div>
  );
};

export default EventCard;
