import React, { useState, useEffect } from "react";
import { BookOpen, Users, HelpCircle, Plus, X } from "lucide-react";
import { Table } from "./Table";
import { Form } from "./Form";
import * as api from "../../lib/api";
import { Bootcamp, Speaker, Curriculum, FAQ } from "../../types/index";

interface BootcampDetailsProps {
  bootcamp: Bootcamp;
  onClose: () => void;
}

type TabType = "curriculum" | "speakers" | "faqs";

export const BootcampDetails: React.FC<BootcampDetailsProps> = ({
  bootcamp,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState<TabType>("curriculum");
  const [showForm, setShowForm] = useState(false);
  const [editingItem, setEditingItem] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [speakers, setSpeakers] = useState<Speaker[]>([]);
  const [curriculums, setCurriculums] = useState<Curriculum[]>([]);
  const [faqs, setFaqs] = useState<FAQ[]>([]);

  useEffect(() => {
    fetchTabData();
  }, [activeTab, bootcamp.bootcamp_id]);

  const fetchTabData = async () => {
    setIsLoading(true);
    try {
      switch (activeTab) {
        case "speakers":
          const speakerData = await api.fetchSpeakerById(bootcamp.bootcamp_id);
          setSpeakers(Array.isArray(speakerData) ? speakerData : [speakerData]);
          break;
        case "curriculum":
          const curriculumData = await api.fetchCurriculumById(
            bootcamp.bootcamp_id
          );
          setCurriculums(
            Array.isArray(curriculumData) ? curriculumData : [curriculumData]
          );
          break;
        case "faqs":
          const faqData = await api.fetchFaqById(bootcamp.bootcamp_id);
          setFaqs(Array.isArray(faqData) ? faqData : [faqData]);
          break;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      if (editingItem) {
        switch (activeTab) {
          case "speakers":
            await api.updateSpeaker(bootcamp.bootcamp_id, editingItem.speaker_id, data);
            break;
          case "curriculum":
            await api.updateCurriculum(
              bootcamp.bootcamp_id,
              editingItem.curriculum_id,
              data
            );
            break;
          case "faqs":
            await api.updateFaq(bootcamp.bootcamp_id, editingItem.faq_id, data);
            break;
        }
      } else {
        switch (activeTab) {
          case "speakers":
            await api.createSpeaker({
              ...data,
              bootcampId: bootcamp.bootcamp_id,
            });
            break;
          case "curriculum":
            await api.createCurriculum({
              ...data,
              bootcampId: bootcamp.bootcamp_id,
            });
            break;
          case "faqs":
            await api.createFaq({ ...data, bootcampId: bootcamp.bootcamp_id });
            break;
        }
      }
      setShowForm(false);
      setEditingItem(null);
      fetchTabData();
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (item: any) => {
    if (!window.confirm("Are you sure you want to delete this item?")) return;

    setIsLoading(true);
    try {
      switch (activeTab) {
        case "speakers":
          await api.deleteSpeaker(bootcamp.bootcamp_id, item.speaker_id);
          break;
        case "curriculum":
          await api.deleteCurriculum(bootcamp.bootcamp_id, item.curriculum_id);
          break;
        case "faqs":
          await api.deleteFaq(bootcamp.bootcamp_id, item.faq_id);
          break;
      }
      fetchTabData();
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getFormFields = () => {
    switch (activeTab) {
      case "speakers":
        return [
          { name: "name", label: "Name", type: "text" },
          { name: "bio", label: "Bio", type: "textarea" },
          { name: "role", label: "Role", type: "text" },
          { name: "email", label: "Email", type: "email" },
          { name: "image", label: "Image", type: "file" },
        ];
      case "curriculum":
        return [
          { name: "title", label: "Title", type: "text" },
          { name: "content", label: "Content", type: "textarea" },
        ];
      case "faqs":
        return [
          { name: "question", label: "Question", type: "textarea" },
          { name: "answer", label: "Answer", type: "textarea" },
        ];
      default:
        return [];
    }
  };

  const getTableColumns = () => {
    switch (activeTab) {
      case "speakers":
        return [
          { header: "Name", accessor: "name" },
          { header: "Role", accessor: "role" },
          { header: "Email", accessor: "email" },
        ];
      case "curriculum":
        return [
          { header: "Title", accessor: "title" },
          { header: "Content", accessor: "content" },
        ];
      case "faqs":
        return [
          { header: "Question", accessor: "question" },
          { header: "Answer", accessor: "answer" },
        ];
      default:
        return [];
    }
  };

  const getTabData = () => {
    switch (activeTab) {
      case "speakers":
        return speakers;
      case "curriculum":
        return curriculums;
      case "faqs":
        return faqs;
      default:
        return [];
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-bold text-gray-900">
              {bootcamp.title}
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        <div className="border-b border-gray-200">
          <nav className="flex -mb-px">
            {[
              { id: "curriculum", icon: BookOpen, label: "Curriculum" },
              { id: "speakers", icon: Users, label: "Speakers" },
              { id: "faqs", icon: HelpCircle, label: "FAQs" },
            ].map(({ id, icon: Icon, label }) => (
              <button
                key={id}
                onClick={() => setActiveTab(id as TabType)}
                className={`
                  flex-1 px-4 py-4 text-center border-b-2 font-medium text-sm
                  ${
                    activeTab === id
                      ? "border-blue-500 text-blue-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  }
                `}
              >
                <Icon className="w-5 h-5 mx-auto mb-1" />
                {label}
              </button>
            ))}
          </nav>
        </div>

        <div
          className="p-6 overflow-y-auto"
          style={{ maxHeight: "calc(90vh - 200px)" }}
        >
          <div className="mb-6 flex justify-end">
            <button
              onClick={() => {
                setShowForm((prev) => !prev); // Toggle the form visibility
                if (showForm) {
                  setEditingItem(null); // Clear editing state when canceling
                }
              }}
              className={`flex items-center px-4 py-2 rounded-md text-white ${
                showForm
                  ? "bg-red-600 hover:bg-red-700"
                  : "bg-blue-600 hover:bg-blue-700"
              }`}
            >
              {showForm ? (
                <>
                  <X className="w-5 h-5 mr-2" />
                  Cancel
                </>
              ) : (
                <>
                  <Plus className="w-5 h-5 mr-2" />
                  Add New
                </>
              )}
            </button>
          </div>

          {showForm && (
            <div className="mb-8 p-6 bg-gray-50 rounded-lg">
              <Form
                fields={getFormFields()}
                initialData={editingItem}
                onSubmit={handleSubmit}
                submitLabel={editingItem ? "Update" : "Save"}
              />
            </div>
          )}

          {isLoading ? (
            <div className="flex justify-center items-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
            </div>
          ) : (
            <Table
              columns={getTableColumns()}
              data={getTabData()}
              onEdit={(item) => {
                setEditingItem(item);
                setShowForm(true);
              }}
              onDelete={handleDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
};
