import React from 'react';

interface Props {
  skills: string[];
}

export default function Skills({ skills }: Props) {
  return (
    <section>
      <h2 className="text-xl font-bold mb-4 text-gray-800">Skills</h2>
      <div className="flex flex-wrap gap-2">
        {skills.map((skill) => (
          <span
            key={skill}
            className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-medium hover:bg-blue-200 transition-colors"
          >
            {skill}
          </span>
        ))}
      </div>
    </section>
  );
}