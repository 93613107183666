import React from "react";
import {FlipWords} from '../../components/ui/flip-words';

const ContactFormSection = () => {
  const words = ["innovative", "skilled", "empowered", "future-ready"];

  return (
    <div className="bg-blue-600  text-white py-16 px-8 flex justify-center items-center">
      <div className="w-1/2">
        <h2 className="text-3xl font-bold mb-6">Upskill Your 
        <span className="text-blue-300">
            <FlipWords words={words} />
          </span>{" "}<br/>
          Workforce, Unlock Your Potential
        </h2>
        <p className="text-lg mb-6">
        At BlueBlockss, we provide enterprise-level training in Big Data, AI, ML, and the latest technologies.
      <br/>  Delivered in-house, online, or at our training center, our programs empower your team with the skills to drive innovation and thrive in a competitive world
        </p>
      </div>
      <div className="bg-white text-black rounded-lg p-6 shadow-lg w-1/2">
        <form className="space-y-4">
          <div className="flex space-x-4">
            <input
              type="text"
              placeholder="First Name"
              className="border p-2 rounded-lg w-1/2"
            />
            <input
              type="text"
              placeholder="Last Name"
              className="border p-2 rounded-lg w-1/2"
            />
          </div>
          <input
            type="email"
            placeholder="Email"
            className="border p-2 rounded-lg w-full"
          />
          <input
            type="text"
            placeholder="Subject"
            className="border p-2 rounded-lg w-full"
          />
          <textarea
            placeholder="Your Query?"
            className="border p-2 rounded-lg w-full"
            rows="4"
          ></textarea>
          <div>
            <input type="checkbox" id="terms" />
            <label htmlFor="terms" className="text-sm ml-2">
              I agree to the <a href="#" className="text-blue-500">terms and conditions</a>
            </label>
          </div>
          <button className="bg-blue-600 text-white px-6 py-2 rounded-md">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactFormSection;
