import React from "react";
import Header from "../Header";
import ContactUs from "../ContactUs";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <Header />

      {/* Main Content */}
      <main className="flex-1 mt-10 px-4 sm:px-6 lg:px-8">
        {children}
      </main>

      {/* Footer */}
      <footer className="bg-gray-100 py-6 px-4 sm:px-6 lg:px-8">
        <ContactUs />
      </footer>
    </div>
  );
};

export default Layout;
