import BackButton from "../components/BackButton";
import React, { useState } from "react";

const RFQManagementPanel = () => {
  const [rfqData, setRfqData] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const handleSkillsChange = (event) => {
    const options = event.target.options;
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);

    setSelectedSkills(selectedValues);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add RFQ submission logic here
    alert("RFQ Submitted Successfully!");
  };

  return (
    <div className="min-h-screen text-white px-4 md:px-8 py-6">
      <h1 className="text-3xl font-bold text-center mb-6">RFQ Management Panel</h1>
      <div className="mt-5 mx-10">
      <BackButton/>
      </div>
      <section className="bg-white text-black p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-2xl font-semibold mb-4">Submit RFQ</h2>

        <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="projectName" className="block font-medium mb-1">Project Name/Title</label>
            <input
              type="text"
              id="projectName"
              className="w-full p-2 border rounded-lg focus:outline-blue-500"
              required
            />
          </div>

          <div>
            <label htmlFor="projectType" className="block font-medium mb-1">Project Type</label>
            <select id="projectType" className="w-full p-2 border rounded-lg focus:outline-blue-500" required>
              <option value="Development">Development</option>
              <option value="Maintenance">Maintenance</option>
              <option value="Research">Research</option>
              <option value="Custom">Custom</option>
            </select>
          </div>

          <div className="md:col-span-2">
            <label htmlFor="projectDetails" className="block font-medium mb-1">Project Details</label>
            <textarea
              id="projectDetails"
              className="w-full p-2 border rounded-lg focus:outline-blue-500"
              rows="4"
              required
            ></textarea>
          </div>

          <div>
  <label htmlFor="skills" className="block font-medium mb-1">Required Skills/Technologies</label>
  <select
    id="skills"
    className="w-full p-2 border rounded-lg focus:outline-blue-500"
    multiple
    value={selectedSkills}
    onChange={(e) => {
      const options = e.target.options;
      const values = Array.from(options).filter(option => option.selected).map(option => option.value);
      setSelectedSkills(values);
    }}
  >
    <option value="React">React</option>
    <option value="Node.js">Node.js</option>
    <option value="Python">Python</option>
    <option value="UI/UX">UI/UX</option>
  </select>
</div>


          <div>
            <label htmlFor="teamSize" className="block font-medium mb-1">Team Size</label>
            <select id="teamSize" className="w-full p-2 border rounded-lg focus:outline-blue-500" required>
              <option value="Small">Small (1–3 people)</option>
              <option value="Medium">Medium (4–10 people)</option>
              <option value="Large">Large (10+ people)</option>
            </select>
          </div>

          <div>
            <label htmlFor="roles" className="block font-medium mb-1">Roles Needed</label>
            <input
              type="text"
              id="roles"
              className="w-full p-2 border rounded-lg focus:outline-blue-500"
              placeholder="e.g., Backend Developer, UI/UX Designer"
            />
          </div>

          <div>
            <label htmlFor="budget" className="block font-medium mb-1">Budget</label>
            <input
              type="text"
              id="budget"
              className="w-full p-2 border rounded-lg focus:outline-blue-500"
              placeholder="Fixed Budget or Budget Range"
              required
            />
          </div>

          <div>
            <label htmlFor="startDate" className="block font-medium mb-1">Expected Start Date</label>
            <input type="date" id="startDate" className="w-full p-2 border rounded-lg focus:outline-blue-500" required />
          </div>

          <div>
            <label htmlFor="endDate" className="block font-medium mb-1">Deadline/End Date</label>
            <input type="date" id="endDate" className="w-full p-2 border rounded-lg focus:outline-blue-500" required />
          </div>

          <div>
            <label htmlFor="priority" className="block font-medium mb-1">Priority Level</label>
            <select id="priority" className="w-full p-2 border rounded-lg focus:outline-blue-500" required>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </select>
          </div>

          <div className="md:col-span-2">
            <label htmlFor="attachments" className="block font-medium mb-1">Attachments</label>
            <input type="file" id="attachments" className="w-full p-2 border rounded-lg focus:outline-blue-500" />
          </div>

          <div className="md:col-span-2">
            <button
              type="submit"
              className="w-28 bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition"
            >
              Submit RFQ
            </button>
          </div>
        </form>
      </section>

      <section className="bg-white text-black p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Submitted RFQs</h2>
        <table className="w-full text-left">
          <thead>
            <tr className="bg-blue-500 text-white">
              <th className="py-2 px-4">Project Name</th>
              <th className="py-2 px-4">Submission Date</th>
              <th className="py-2 px-4">Status</th>
              <th className="py-2 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {rfqData.length > 0 ? (
              rfqData.map((rfq, index) => (
                <tr key={index} className="border-t">
                  <td className="py-2 px-4">{rfq.projectName}</td>
                  <td className="py-2 px-4">{rfq.submissionDate}</td>
                  <td className="py-2 px-4">{rfq.status}</td>
                  <td className="py-2 px-4">
                    <button className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600 transition">
                      Edit
                    </button>
                    <button className="bg-red-500 text-white py-1 px-3 rounded ml-2 hover:bg-red-600 transition">
                      Cancel
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="py-4 text-center">No RFQs Submitted</td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default RFQManagementPanel;
