import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { 
  User as UserIcon, 
  Filter as FilterIcon, 
  Search as SearchIcon, 
  Edit as EditIcon, 
  Trash2 as DeleteIcon,
  Plus as PlusIcon,
  ClockIcon
} from 'lucide-react';
import { toast } from 'react-toastify';

// User Interface
interface User {
  user_id: number;
  full_name: string;
  email: string;
  phone_number: string;
  place_of_residence: string;
  time_zone: string;
  role: string;
  career_goals: string[];
  preferred_mode: string;
  contact_permission: boolean;
  data_usage_consent: boolean;
}

const UserManagementPage: React.FC = () => {
  // State Management
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Filtering and Search States
  const [searchQuery, setSearchQuery] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [timeZoneFilter, setTimeZoneFilter] = useState('');

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const itemsPerPage = 10;

  // Token Management
  const [token, setToken] = useState<string | null>(null);

  // Check and set token on component mount
  useEffect(() => {
    const storedToken = sessionStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    } else {
      toast.error('No token found. Please log in.');
    }
  }, []);

  // Fetch Users
  const fetchUsers = async () => {
    // Only proceed if token exists
    if (!token) return;

    try {
      setLoading(true);
      const response = await axios.get('https://api.blueblockss.ai:5002/api/user/users/all', {
        params: {
          from: (currentPage - 1) * itemsPerPage + 1,
          to: currentPage * itemsPerPage,
          role: roleFilter,
          time_zone: timeZoneFilter,
          search: searchQuery
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      setUsers(response.data.users);
      setTotalUsers(response.data.total || 0);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch users');
      setLoading(false);
      toast.error('Failed to fetch users');
    }
  };

  // Fetch users when dependencies change
  useEffect(() => {
    if (token) {
      fetchUsers();
    }
  }, [token, currentPage, roleFilter, timeZoneFilter, searchQuery]);

  // Delete User Handler
  const handleDeleteUser = async (userId: number) => {
    if (!token) {
      toast.error('No authentication token');
      return;
    }

    try {
      await axios.delete(`https://api.blueblockss.ai:5002/api/user/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      
      toast.success('User deleted successfully');
      fetchUsers(); // Refresh users after deletion
    } catch (err) {
      setError('Failed to delete user');
      toast.error('Failed to delete user');
    }
  };

  // Roles and Time Zones (could be fetched dynamically)
  const roles = useMemo(() => ['Student', 'Professional', 'Freelancer'], []);
  const timeZones = useMemo(() => ['EST', 'PST', 'America/St_Johns'], []);

  // Filter Dropdown Component
  const FilterDropdown = () => (
    <div className="flex space-x-4">
      {/* Role Filter */}
      <div className="relative">
        <select
          value={roleFilter}
          onChange={(e) => setRoleFilter(e.target.value)}
          className="appearance-none w-full bg-white border border-gray-300 rounded-md pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">All Roles</option>
          {roles.map(role => (
            <option key={role} value={role}>{role}</option>
          ))}
        </select>
        <FilterIcon className="absolute left-3 top-3 text-gray-400" size={20} />
      </div>

      {/* Time Zone Filter */}
      <div className="relative">
        <select
          value={timeZoneFilter}
          onChange={(e) => setTimeZoneFilter(e.target.value)}
          className="appearance-none w-full bg-white border border-gray-300 rounded-md pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">All Time Zones</option>
          {timeZones.map(zone => (
            <option key={zone} value={zone}>{zone}</option>
          ))}
        </select>
        <ClockIcon className="absolute left-3 top-3 text-gray-400" size={20} />
      </div>
    </div>
  );

  // Search Bar Component
  const SearchBar = () => (
    <div className="relative flex-grow">
      <input
        type="text"
        placeholder="Search users..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <SearchIcon className="absolute left-3 top-3 text-gray-400" size={20} />
    </div>
  );

  // Pagination Component
  const Pagination = () => {
    const totalPages = Math.ceil(totalUsers / itemsPerPage);

    return (
      <div className="flex justify-between items-center mt-4">
        <span className="text-gray-600">
          Showing {(currentPage - 1) * itemsPerPage + 1} to{' '}
          {Math.min(currentPage * itemsPerPage, totalUsers)} of {totalUsers} users
        </span>
        <div className="flex space-x-2">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(prev => prev - 1)}
            className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50"
          >
            Previous
          </button>
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(prev => prev + 1)}
            className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  // User Table Component
  const UserTable = () => {
    if (loading) return <div>Loading...</div>;
    if (error) return <div className="text-red-500">{error}</div>;

    return (
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-3 text-left">ID</th>
              <th className="px-4 py-3 text-left">Name</th>
              <th className="px-4 py-3 text-left">Email</th>
              <th className="px-4 py-3 text-left">Role</th>
              <th className="px-4 py-3 text-left">Time Zone</th>
              <th className="px-4 py-3 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.user_id} className="border-b hover:bg-gray-50">
                <td className="px-4 py-3">{user.user_id}</td>
                <td className="px-4 py-3">{user.full_name}</td>
                <td className="px-4 py-3">{user.email}</td>
                <td className="px-4 py-3">{user.role}</td>
                <td className="px-4 py-3">{user.time_zone}</td>
                <td className="px-4 py-3 flex justify-center space-x-2">
                  <button 
                    className="text-blue-500 hover:text-blue-700"
                    // Add edit functionality
                  >
                    <EditIcon size={20} />
                  </button>
                  <button 
                    className="text-red-500 hover:text-red-700"
                    onClick={() => handleDeleteUser(user.user_id)}
                  >
                    <DeleteIcon size={20} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Render nothing if no token
  if (!token) {
    return null;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold flex items-center">
          <UserIcon className="mr-3" size={30} /> User Management
        </h1>
        <button className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
          <PlusIcon className="mr-2" size={20} /> Add New User
        </button>
      </div>

      <div className="mb-6 flex space-x-4">
        <SearchBar />
        <FilterDropdown />
      </div>

      <UserTable />
      <Pagination />
    </div>
  );
};

export default UserManagementPage;