import React from 'react';
import WelcomeCard from '../components/user/WelcomeCard';
import ProgressOverview from '../components/user/ProgressOverview';
import { EnrolledBootcamp } from '../types/user';
import { useAuth } from '../contexts/AuthContext';
import { FaBriefcase, FaCheckCircle, FaTasks } from 'react-icons/fa';
import { ArrowLeft, ArrowRight } from 'lucide-react';

// Mock data
const userData = {
  name: 'Alex Johnson',
  nextClass: {
    title: 'Advanced Machine Learning Concepts',
    time: '2:00 PM',
    date: 'Today',
  },
};

const enrolledBootcamps: EnrolledBootcamp[] = [
  {
    id: '1',
    title: 'AI/ML Bootcamp',
    progress: 65,
    nextClass: '2024-03-15T14:00:00',
    assignments: 2,
    completedModules: 13,
    totalModules: 20,
  },
  {
    id: '2',
    title: 'Data Science Fundamentals',
    progress: 40,
    nextClass: '2024-03-16T15:30:00',
    assignments: 3,
    completedModules: 8,
    totalModules: 20,
  },
];

export default function UserDashboard() {
      const { email } = useAuth();
  
  return (
    <div className="p-6 space-y-6">
      <WelcomeCard name={email} nextClass={userData.nextClass} />
      <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0 md:space-x-6 p-6">
      {/* Card 1: Registration Successful */}
      <div className="bg-green-100 p-6 rounded-lg shadow-md flex flex-col items-center text-center">
        <div className="text-green-500 text-4xl mb-4">
          <FaCheckCircle />
        </div>
        <h3 className="text-xl font-semibold text-green-700 mb-2">Registration Successful</h3>
        <p className="text-sm text-green-600">You have successfully registered for the bootcamp. Start your learning journey today!</p>
      </div>

      {/* Arrow */}
      <div className="text-gray-400 text-4xl hidden md:block"><ArrowRight/></div>

      {/* Card 2: Complete All Bootcamps */}
      <div className="bg-blue-100 p-6 rounded-lg shadow-md flex flex-col items-center text-center">
        <div className="text-blue-500 text-4xl mb-4">
          <FaTasks />
        </div>
        <h3 className="text-xl font-semibold text-blue-700 mb-2">Complete All Bootcamps</h3>
        <p className="text-sm text-blue-600">Finish all assigned bootcamps and gain mastery in your chosen fields.</p>
      </div>

      {/* Arrow */}
      <div className="text-gray-400 text-4xl hidden md:block"><ArrowRight/></div>

      {/* Card 3: Unlock Digital Portfolio */}
      <div className="bg-purple-100 p-6 rounded-lg shadow-md flex flex-col items-center text-center">
        <div className="text-purple-500 text-4xl mb-4">
          <FaBriefcase />
        </div>
        <h3 className="text-xl font-semibold text-purple-700 mb-2">Unlock Digital Portfolio</h3>
        <p className="text-sm text-purple-600">Your digital portfolio is now live. Share it and showcase your skills to the market!</p>
      </div>
    </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Quick Stats */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Learning Stats</h3>
          <div className="space-y-4">
            <div>
              <p className="text-sm text-gray-500">Hours Spent Learning</p>
              <p className="text-2xl font-bold text-gray-900">48.5</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Assignments Completed</p>
              <p className="text-2xl font-bold text-gray-900">15/20</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Average Score</p>
              <p className="text-2xl font-bold text-green-600">92%</p>
            </div>
          </div>
        </div>

        {/* Upcoming Deadlines */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Upcoming Deadlines</h3>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">ML Project Submission</p>
                <p className="text-sm text-gray-500">AI/ML Bootcamp</p>
              </div>
              <span className="text-red-600 text-sm">2 days left</span>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">Data Analysis Report</p>
                <p className="text-sm text-gray-500">Data Science Fundamentals</p>
              </div>
              <span className="text-yellow-600 text-sm">5 days left</span>
            </div>
          </div>
        </div>

        {/* Achievements */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Recent Achievements</h3>
          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <div className="flex-shrink-0 w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                <span className="text-2xl">🏆</span>
              </div>
              <div>
                <p className="font-medium text-gray-900">Perfect Attendance</p>
                <p className="text-sm text-gray-500">Attended all classes this month</p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="flex-shrink-0 w-12 h-12 bg-green-100 rounded-full flex items-center justify-center">
                <span className="text-2xl">⭐</span>
              </div>
              <div>
                <p className="font-medium text-gray-900">Top Performer</p>
                <p className="text-sm text-gray-500">Scored 95% in ML Assessment</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProgressOverview bootcamps={enrolledBootcamps} />
    </div>
  );
}