import Header from "../components/dashboard/Header";
import Sidebar from "../components/dashboard/Sidebar";
import React from "react";
import Dashboard from "./Dashboard";

function Admin_dashboard({children}) {
  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 overflow-y-auto">
        {children}
        </main>
      </div>
    </div>
  );
}

export default Admin_dashboard;
