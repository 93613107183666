import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ChevronLeft, ChevronRight, ClipboardCheck, ClipboardEdit } from 'lucide-react';
import {
  LayoutDashboard,
  BookOpen,
  GraduationCap,
  Calendar,
  Award,
  MessageSquare,
  Settings,
  LogOut,
  ClipboardPenIcon,
} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const navigation = [
  { name: 'Dashboard', icon: LayoutDashboard, href: '/user/dashboard' },
  { name: 'Create Resume', icon: ClipboardPenIcon, href: '/user/create-resume' },
  { name: 'View Resume', icon: ClipboardCheck, href: '/user/resumes' },
  { name: 'My Bootcamps', icon: BookOpen, href: '/user/bootcamps' },
  { name: 'Learning Path', icon: GraduationCap, href: '/user/learning' },
  { name: 'Schedule', icon: Calendar, href: '/user/schedule' },
  { name: 'Achievements', icon: Award, href: '/user/achievements' },
  { name: 'Discussion', icon: MessageSquare, href: '/user/discussion' },
  { name: 'Settings', icon: Settings, href: '/user/settings' },
];

export default function UserSidebar() {
  const [isExpanded, setIsExpanded] = useState(true);
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    window.location.href = "/";
  };

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div 
      className={`flex flex-col h-full bg-white border-r border-slate-200 transition-all duration-300 ease-in-out ${
        isExpanded ? 'w-64' : 'w-20'
      }`}
    >
      <div className={`px-6 py-8 border-b border-slate-200 flex items-center ${
        isExpanded ? 'justify-between' : 'justify-center'
      }`}>
        {isExpanded && <h1 className="text-2xl font-bold text-blue-600">Learning Portal</h1>}
        <button 
          onClick={toggleSidebar}
          className="p-2 rounded-full hover:bg-gray-100 transition-colors"
        >
          {isExpanded ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
        </button>
      </div>

      <nav className="flex-1 px-4 py-4 space-y-1">
        {navigation.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            className={({ isActive }) =>
              `flex items-center px-4 py-3 text-sm font-medium rounded-lg transition-colors ${
                isActive
                  ? 'bg-blue-50 text-blue-600'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-blue-600'
              }`
            }
            title={!isExpanded ? item.name : ''}
          >
            <item.icon className="w-5 h-5 min-w-[20px]" />
            {isExpanded && <span className="ml-3">{item.name}</span>}
          </NavLink>
        ))}
      </nav>

      <div className="p-4 border-t border-slate-200">
        <button
          onClick={handleLogout}
          className="flex items-center w-full px-4 py-3 text-sm font-medium text-gray-600 rounded-lg hover:bg-gray-50 hover:text-red-600 transition-colors"
          title={!isExpanded ? 'Logout' : ''}
        >
          <LogOut className="w-5 h-5 min-w-[20px]" />
          {isExpanded && <span className="ml-3">Logout</span>}
        </button>
      </div>
    </div>
  );
}