import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Clock, 
  Calendar, 
  ChevronDown, 
  ChevronUp, 
  GraduationCap, 
  Users, 
  Briefcase 
} from 'lucide-react';
import SkillTag from './SkillTag';
import { slugify } from '../../utils/slugify';

const BootcampCard = ({ bootcamp }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const slug = slugify(bootcamp?.title || ''); // Handle null or undefined title
  const applyUrl = `/skill-development/${slug}?id=${bootcamp?.bootcamp_id || ''}`;

  const skillsArray = Array.isArray(bootcamp?.skills) 
    ? bootcamp.skills 
    : (bootcamp?.skills ? bootcamp.skills.split(",").map(skill => skill.trim()) : []);

  return (
    <div className="bg-white rounded-lg shadow-md hover:shadow-xl transition-all duration-300 flex flex-col h-[500px]">
      {/* Image Container */}
      <div className="relative h-48">
        <div 
          className="absolute top-0 left-0 w-full h-full rounded-t-lg"
          style={{
            background: `
              radial-gradient(at 51% 85%, #362fd9 0px, transparent 50%),
              radial-gradient(at 6% 76%, #6f9a8d 0px, transparent 50%),
              radial-gradient(at 52% 11%, #4477ce 0px, transparent 50%),
              radial-gradient(at 72% 30%, #6789ba 0px, transparent 50%),
              #27e1ce
            `
          }}
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <h3 className="text-white text-xl font-bold px-4 text-center">
              {bootcamp?.title || 'Bootcamp'}
            </h3>
          </div>
        </div>
      </div>

      <div className="p-4 flex flex-col flex-grow">
        {/* Title */}
        <Link 
          to={`/skill-development/${slug}`}
          className="text-lg font-semibold text-gray-900 hover:text-blue-600 
                   transition-colors duration-200 line-clamp-2"
        >
          {bootcamp?.title || 'Bootcamp'}
        </Link>

        {/* Duration & Time */}
        <div className="mt-2 space-y-1.5 text-sm text-gray-600">
          <div className="flex items-center gap-2">
            <Calendar className="w-4 h-4 text-blue-600" />
            <p>Duration: {bootcamp?.duration || 'N/A'}</p>
          </div>
          <div className="flex items-center gap-2">
            <Clock className="w-4 h-4 text-blue-600" />
            <p>Class Time: {bootcamp?.class_time || 'N/A'}</p>
          </div>
        </div>

        {/* Skills */}
        <div className="mt-3 flex flex-wrap gap-1.5">
          {skillsArray.map((skill, index) => (
            <SkillTag key={index} skill={skill} />
          ))}
        </div>

        {/* Description */}
        <div className="mt-3">
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="flex items-center text-sm text-blue-600 hover:text-blue-800"
          >
            {isExpanded ? 'Show Less' : 'Show More'}
            {isExpanded ? 
              <ChevronUp className="ml-1 w-4 h-4" /> : 
              <ChevronDown className="ml-1 w-4 h-4" />
            }
          </button>
          <div
            className={`mt-2 text-sm text-gray-700 transition-all duration-300 overflow-hidden
                     ${isExpanded ? 'max-h-48' : 'max-h-0'}`}
          >
            {/* Structured Description */}
            <div className="space-y-2">
              <div className="flex items-start gap-2">
                <Users className="w-4 h-4 text-blue-600 mt-1 flex-shrink-0" />
                <p>{bootcamp?.description?.beginners || 'Beginner details not available'}</p>
              </div>
              <div className="flex items-start gap-2">
                <GraduationCap className="w-4 h-4 text-blue-600 mt-1 flex-shrink-0" />
                <p>{bootcamp?.description?.graduates || 'Graduate details not available'}</p>
              </div>
              <div className="flex items-start gap-2">
                <Briefcase className="w-4 h-4 text-blue-600 mt-1 flex-shrink-0" />
                <p>{bootcamp?.description?.professionals || 'Professional details not available'}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Apply Button */}
        <div className="mt-auto pt-3">
          <a
            href={applyUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="block w-full py-2 px-4 bg-blue-600 text-white text-center text-sm
                     rounded-md hover:bg-blue-700 transition-colors duration-200
                     flex items-center justify-center gap-2"
          >
            Apply Now
            <GraduationCap className="w-4 h-4" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BootcampCard;
