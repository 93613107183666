import React from 'react';
import RegistrationForm from '../components/RegistrationForm';

function Registration() {
  return (
    <div className="max-h-full bg-gray-50">
      <RegistrationForm />
    </div>
  );
}

export default Registration;