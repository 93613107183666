import React, { createContext, useState, useContext } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => sessionStorage.getItem("token"));
  const [isAdmin, setIsAdmin] = useState(() => {
    const storedToken = sessionStorage.getItem("token");
    if (storedToken) {
      try {
        const decoded = jwtDecode(storedToken);
        return decoded.role === "admin";
      } catch (error) {
        console.error("Failed to decode token:", error);
        return false;
      }
    }
    return false;
  });
  
  // Add email state
  const [email, setEmail] = useState(() => {
    const storedToken = sessionStorage.getItem("token");
    if (storedToken) {
      try {
        const decoded = jwtDecode(storedToken);
        return decoded.email || "";
      } catch (error) {
        console.error("Failed to decode token:", error);
        return "";
      }
    }
    return "";
  });

  const login = (newToken) => {
    setToken(newToken);
    sessionStorage.setItem("token", newToken);
    try {
      const decoded = jwtDecode(newToken);
      setIsAdmin(decoded.role === "admin");
      setEmail(decoded.email || ""); // Set email from decoded token
    } catch (error) {
      console.error("Failed to decode token:", error);
    }
  };

  const logout = () => {
    setToken(null);
    setIsAdmin(false);
    setEmail(""); // Clear email on logout
    sessionStorage.removeItem("token");
  };

  return (
    <AuthContext.Provider value={{ token, isAdmin, email, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};